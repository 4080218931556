import React, { useContext, useMemo } from 'react';
import { NavLink, Route, useHistory, useRouteMatch } from 'react-router-dom';
import UsersListComponent from '../../../Pages/Admin panel/components/UserList/UserList';
import Faqs from '../../../Pages/Admin panel/components/Faqs/Faqs';
import EditCards from '../../../Pages/Admin panel/components/EditCards/EditCards';
import Settings from '../../../Pages/Admin panel/components/Settings/Settings';
import Transaction from '../Statistics/Transaction/Transaction';
import Summary from '../Statistics/Summary/Summary';
import Skiptrace from '../Statistics/SkiptraceHistory/Skiptrace';
import { AppContext } from '../../../appContext';
import { ADMIN_ROUTE_PATHS, MAIN_ADMIN_ROUTE_PATHS } from '../../../utils/constants';
import Employees from '../../../Pages/Admin panel/components/Employees/Employees';
import DisabledEmployees from '../../../Pages/Admin panel/components/Employees/DisabledEmployees';
import Invites from '../../../Pages/Admin panel/components/Employees/Invites';
import UserStatistic from '../../../Pages/Admin panel/components/StatisticPanel/UserStatistic/UserStatistic';
import PaymentStatistic from '../../../Pages/Admin panel/components/StatisticPanel/UserStatistic/PaymentStatistic';
import FilesStatistic from '../../../Pages/Admin panel/components/StatisticPanel/UserStatistic/FilesStatistic/FilesStatistic';
import UserActivity from '../Statistics/UserActivity/UserActivity';

const AdminNavigation = () => {
  const { url } = useRouteMatch();
  const { whiteLabelId, isUserMainAdmin } = useContext(AppContext);
  const pathname = useHistory().location.pathname;

  const navigation = useMemo(() => {
    return isUserMainAdmin ? MAIN_ADMIN_ROUTE_PATHS : ADMIN_ROUTE_PATHS;
  }, [isUserMainAdmin]);

  return (
    <>
      <nav className="admin-panel__navigation">
        {navigation.map((route, index) => {
          return (
            <NavLink
              className={`admin-panel__navigation-link ${
                (pathname.includes('reports') && route.url.includes('reports')) || (pathname.includes('employees') && route.url.includes('employees'))
                  ? 'admin-panel__navigation-link--active'
                  : null
              }`}
              activeClassName="admin-panel__navigation-link--active"
              exact={route.exact}
              to={`${url}${route.url}`}
              style={{ zIndex: navigation.length - index }}
              key={index}
            >
              {route.label}
            </NavLink>
          );
        })}
      </nav>
      <div className="admin-panel__content-container">
        <Route exact path={`${url}/`} component={EditCards} />
        <Route exact path={`${url}/users`} component={UsersListComponent} />
        <Route path={`${url}/users/statistic/:userId`} exact component={UserStatistic} />
        <Route path={`${url}/users/statistic/payment/:userId`} component={PaymentStatistic} />
        <Route path={`${url}/users/statistic/file/:userId`} component={FilesStatistic} />
        <Route path={`${url}/faq`} component={Faqs} />
        <Route path={`${url}/settings`} component={Settings} />
        <Route exact path={`${url}/employees`}>
          <Employees id={whiteLabelId} />
        </Route>
        <Route path={`${url}/employees/disabled`}>
          <DisabledEmployees id={whiteLabelId} />
        </Route>
        <Route path={`${url}/employees/invites`}>
          <Invites id={whiteLabelId} />
        </Route>
        <Route path="/admin/reports" exact>
          <Transaction id={whiteLabelId} />
        </Route>
        <Route path="/admin/reports/summary">
          <Summary id={whiteLabelId} />
        </Route>
        <Route path="/admin/reports/skiptrace">
          <Skiptrace id={whiteLabelId} />
        </Route>
        <Route path="/admin/reports/user-activity">
          <UserActivity id={whiteLabelId} />
        </Route>
      </div>
    </>
  );
};

export default AdminNavigation;
