import React from 'react';
import { Select, Input } from 'antd';
import { USERS_SEARCH_TYPES } from '../../../../../utils/constants';
import './styles.scss';
const { Search } = Input;

const SearchComponent = ({ setType, search, setSearch, searchHandler }) => {
  return (
    <div className="users-list__search">
      <Select defaultValue={'All'} onChange={(value) => setType(value)}>
        {USERS_SEARCH_TYPES.map((item, index) => (
          <Select.Option key={index} value={item.value}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Search
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
        enterButton="Search"
        size="large"
        onSearch={searchHandler}
      />
    </div>
  );
};

export default SearchComponent;
