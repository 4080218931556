import React, { useContext, useMemo } from 'react';
import { NavLink, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { SUPER_ADMIN_ROUTE_PATHS } from '../../utils/constants';
import ListWL from '../../Components/admin/ListWl/ListWL';
import CreateWl from '../../Components/admin/CreateWL/CreateWL';
import EditWl from '../../Components/admin/EditWL/EditWl';
import RoleNavigation from '../../Components/admin/Navigation/RoleNavigation';
import SummarySuperAdmin from '../../Components/admin/SuperAdminReports/SummarySuperAdmin';
import { AppContext } from '../../appContext';
import classNames from 'classnames';
import TransactionSuperAdmin from '../../Components/admin/SuperAdminReports/TransactionSuperAdmin';
import SkiptraceSuperAdmin from '../../Components/admin/SuperAdminReports/SkiptraceSuperAdmin';
import UserActivitySuperAdmin from '../../Components/admin/SuperAdminReports/UserActivitySuperAdmin';
import WebHook from './components/WebHook/WebHook';

const SuperAdmin = () => {
  const { url } = useRouteMatch();
  const { isUserSuperAdmin } = useContext(AppContext);
  const pathname = useHistory().location.pathname;

  const adminPanelPadding = useMemo(() => {
    return (
      pathname.includes('/employees') ||
      pathname.includes('/reports') ||
      pathname.includes('/skiptrace') ||
      pathname.includes('/summary ') ||
      pathname.includes('/user-activity')
    );
  }, [pathname]);

  const reportClassName = pathname.includes('skiptrace') || pathname.includes('user-activity') || pathname.includes('transaction');

  return (
    <section className={classNames('admin-panel', { 'admin-padding': adminPanelPadding })}>
      <RoleNavigation />

      <div className='admin-panel__main-container'>
        <>
          {isUserSuperAdmin && !pathname.includes('web-hook') && (
            <nav className='admin-panel__navigation'>
              {SUPER_ADMIN_ROUTE_PATHS.map((route, index) => {
                return (
                  <NavLink
                    className={`admin-panel__navigation-link`}
                    activeClassName='admin-panel__navigation-link--active'
                    exact={route.exact}
                    to={`${url}${route.url}`}
                    style={{ zIndex: SUPER_ADMIN_ROUTE_PATHS.length - index }}
                    key={index}
                  >
                    {route.label}
                  </NavLink>
                );
              })}
            </nav>
          )}
          <div className='admin-panel__content-container'>
            <div className='white-label'>
              <div className={`white-label__content ${reportClassName ? 'white-label__content-reports' : ''}`}>
                <Route path='/super-admin' exact component={ListWL} />
                <Route path='/super-admin/web-hook' exact component={WebHook} />

                {isUserSuperAdmin && (
                  <>
                    <Route exact path='/super-admin/new' component={CreateWl} />
                    <Route exact path='/super-admin/white-label/edit/:id' component={EditWl} />
                    <Route exact path='/super-admin/summary' component={SummarySuperAdmin} />

                    <Route exact path='/super-admin/skiptrace' component={SkiptraceSuperAdmin} />
                    <Route exact path='/super-admin/user-activity' component={UserActivitySuperAdmin} />
                    <Route exact path='/super-admin/transaction' component={TransactionSuperAdmin} />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
    </section>
  );
};

export default SuperAdmin;
