import React from 'react';
import StarIcon from '../../../assets/pop-up/star.svg';
import DollarIcon from '../../../assets/landing/dollar-price.svg';
import './styles.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classnames from 'classnames';

const PopularOption = () => {
  return (
    <div className={`default-price-card-option default-price-card-option--popular`}>
      <LazyLoadImage src={StarIcon} alt="star" style={{ width: '20px', height: '19px', marginRight: '10px' }} />
      <p>Popular option</p>
    </div>
  );
};

const BestOption = () => {
  return (
    <div className={`default-price-card-option default-price-card-option--best`}>
      <LazyLoadImage src={DollarIcon} alt="star" style={{ width: '20px', height: '19px', marginRight: '10px' }} />
      <p>Best deal</p>
    </div>
  );
};

const DisplayedCards = ({ skips, price, perHit, type }) => {
  const TYPE_OPTION = {
    best_deal: <BestOption />,
    popular_option: <PopularOption />,
    default: null,
  };

  return (
    <div className="default-price-card">
      {TYPE_OPTION[type]}

      <header className={classnames('price-card__header', { special__header: type !== 'default' })}>
        <h3 className="price-card__header-heading">
          <b>{new Intl.NumberFormat('en').format(skips)}</b>
          {` SKIPS`}
        </h3>
      </header>
      <div className="price-card__content-container">
        <p className={`${type !== 'default' ? '' : 'price-card__price-default'} price-card__price-value`}>
          ${price / 100}
          <sup>{price.toString().slice(-2)}</sup>
        </p>
        <p className="price-card__price-skip">${perHit} PER HIT</p>
      </div>
    </div>
  );
};

export default DisplayedCards;
