import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CloudDownloadOutlined, PieChartOutlined, QuestionOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { userProfile } from '../../../store/actions/userActions';
import { apiURL } from '../../../utils/config';
import NoAvatar from '../../../assets/UI/no_avatar.png';
import { AppContext } from '../../../appContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import history from '../../../utils/history';
import InsufficientPopUp from '../../common/InsufficientPopUp/InsufficientPopUp';
import DollarIcon from '../../../assets/sidebar/dollar-icon.svg';
import ArrowRight from '../../../Components/SVG-Icon/ArrowRight';
import classnames from 'classnames';
import SidebarFooter from '../../SVG-Icon/SidebarFooter';
import './styles.scss';

const Sidebar = ({ collapsed, setCollapsed }) => {
  const dispatch = useDispatch();
  const collapsedIcon = collapsed ? { fontSize: '30px', padding: '5px 0' } : {};
  const collapsedAvatar = collapsed ? { width: '60px', height: '60px' } : {};
  const profile = useSelector((state) => state?.user?.profile);
  const sidebarLogo = useSelector((state) => state.config.config?.images?.logo_1);
  const footerLogoShort = useSelector((state) => state.config.config?.images?.sidebar_logo_short) || null;
  const footerLogo = useSelector((state) => state.config.config?.images?.sidebar_logo) || null;

  const { isUserMainAdmin, sideBarIsShown, setSideBarIsShown, isUserAdmin, isUserSuperAdmin, isUserBasic, isSkipCloud } = useContext(AppContext);

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch]);

  const mainRoutes = [
    {
      key: '/dashboard',
      to: '/dashboard',
      title: 'Dashboard',
      icon: <PieChartOutlined style={collapsedIcon} />,
    },
    {
      key: '/library',
      to: '/library',
      title: 'Results Library',
      icon: <CloudDownloadOutlined style={collapsedIcon} />,
    },
    (isUserAdmin || isUserSuperAdmin || isUserBasic || isUserMainAdmin) && {
      key: '/admin',
      to: isUserSuperAdmin ? '/super-admin' : '/admin',
      title: 'Admin panel',
      icon: <UserOutlined style={collapsedIcon} />,
    },
    {
      key: '/profile',
      to: '/profile',
      title: 'Account MGMT.',
      icon: <SettingOutlined style={collapsedIcon} />,
    },
    {
      key: '/faqs',
      to: '/faqs',
      title: 'FAQ',
      icon: <QuestionOutlined style={collapsedIcon} />,
    },
  ].filter(Boolean);

  return (
    <div className={classnames(`sidebar ${collapsed ? 'sidebar__collapsed' : 'sidebar__uncollapsed'}`, { sidebar__bg: isSkipCloud })}>
      <div onClick={setCollapsed} className="collapsed_button">
        <p>CLICK TO {collapsed ? 'extend' : 'minimize'} SIDEBAR</p>
      </div>
      <div className={classnames(`sidebar__border ${collapsed ? 'sidebar__border__collapsed' : 'sidebar__border__uncollapsed'}`)}></div>
      <div
        className={classnames('sidebar__user', {
          'blue-line': !collapsed,
        })}
      >
        {!collapsed && <LazyLoadImage src={apiURL + sidebarLogo} className="sidebar__logo1" />}
        <LazyLoadImage
          src={profile?.social ? profile?.avatar : profile?.avatar ? apiURL + '/uploads/avatars/' + profile?.avatar : NoAvatar}
          className="sidebar__user-avatar"
          alt="user avatar"
          style={collapsedAvatar}
        />
        {profile && !collapsed && (
          <h3>
            Welcome <span> {profile?.first_name}</span>
          </h3>
        )}
      </div>
      {collapsed && <div className="sidebar__line" alt="line" draggable={false} />}
      <div className="sidebar__menu">
        {mainRoutes.map((nav) => (
          <Link draggable={false} key={nav.key} to={nav.to} className={classnames(` sidebar__menu-item ${collapsed ? 'collapsed' : 'uncollapsed'}`)}>
            {collapsed ? nav.icon : nav.title}
          </Link>
        ))}
      </div>
      {collapsed && <div className="sidebar__line m-t-30 m-b-15" alt="line" draggable={false} />}

      {!collapsed ? (
        <p className={`sidebar__menu-credit`} onClick={() => history.push('/add-more-skips')}>
          + ADD MORE <span> SKIPS</span>
        </p>
      ) : (
        <div className={`sidebar__collapsed__menu-credit`} onClick={() => history.push('/add-more-skips')}>
          <img src={DollarIcon} alt="dollar" draggable={false} />
          <span className="collapsed__text">
            ADD skips
            <ArrowRight />
          </span>
        </div>
      )}

      {!collapsed ? (
        <SidebarFooter>{footerLogo && <img src={apiURL + footerLogo} alt="footer-logo" draggable={false} />}</SidebarFooter>
      ) : (
        <div className="sidebar__footer__block">{footerLogoShort && <img src={apiURL + footerLogoShort} alt="footer-logo-short" draggable={false} />}</div>
      )}
      <InsufficientPopUp show={sideBarIsShown} setShow={setSideBarIsShown} />
    </div>
  );
};

export default Sidebar;
