import React from 'react';
import { Modal, Tooltip } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './styles.scss';

const CertificateModal = ({ show, setShow, label, setLabel }) => {
  return (
    <>
      {label && (
        <Modal
          className="ant-certificate"
          visible={show}
          onCancel={() => {
            setShow(false);
            setLabel(null);
          }}
          onOk={() => {
            setLabel(null);
            setShow(false);
          }}
        >
          <div className="certificate-modal">
            <h1>{label.name}</h1>

            <h3 className="flex flex-align-items-center flex-justify-content-between">
              <span>Status:</span> {label.status}
            </h3>
            <h3 className="flex flex-align-items-center flex-justify-content-between">
              <span>Available From :</span> {label?.certificates?.available_from || 'Error'}
            </h3>
            <h3 className="flex flex-align-items-center flex-justify-content-between">
              <span>Available Until :</span>
              {label?.certificates?.available_until || 'Error'}
            </h3>
            <h3 className="flex flex-align-items-center flex-justify-content-between">
              Certificate Key :
              {label?.certificates?.cert_key ? (
                <Tooltip placement="top" title={'SSL Certificate'}>
                  <CheckCircleOutlined className="certificate-icon" />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title={'SSL Certificate Error'}>
                  <CloseCircleOutlined className="certificate-icon-error" />
                </Tooltip>
              )}
            </h3>
            <h3 className="flex flex-align-items-center flex-justify-content-between">
              Certificate SSL :
              {label?.certificates?.certificate ? (
                <Tooltip placement="top" title={'Certificate Key'}>
                  <CheckCircleOutlined className="certificate-icon" />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title={'SSL Certificate Error'}>
                  <CloseCircleOutlined className="certificate-icon-error" />
                </Tooltip>
              )}
            </h3>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CertificateModal;
