import React from 'react';
import { FileDoneOutlined } from '@ant-design/icons';
import FileInput from '../FileInput/FileInput';
import { Spin, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { postBusinessFiles, postFiles } from '../../../store/actions/fileActions';
import history from '../../../utils/history';
import { showNotification } from '../../../App';
import FileIcon from '../../SVG-Icon/FileIcon';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import './styles.scss';

const Finder = ({ type = 'personal', file, setFile, title, blueTitle, margin }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.files.postLoading);
  const width = useResizeWindows();

  const onDropHandler = (e) => {
    e.preventDefault();
    if (file.length <= 10) {
      if (e.dataTransfer.files.length > 1) {
        const files = Object.keys(e.dataTransfer.files).map((file) => e.dataTransfer.files[file]);
        const validate = file.length + files.length <= 10;
        if (validate) setFile((prevState) => [...prevState, ...files]);
        else showNotification('error', 'Max count is 10 files', 'Error');
      } else if (e.dataTransfer.files) {
        if (file.length + 1 <= 10) setFile((prevState) => [...prevState, e.dataTransfer.files[0]]);
        else showNotification('error', 'Max count is 10 files', 'Error');
      }
    } else showNotification('error', 'Max count is 10 files', 'Error');
  };

  const onDragStartHandler = (e) => {
    e.preventDefault();
  };

  const onDragLeaveHandler = (e) => {
    e.preventDefault();
  };

  const removeFiles = (filename) => {
    setFile((prev) => prev.filter((item) => item?.name !== filename?.name));
  };

  const submitHandler = async () => {
    if (type === 'business') {
      history.push('/data-matching/business/new');
      dispatch(postBusinessFiles(file));
    } else {
      history.push('/data-matching/people/new');
      dispatch(postFiles(file));
    }
  };

  return (
    <Spin spinning={!!loading} size="large">
      <div className={`${margin ? 'm-b-20' : ''}`}>
        <div
          className={`drag-and-drop ${file.length ? 'drag-and-drop-files' : ''}`}
          onDrop={onDropHandler}
          onDragStart={(e) => onDragStartHandler(e)}
          onDragLeave={(e) => onDragLeaveHandler(e)}
          onDragOver={(e) => onDragStartHandler(e)}
        >
          <h3>
            {title} <span>{blueTitle}</span>
          </h3>

          {!file.length ? (
            <>
              <div className="drag-and-drop__circle">
                <div className="drag-and-drop__image">
                  <FileIcon width={width > 1600 ? 70 : 50} height={width > 1600 ? 70 : 50} />
                </div>
              </div>
              <FileInput
                className="drag-and-drop-input primary-button"
                onChange={setFile}
                text="From a spreadsheet"
                accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
              />
              <p className="drag-and-drop__placeholder" onClick={() => history.push('/faqs')}>
                Learn about <span>importing from a spreadsheet</span>
              </p>
            </>
          ) : (
            <div className="drag-and-drop__text">
              {file.length
                ? file.map((filename, index) => (
                    <div className="drag-and-drop__text-item" key={index} onClick={() => removeFiles(filename)}>
                      <Tooltip placement="top" title="Remove File">
                        <FileDoneOutlined className="icon" />
                        <p>{filename ? (filename.name.length > 20 ? filename.name.slice(0, 20) + '...' : filename.name) : null}</p>
                      </Tooltip>
                    </div>
                  ))
                : null}
            </div>
          )}
        </div>
        {file.length ? (
          <button onClick={submitHandler} className="m-t-20 link primary-button">
            Submit
          </button>
        ) : null}
      </div>
    </Spin>
  );
};

export default Finder;
