import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import NavigationSection from '../../Components/common/LandingHeader/NavigationSection';
import { ReactComponent as ReactAheadLogoSvg } from '../../assets/white-label/icons/icon-landing-ahead.svg';
import { SignUpLink } from '../Landing/Landing';
import FooterSection from '../../Components/common/LandingFooter/footerSection';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFaqs, postContactUs } from '../../store/actions/landingActions';
import { showNotification } from '../../App';
import { Spin } from 'antd';
import Fuse from 'fuse.js';
import Question from '../../Components/common/Question/Question';
import { AppContext } from '../../appContext';
import { toCapitalize } from '../../utils/functions';
import './style.scss';
import ReactGa from 'react-ga4';

const FAQ = () => {
  const pathname = useHistory().location.pathname;
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.landing.faqs);
  const loading = useSelector((state) => state.landing.loading);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [search, setSearch] = useState('');
  const [faqs, setFaqs] = useState(questions);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    text: false,
  });
  const { brand } = useContext(AppContext);

  useEffect(() => {
    ReactGa.send({ hitType: "pageview", page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAllFaqs());
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (name.trim().length < 3 || !(email?.includes('@') && email?.length > 3) || text.trim().length < 3) {
      setErrors({
        email: !(email?.includes('@') && email?.length > 3),
        name: name.trim().length < 3,
        text: text.trim().length < 3,
      });

      showNotification('error', 'Please fill in all required fields correctly', 'Error');
    } else {
      dispatch(postContactUs({ name, email, text }));
      setErrors({
        name: false,
        text: false,
        email: false,
      });
    }
  };

  useEffect(() => {
    const options = {
      findAllMatches: true,
      threshold: 0.5,
      keys: ['question', 'answer'],
    };

    const fuse = new Fuse(questions, options);

    const result = fuse.search(search).map((item) => item.item);

    setFaqs(result);

    if (!search) setFaqs(questions);
  }, [search, questions]);

  return (
    <div className="faq">
      <NavigationSection headHeader={'Got questions?'} headText={`We've got answers!`} />
      <section className="faq-section">
        <div className="faq-section__header-container">
          <h2>FAQ</h2>
        </div>
        <input placeholder="Search..." className="faqs-search" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
        <Spin spinning={loading} size="large">
          <ul className="faq__questions-list">
            {faqs.map((faq) => (
              <Question key={faq.id} faq={faq} search={search} />
            ))}
          </ul>
        </Spin>

        <Spin spinning={loading} size="large">
          <form className="faq__form">
            <h3>
              Don't see
              <b> You question?</b>
            </h3>
            <p>Enter your information and ask your question below and our {toCapitalize(brand)} support team will be in touch shortly.</p>
            <fieldset className="faq__form-fieldset">
              <input
                className={errors.name ? 'input-error' : ''}
                type={'text'}
                id="userName"
                value={name}
                placeholder="First Name"
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                className={errors.email ? 'input-error' : ''}
                type={'email'}
                id="userEmail"
                value={email}
                placeholder="E-mail"
                onChange={(e) => setEmail(e.target.value)}
              />
              <textarea
                className={errors.text ? 'input-error' : ''}
                id="usetText"
                value={text}
                placeholder="Type your question here..."
                onChange={(e) => setText(e.target.value)}
              />
            </fieldset>
            <button className="faq__form-submit-button" type="submit" onClick={submitHandler}>
              Submit
            </button>
          </form>
        </Spin>

        <div className="faq__account-container">
          <div className="faq__account-svg-container">
            <ReactAheadLogoSvg />
          </div>
          <h4 className="faq__account-header">
            <span>Haven't used</span>
            <b>{toCapitalize(brand)}</b>
            <span>yet?</span>
          </h4>
          <SignUpLink text={`Show me what i'm missing out on!`} className="faq__login-button navigation-section__login-link-button" />
          <span>
            We'll
            <Link to={'/sign-up'} className="faq__login-link navigation-section__login-link">
              make you an account
            </Link>
            for FREE so you can check it out first!
          </span>
        </div>
      </section>
      <FooterSection />
    </div>
  );
};

export default FAQ;
