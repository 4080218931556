import React, { useContext, useEffect, useState } from 'react';
import { InputNumber } from 'antd';
import WLField from '../FieldWL/WLField';
import Helper from '../Helper/Helper';
import { createWL, editWLSettings, getSoloSettingsWl } from '../../../store/actions/whiteLabelActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  contractRate,
  domainText,
  facebookKeyText,
  facebookText,
  googleKeyText,
  instagramText,
  jivoChatText,
  phoneText,
  whiteLabelNameText,
  youtubeText,
} from '../../../utils/white-label-annotations';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../appContext';
import CallendlyModal from '../CallendlyModal/CallendlyModal';

const MainSettings = ({ paramsID, setSettingContent }) => {
  const { isUserSuperAdmin } = useContext(AppContext);
  const pathname = useHistory().location.pathname;
  const dispatch = useDispatch();
  const whiteLabel = useSelector((state) => state.whiteLabel.whiteLabel);
  const createdWLId = useSelector((state) => state.whiteLabel?.createdWLId);
  const id = pathname.includes('edit') || pathname.includes('settings') || !isUserSuperAdmin ? paramsID : createdWLId;
  const [domain, setDomain] = useState('');
  const [contract, setContract] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [facebook, setFacebook] = useState('');
  const [google, setGoogle] = useState('');
  const [jivoChat, setJivoChat] = useState('');
  const [youtube, setYoutube] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [sendGridApiKey, setSendGridApiKey] = useState('');

  const [facebookPixel, setFacebookPixel] = useState('');
  const [googleAnalytics, setGoogleAnalytics] = useState('');
  const [first_promoter, setFirst_promoter] = useState('');

  const [callendly, setCallendly] = useState(false);

  useEffect(() => {
    if (id) dispatch(getSoloSettingsWl(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      setDomain(whiteLabel?.domain || '');
      setFacebook(whiteLabel?.fb_auth_key || '');
      setGoogle(whiteLabel?.google_auth_key || '');
      setName(whiteLabel?.name || '');
      setPhone(whiteLabel?.company_phone || '');
      setContract(whiteLabel?.contract_rate || '');
      setJivoChat(whiteLabel?.jivochat_url || '');
      setInstagram(whiteLabel?.instagram || '');
      setYoutube(whiteLabel?.youtube || '');
      setFacebookUrl(whiteLabel?.facebook || '');
      setSendGridApiKey(whiteLabel?.sendgrid_api_key || '');
      setFirst_promoter(whiteLabel?.first_promoter || '');
    }
  }, [whiteLabel, id]);

  const submitHandler = async () => {
    if (id) {
      const response = await dispatch(
        editWLSettings(
          {
            name,
            domain,
            contract_rate: contract,
            fb_auth_key: facebook,
            google_auth_key: google,
            instagram,
            youtube,
            facebook: facebookUrl,
            jivochat_url: jivoChat,
            company_phone: phone,
            google_analytics: googleAnalytics,
            facebook_pixel: facebookPixel,
            sendgrid_api_key: sendGridApiKey,
            first_promoter,
          },
          id
        )
      );

      if (response) setSettingContent('images');
    } else {
      const response = await dispatch(
        createWL({
          name,
          domain,
          contract_rate: contract,
          fb_auth_key: facebook,
          google_auth_key: google,
          instagram,
          youtube,
          facebook: facebookUrl,
          jivochat_url: jivoChat,
          company_phone: phone,
          google_analytics: googleAnalytics,
          facebook_pixel: facebookPixel,
          sendgrid_api_key: sendGridApiKey,
          first_promoter,
        })
      );

      if (response) setSettingContent('images');
    }
  };

  return (
    <div className="white-label__fields">
      <WLField text={domainText} placeholder="White Label Domain" value={domain} onChange={(e) => setDomain(e.target.value)} />
      <WLField text={whiteLabelNameText} placeholder="White Label Name" value={name} onChange={(e) => setName(e.target.value)} />
      <WLField text={phoneText} placeholder="Phone Company" value={phone} onChange={(e) => setPhone(e.target.value)} />
      <label className="white-label-label">
        <p>Contract Rate</p>
        <div className="white-label-label__inner">
          <InputNumber
            placeholder="Contract Rate"
            onChange={(value) => setContract(value)}
            className="white-label-field"
            value={contract}
            min={'0.03'}
            max="10"
            stringMode
            step="0.01"
          />
          <Helper title="Contract Rate" text={contractRate} tooltipWidth="100%" />
        </div>
      </label>
      <WLField text={facebookKeyText} placeholder="Facebook Key" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
      <WLField text={googleKeyText} placeholder="Google Key" value={google} onChange={(e) => setGoogle(e.target.value)} />
      <WLField text={jivoChatText} placeholder="Jivochat URL" value={jivoChat} onChange={(e) => setJivoChat(e.target.value)} />
      <WLField text={youtubeText} placeholder="You Tube" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
      <WLField text={instagramText} placeholder="Instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
      <WLField text={facebookText} placeholder="Facebook" value={facebookUrl} onChange={(e) => setFacebookUrl(e.target.value)} />
      <WLField text="" placeholder="SendGrid api key" value={sendGridApiKey} onChange={(e) => setSendGridApiKey(e.target.value)} />
      <WLField text="" placeholder="Facebook Pixel key" value={facebookPixel} onChange={(e) => setFacebookPixel(e.target.value)} />
      <WLField text="" placeholder="Google Analytics key" value={googleAnalytics} onChange={(e) => setGoogleAnalytics(e.target.value)} />
      <WLField text="" placeholder="First promoter key" value={first_promoter} onChange={(e) => setFirst_promoter(e.target.value)} />

      {id && (
        <div className="callendly-block white-label-label">
          <p>Callendly</p>
          <button onClick={() => setCallendly(true)}>Show Callendly</button>
        </div>
      )}
      {callendly && <CallendlyModal id={id} setShow={setCallendly} show={callendly} dispatch={dispatch} />}

      <button className="create-white-label-btn" onClick={submitHandler}>
        Save Changes
      </button>
    </div>
  );
};

export default MainSettings;
