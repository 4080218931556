import {
  EDIT_SETTINGS_DUPLICATE_FAILURE,
  EDIT_SETTINGS_DUPLICATE_REQUEST,
  EDIT_SETTINGS_DUPLICATE_SUCCESS,
  SETTINGS_DUPLICATE_FAILURE,
  SETTINGS_DUPLICATE_REQUEST,
  SETTINGS_DUPLICATE_SUCCESS,
} from '../actionsTypes';
import axiosApi from '../../utils/axios';
import { showNotification } from '../../App';

export const editSettingsRequest = () => ({ type: EDIT_SETTINGS_DUPLICATE_REQUEST });
export const editSettingsSuccess = () => ({ type: EDIT_SETTINGS_DUPLICATE_SUCCESS });
export const editSettingsFailure = (error) => ({ type: EDIT_SETTINGS_DUPLICATE_FAILURE, error });

export const getSettingsRequest = () => ({ type: SETTINGS_DUPLICATE_REQUEST });
export const getSettingsSuccess = (duplicate) => ({ type: SETTINGS_DUPLICATE_SUCCESS, duplicate });
export const getSettingsFailure = (error) => ({ type: SETTINGS_DUPLICATE_FAILURE, error });

export const getSearchSettings = () => {
  return async (dispatch) => {
    try {
      dispatch(getSettingsRequest());
      const response = await axiosApi.get(`/search-settings`);
      dispatch(getSettingsSuccess(response.data.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getSettingsFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const editPersonSearchSettings = (data) => {
  return async (dispatch) => {
    try {
      dispatch(editSettingsRequest());
      await axiosApi.put(`/search-settings/edit-person`, data);
      dispatch(editSettingsSuccess());
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(editSettingsFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const editLLCSearchSettings = (data) => {
  return async (dispatch) => {
    try {
      dispatch(editSettingsRequest());
      await axiosApi.put(`/search-settings/edit-business`, data);
      dispatch(editSettingsSuccess());
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(editSettingsFailure(error?.response?.data?.errors?.message || true));
    }
  };
};
