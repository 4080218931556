import { DatePicker, Input, Select, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { editDiscount, getSingleDiscount, getUsersForDiscount, getWLPrices } from '../../../../../store/actions/adminActions';
import './styles.scss';
const { Option } = Select;

const EditDiscount = ({ discountId, closeModal }) => {
  const { url } = useRouteMatch();
  const { id } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.admin?.singleDiscountLoading);
  const discountData = useSelector((state) => state.admin?.singleDiscount);

  const [userIds, setUserIds] = useState(null);
  const [priceIds, setPriceIds] = useState(null);
  const [availableFrom, setAvailableFrom] = useState(new Date());
  const [availableUntil, setAvailableUntil] = useState(new Date());
  const [percent, setPercent] = useState(null);
  const [code, setCode] = useState(null);
  const [usageLimit, setUsageLimit] = useState(null);

  const pricesList = useSelector((state) => state?.admin.wlPrices);
  const discountUsers = useSelector((state) => state.admin?.discountUsers);
  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const anotherWL = url.includes('white-label');
  const currentWLId = anotherWL ? id : whiteLabelId;

  const onChangeAvailableFrom = (_, dateString) => {
    if (_) setAvailableFrom(dateString);
    else setAvailableFrom(new Date());
  };
  const onChangeAvailableUntil = (_, dateString) => {
    if (_) {
      setAvailableUntil(dateString);
    } else setAvailableUntil(new Date());
  };

  const priceSelectJSX = useMemo(() => {
    const filterPricesListValue = (originalPrices) => {
      const arr = [];
      const filteredPricesList = originalPrices.filter((item) => discountData?.all_price_ids?.includes(item.id));
      filteredPricesList.forEach((item) => {
        arr.push(item.id);
      });
      return arr;
    };
    return (
      !loading && (
        <Select
          defaultValue={() => filterPricesListValue(pricesList)}
          mode="multiple"
          allowClear
          style={{
            width: '60%',
          }}
          placeholder="Select wich pricing packages"
          onChange={(e) => {
            setPriceIds(e);
          }}
        >
          {pricesList.map((elem) => {
            return (
              <Option key={elem.id} value={elem.id}>
                {elem.skips} skips
              </Option>
            );
          })}
        </Select>
      )
    );
  }, [pricesList, discountData, loading]);

  const usersSelectJSX = useMemo(() => {
    const filterUsersIds = (discountUsers) => {
      const arr = [];
      const filteredUsersIds = discountUsers.filter((item) => discountData?.all_user_ids?.includes(item.id));
      filteredUsersIds.forEach((item) => {
        arr.push(item.id);
      });

      return arr;
    };
    return (
      !loading && (
        <Select
          defaultValue={() => filterUsersIds(discountUsers)}
          mode="multiple"
          style={{
            width: '100%',
          }}
          placeholder="Add Users Here"
          onChange={(e) => setUserIds(e)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          className="m-b-25"
          allowClear
        >
          {discountUsers.map((elem) => {
            return (
              <Option key={elem.id} value={elem.id}>
                {elem.email}
              </Option>
            );
          })}
        </Select>
      )
    );
  }, [discountUsers, discountData, loading]);

  const updateDiscount = async () => {
    const data = { userIds, priceIds, availableFrom, availableUntil, percent, usageLimit, code, name: '' };
    const response = await dispatch(editDiscount(currentWLId, discountId, data));
    if (response) {
      setUserIds(null);
      setPriceIds(null);
      setAvailableFrom(null);
      setAvailableUntil(null);
      setPercent(null);
      setCode(null);
      setUsageLimit(null);
      closeModal();
    }
  };

  useEffect(() => {
    dispatch(getWLPrices(currentWLId));
    dispatch(getUsersForDiscount(currentWLId));
  }, [dispatch, currentWLId]);

  useEffect(() => {
    dispatch(getSingleDiscount(currentWLId, discountId));
  }, [dispatch, currentWLId, discountId]);

  useEffect(() => {
    if (discountData) {
      const { available_from, available_until, code, percent, usage_limit, all_user_ids, all_price_ids } = discountData;
      setUserIds(all_user_ids);
      setPriceIds(all_price_ids);
      setAvailableFrom(available_from);
      setAvailableUntil(available_until);
      setPercent(percent);
      setCode(code);
      setUsageLimit(usage_limit);
    }
  }, [discountData, discountId]);

  return (
    <Spin spinning={loading}>
      <div className="modal__discount">
        <h2 style={{ textAlign: 'center' }} className="m-b-40">
          Edit discount
        </h2>
        <div className="discount-input__container m-b-25">
          <Input
            placeholder="Code Name"
            style={{ width: '40%', marginRight: '10px' }}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            value={code}
          />

          <Input
            placeholder="number of uses"
            style={{ width: '40%', marginLeft: '10px' }}
            onChange={(e) => {
              setUsageLimit(e.target.value);
            }}
            value={usageLimit}
          />
        </div>
        <div className="discount-input__container m-b-25">
          <DatePicker
            disabled
            value={moment(availableFrom)}
            placeholder="Available From"
            onChange={onChangeAvailableFrom}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <DatePicker value={moment(availableUntil)} placeholder="Available Until" onChange={onChangeAvailableUntil} style={{ width: '20%' }} />
        </div>
        <div className="discount-input__container m-b-25">
          <Input placeholder="Discount % rate" style={{ width: '40%', marginRight: '10px' }} onChange={(e) => setPercent(e.target.value)} value={percent} />
          {priceSelectJSX}
        </div>

        {usersSelectJSX}

        <div style={{ textAlign: 'right' }}>
          <button className="discount__btn  cancel" onClick={closeModal}>
            cancel
          </button>
          <button className="discount__btn save" onClick={updateDiscount}>
            save
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default EditDiscount;
