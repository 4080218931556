import { Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import BusinessFiles from './BusinessFiles';
import PersonFiles from './PersonFiles';
import classnames from 'classnames';
import './styles.scss';
import Navigation from '../../Navigation';
import classNames from 'classnames';

const FilesStatistic = () => {
  const [content, setContent] = useState(0);
  const { url } = useRouteMatch();
  const { id, userId } = useParams();
  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const anotherWL = url.includes('white-label');
  const currentWLId = anotherWL ? id : whiteLabelId;
  const personalPages = useSelector((state) => state.admin?.personFilesPages);
  const businessPages = useSelector((state) => state.admin?.businessFilesPages);
  const personFilesLoading = useSelector((state) => state.admin.personFilesLoading);
  const businessFilesLoading = useSelector((state) => state.admin?.businessFilesLoading);

  const isLoading = useMemo(() => {
    return personFilesLoading || businessFilesLoading;
  }, [personFilesLoading, businessFilesLoading]);

  const renderFilesContent = (step) => {
    switch (step) {
      case 0:
        return <PersonFiles userID={userId} wlID={currentWLId} loading={personFilesLoading} />;
      case 1:
        return <BusinessFiles userID={userId} wlID={currentWLId} loading={businessFilesLoading} />;
      default:
        return null;
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="file-type__container">
        <div className={classnames('btn', { 'active-type': content === 0 })} onClick={() => setContent(0)}>
          Person
        </div>
        <div className={classnames('btn', { 'active-type': content === 1 })} onClick={() => setContent(1)}>
          Business
        </div>
      </div>

      {/* container-statistic-pagination */}
      <div className={classNames('file-statistic__container', { 'container-statistic-pagination': personalPages > 1 || businessPages > 1 })}>
        <Navigation id={currentWLId} userId={userId} />
        {renderFilesContent(content)}
      </div>
    </Spin>
  );
};

export default FilesStatistic;
