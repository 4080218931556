import React from 'react';
import { Tooltip } from 'antd';
import './styles.scss';

const DataMatchingField = ({ onClick, draggable = false, label, value, error, width = '100%', parentClassName, shortValue = null }) => {
  return (
    <div className={`data-matching__label ${parentClassName}`} style={{ color: error ? '#FF4C4C' : 'white' }} draggable={draggable} onClick={onClick}>
      {label}
      <div style={{ width, border: error ? '1px solid #FF4C4C' : null }} className="data-matching__field">
        {shortValue ? (
          <Tooltip title={value} destroyTooltipOnHide={false}>
            <p>{shortValue || value}</p>
          </Tooltip>
        ) : (
          <p>{shortValue || value}</p>
        )}
      </div>
    </div>
  );
};

export default DataMatchingField;
