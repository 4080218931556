import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../../../../Components/common/Checkbox/Checkbox';
import { editLLCSearchSettings, editPersonSearchSettings, getSearchSettings } from '../../../../store/actions/searchSettingsAction';
import { Spin } from 'antd';
import './styles.scss';

const SearchSettings = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.searchSettings.loading);
  const duplicate = useSelector((state) => state.searchSettings.duplicate);

  const [blockedPerson, setBlockedPerson] = useState(true);
  const [blockedBusiness, setBlockedBusiness] = useState(true);

  const [first_name, setFirst_name] = useState(true);
  const [middle_name, setMiddle_name] = useState(true);
  const [last_name, setLast_name] = useState(true);
  const [address, setAddress] = useState(true);
  const [city, setCity] = useState(true);
  const [state, setstate] = useState(true);

  const [business_name, setBusiness_name] = useState(true);
  const [business_city, setBusiness_city] = useState(true);
  const [business_state, setBusiness_state] = useState(true);

  const personEditHandler = () => {
    dispatch(
      editPersonSearchSettings({
        fields: {
          first_name,
          middle_name,
          last_name,
          address,
          city,
          state,
        },
      })
    );
    setBlockedPerson((prev) => !prev);
  };

  const businessEditHandler = () => {
    dispatch(
      editLLCSearchSettings({
        fields: { business_name, city: business_city, state: business_state },
      })
    );
    setBlockedBusiness((prev) => !prev);
  };

  const businessHandler = () => {
    setBlockedBusiness((prev) => !prev);
  };

  const personHandler = () => {
    setBlockedPerson((prev) => !prev);
  };

  useEffect(() => {
    dispatch(getSearchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(duplicate).length) {
      setFirst_name(duplicate?.person?.first_name);
      setMiddle_name(duplicate?.person?.middle_name);
      setLast_name(duplicate?.person?.last_name);
      setAddress(duplicate?.person?.address);
      setCity(duplicate?.person?.city);
      setstate(duplicate?.person?.state);

      setBusiness_name(duplicate.business?.business_name);
      setBusiness_city(duplicate.business?.city);
      setBusiness_state(duplicate.business?.state);
    }
  }, [duplicate]);

  return (
    <div className="search-settings">
      <h1>Duplicate Detection Settings</h1>
      <h2>
        Select all the fields that you want to have an exact match for within your submissions in order for our duplicate detection to trigger. Note that we
        recommend, as a rule of thumb, that you always select at least three fields.
      </h2>

      <div className="search-settings__body">
        {loading ? (
          <Spin spinning={loading} size="large" className="m-a-auto" />
        ) : (
          <>
            <div className="search-settings__form">
              <div className="search-settings__form-action">
                <h2>Person Skiptrace:</h2>
                <div className="flex flex-align-items-center g-10">
                  {blockedPerson ? <button onClick={personHandler}>Edit</button> : <button onClick={personHandler}>Cancel</button>}
                  <button onClick={personEditHandler} disabled={blockedPerson}>
                    Save
                  </button>
                </div>
              </div>
              <div className="search-settings__form-checkbox">
                <CheckBox
                  className="search-settings__checkbox"
                  text="First Name"
                  id={1}
                  checked={first_name}
                  setChecked={setFirst_name}
                  disabled={blockedPerson}
                />
                <CheckBox
                  className="search-settings__checkbox"
                  text="Middle Name"
                  id={2}
                  checked={middle_name}
                  setChecked={setMiddle_name}
                  disabled={blockedPerson}
                />
                <CheckBox
                  className="search-settings__checkbox"
                  text="Last Name"
                  id={3}
                  checked={last_name}
                  setChecked={setLast_name}
                  disabled={blockedPerson}
                />
                <CheckBox
                  className="search-settings__checkbox"
                  text="Address Line 1"
                  id={4}
                  checked={address}
                  setChecked={setAddress}
                  disabled={blockedPerson}
                />
                <CheckBox className="search-settings__checkbox" text="Mailing City" id={5} checked={city} setChecked={setCity} disabled={blockedPerson} />
                <CheckBox className="search-settings__checkbox" text="Mailing State" id={6} checked={state} setChecked={setstate} disabled={blockedPerson} />
              </div>
            </div>
            <div className="search-settings__form">
              <div className="search-settings__form-action">
                <h2>LLC Skiptrace:</h2>
                <div className="flex flex-align-items-center g-10">
                  {blockedBusiness ? <button onClick={businessHandler}>Edit</button> : <button onClick={businessHandler}>Cancel</button>}
                  <button onClick={businessEditHandler} disabled={blockedBusiness}>
                    Save
                  </button>
                </div>
              </div>

              <div className="search-settings__form-checkbox">
                <CheckBox
                  className="search-settings__checkbox"
                  text="Business Name"
                  id={7}
                  checked={business_name}
                  setChecked={setBusiness_name}
                  disabled={blockedBusiness}
                />
                <CheckBox
                  className="search-settings__checkbox"
                  text="Mailing City"
                  id={8}
                  checked={business_city}
                  setChecked={setBusiness_city}
                  disabled={blockedBusiness}
                />
                <CheckBox
                  className="search-settings__checkbox"
                  text="Mailing State"
                  id={9}
                  checked={business_state}
                  setChecked={setBusiness_state}
                  disabled={blockedBusiness}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchSettings;
