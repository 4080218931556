import React from 'react';
import Callendly from './Callendly';
import { Modal, Spin } from 'antd';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import './styles.scss';

const CallendlyList = ({ show, setShow , list, loading}) => {
  const width = useResizeWindows();
  const modalWidth = width <= 1300 ? '1180px' : width <= 1500 ? '95%' : '80%';

  return (
    <Modal
      bodyStyle={{ width: width <= 1300 ? '1180px' : '100%', padding: '0', borderRadius: '50px', minHeight: width <= 1500 ? '590px' : '100%' }}
      visible={show}
      onCancel={() => setShow(false)}
      footer={null}
      width={modalWidth}
      className="modal-callendly-cards"
    >
      <Spin spinning={loading} size="large">
        <h1>Representatives</h1>
        <div className="modal-callendly-cards__body">
          {list.length ? list.map((user) => <Callendly user={user} key={user.id} setParentModal={setShow} />) : null}
        </div>
      </Spin>
    </Modal>
  );
};

export default CallendlyList;
