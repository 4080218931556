import React, { useContext, useMemo } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { ADMIN_REPORTS_ROUTE_PATHS, BASIC_REPORTS_ROUTE_PATHS } from '../../../../utils/constants';
import { AppContext } from '../../../../appContext';
import './styles.scss';

const Navigation = ({ id }) => {
  const params = useParams();
  const { isUserBasic, isUserSuperAdmin, isUserAdmin, isUserMainAdmin } = useContext(AppContext);
  const pathname = useHistory().location.pathname;

  const navigation = useMemo(() => {
    const SUPER_ADMIN_ROUTE_PATHS = [
      {
        url: `/white-label/${id}/reports`,
        label: 'Transaction History',
        exact: false,
      },
      {
        url: `/white-label/${id}/user-activity`,
        label: 'User Activity History',
      },

      {
        url: `/white-label/${id}/skiptrace`,
        label: 'Skiptrace History',
        exact: false,
      },
      {
        url: `/white-label/${id}/summary`,
        label: 'WL Summary',
        exact: false,
      },
    ];

    return isUserMainAdmin && !pathname.includes('admin')
      ? SUPER_ADMIN_ROUTE_PATHS
      : _.isEmpty(params) && (isUserAdmin || isUserMainAdmin)
      ? ADMIN_REPORTS_ROUTE_PATHS
      : isUserBasic && pathname.includes('admin')
      ? BASIC_REPORTS_ROUTE_PATHS
      : isUserSuperAdmin && SUPER_ADMIN_ROUTE_PATHS;
  }, [params, id, isUserBasic, isUserSuperAdmin, isUserAdmin, isUserMainAdmin, pathname]);

  return (
    <nav className={`white-label__nav ${!isUserAdmin && !isUserMainAdmin && isUserBasic && pathname.includes('admin') ? 'white-label__nav-basic' : ''}`}>
      {navigation.map((route, index) => {
        return (
          <NavLink
            className={`white-label__nav-link `}
            activeClassName="white-label__nav-link--active"
            to={route.url}
            exact={route.exact}
            style={{ zIndex: navigation.length - index }}
            key={index}
          >
            {route.label}
          </NavLink>
        );
      })}
    </nav>
  );
};

export default Navigation;
