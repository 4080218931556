import React from 'react';
import { Avatar, Popover, Tooltip } from 'antd';
import { apiURL } from '../../../../../utils/config';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { roleFiltered } from '../../../../../utils/functions';
import Content from './Content';
import history from '../../../../../utils/history';

const Row = ({ user, anotherWL, currentWLId, actionApprove, url }) => {
  const historyPushHandler = (id) => {
    history.push(`${url}/statistic/${id}`);
  };

  return (
    <li className="users-list__item">
      <span>{user.id}</span>
      <span onClick={() => historyPushHandler(user?.user_id)} className="historyPush__link">
        {user?.avatar ? (
          <Avatar src={user?.avatar.includes('http') ? user?.avatar : apiURL + '/uploads/avatars/' + user?.avatar} />
        ) : (
          <Avatar icon={<UserOutlined />} />
        )}
        <Tooltip placement="top" title={`${user?.first_name} ${user?.last_name}`}>
          {`${user?.first_name} ${user?.last_name}`.slice(0, 15)}
        </Tooltip>
      </span>
      <span>{user?.email}</span>
      <span>{user?.phone ? user.phone : 'not specified'}</span>
      <span>{user?.created_at}</span>
      <span>{user?.skips}</span>
      <span>{user?.total_hits}</span>
      <span>{user?.roles.length > 1 ? roleFiltered(user?.roles).join(', ') : roleFiltered(user?.roles).join('')}</span>
      {user?.deleted_at ? (
        <Tooltip title={`Banned at ${user?.deleted_at}`}>
          <span className="users-list__item-status">Banned</span>
        </Tooltip>
      ) : (
        <span className="users-list__item-status">Active</span>
      )}
      <span>{user?.receive_notifications ? 'yes' : 'no'}</span>
      {actionApprove && (
        <span>
          <Popover
            placement="bottomLeft"
            content={() => (
              <Content
                userId={user?.user_id}
                userRole={user?.roles}
                isVerified={user?.is_verified}
                wlURL={user?.user_domain}
                banned={user?.deleted_at}
                name={user?.email}
                anotherWL={anotherWL}
                currentWLId={currentWLId}
              />
            )}
            trigger="click"
          >
            <MenuOutlined />
          </Popover>
        </span>
      )}
    </li>
  );
};

export default Row;
