import React, { useEffect, useState } from 'react';
import Download from '../../../assets/pop-up/table-download.svg';
import InfoIcon from '../../../assets/UI/info_icon.svg';
import CheckBox from '../../../Components/common/Checkbox/Checkbox';
import { nanoid } from 'nanoid';
import Tooltip from '../../../Components/common/Tooltip/Tooltip';
import { downloadAllBusinessFiles, downloadAllFiles } from '../../../store/actions/fileActions';
import { useDispatch } from 'react-redux';
import FileIcon from '../../../assets/UI/file_table.svg';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { Link } from 'react-router-dom';
import SubRow from '../../MyResults/components/Row';
import _ from 'lodash';
import { sliceString } from '../../../utils/functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Tooltip as TooltipAntd } from 'antd';
import './styles.scss';

const Row = ({
  type,
  id,
  files = [],
  name,
  skeleton,
  results = 0,
  totalHits,
  totalRecord,
  allChecked,
  index,
  setAllFilesId,
  date,
  multiply,
  process,
  totalDuplicate,
}) => {
  const dispatch = useDispatch();
  const [tooltip, setTooltip] = useState(false);
  const [more, setMore] = useState(false);
  const [parentChecked, setParentChecked] = useState(false);
  const width = useResizeWindows();

  useEffect(() => {
    if (allChecked && !skeleton) setParentChecked(true);
    else if (!allChecked) setParentChecked(false);
  }, [allChecked, skeleton]);

  const checkedHandler = (checked) => {
    setParentChecked(checked);
    if (checked) setAllFilesId((prev) => _.unionWith([...prev, ...files.map((item) => item.id)], _.isEqual));
    else
      setAllFilesId((prev) =>
        _.difference(
          prev,
          files.map((item) => item.id)
        )
      );
  };

  const downloadFile = () => {
    if (!type) dispatch(downloadAllFiles(files && files.map((item) => item.id), process, true));
    else dispatch(downloadAllBusinessFiles(files && files.map((item) => item.id), process, false));
  };

  return (
    <>
      {skeleton ? (
        <div className="row skeleton">
          <CheckBox className="checkbox" setChecked={setParentChecked} checked={parentChecked} id={nanoid()} disabled={skeleton} />
          <div className="tr skeleton-tr" />
        </div>
      ) : (
        <>
          <div className="row" style={{ position: index ? 'relative' : 'initial' }}>
            <CheckBox className="checkbox" setChecked={checkedHandler} checked={parentChecked} id={id + nanoid()} />
            <div className="tr">
              <div className="td">
                {multiply && (
                  <button className="plus" onClick={() => setMore(!more)}>
                    <span>{more ? '-' : '+'}</span>
                  </button>
                )}
                <TooltipAntd placement="topLeft" title={name} overlayStyle={{ width: '100%' }}>
                  <Link to={`${type ? `/library/business/${id}` : `/library/${id}`}`} style={{ flex: multiply ? 1 : 'initial' }}>
                    {sliceString(width, name, 40, multiply ? 20 : 50, multiply ? 12 : 40)}
                    {multiply && (
                      <span className="multiply">
                        <LazyLoadImage src={FileIcon} alt="" />
                        <span>MULTIPLE FILES</span>
                      </span>
                    )}
                  </Link>
                </TooltipAntd>
              </div>
              <p className="td">{date}</p>
              <p className="td">{!multiply ? files[0].expiration_date : null}</p>
              <p className="td">{results} Results</p>
              <button className="td" onClick={() => setTooltip(!tooltip)}>
                <LazyLoadImage className="action__icon" src={InfoIcon} alt="icon" />
              </button>
              <button className="td" onClick={downloadFile}>
                <LazyLoadImage className="action__icon" src={Download} alt="icon" />
              </button>
            </div>
            <Tooltip show={tooltip} setShow={setTooltip} totalHits={totalHits} totalRecord={totalRecord} index={index} totalDuplicates={totalDuplicate} />
          </div>
          <div className="results" style={{ padding: '0 30px', display: more ? 'block' : 'none', margin: 0 }}>
            {multiply &&
              files.length > 1 &&
              files.map((item, index) => (
                <SubRow
                  type={type}
                  subRow
                  key={item.id}
                  id={item.id}
                  index={index}
                  expired={item.expiration_date}
                  name={item.original_name}
                  results={item.results}
                  totalRecord={item.total_records}
                  totalHits={item.total_hits}
                  setAllFilesId={setAllFilesId}
                  allChecked={parentChecked}
                  setParentChecked={setParentChecked}
                />
              ))}
          </div>
        </>
      )}
    </>
  );
};
export default Row;
