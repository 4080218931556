import React, { useEffect, useRef, useState } from 'react';
import { CheckCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Tooltip } from 'antd';
import {
  addBusinessConversionProcess,
  addPersonConversionProcess,
  changeBusinessProcessData,
  changeProcessData,
  changeProcessStatus,
  getBusinessProcessesHistory,
  getProcessesHistory,
  removeHistory,
} from '../../store/actions/processActions';
import { Link, useHistory } from 'react-router-dom';
import Finder from '../../Components/common/Finder/Finder';
import CustomScrollbar from '../../Components/common/CustomScrollBar/CustomScrollBar';
import Line from '../../assets/dashboard/line-icon.png';
import { downloadDashboardBusinessFiles, downloadDashboardFiles, postBusinessFilesSuccess, postFilesSuccess } from '../../store/actions/fileActions';
import Status from '../../Components/common/Status/Status';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { sliceString } from '../../utils/functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactGa from 'react-ga4';
import { wsURL } from '../../utils/config';
import { userProfile } from '../../store/actions/userActions';
import './styles.scss';

const Dashboard = () => {
  const dispatch = useDispatch();
  const pathname = useHistory().location.pathname;
  const history = useSelector((state) => state?.process.history);
  const loading = useSelector((state) => state?.process.loading);
  const pages = useSelector((state) => state?.process.history_pages);
  const wsUser = useSelector((state) => state.user.profile);
  // const emailForScript = useSelector((state) => state.user?.profile?.email);
  const [page, setPage] = useState(1);
  const [filesPeople, setFilesPeople] = useState('');
  const [filesBusiness, setFilesBusiness] = useState('');
  const [state, setState] = useState(true);
  const ref = useRef();
  const width = useResizeWindows();
  const ws = useRef(null);

  useEffect(() => {
    if (state) dispatch(getProcessesHistory(page));
    else dispatch(getBusinessProcessesHistory(page));
  }, [dispatch, page, state]);

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFilesHandler = (array) => {
    if (state) dispatch(downloadDashboardFiles(array.map((item) => item.id)));
    else dispatch(downloadDashboardBusinessFiles(array.map((item) => item.id)));
  };

  const navigationHandler = (state) => {
    dispatch(removeHistory());
    setState(state);
    setPage(1);
    ref.current.scrollIntoView({ block: 'start' });
  };

  useEffect(() => {
    if (wsUser && wsUser?.id && ws) {
      ws.current = new WebSocket(wsURL);
      ws.current.onopen = () => {
        ws.current.send(JSON.stringify({ user: wsUser?.id && wsUser?.id.toString() }));
      };

      ws.current.onmessage = (e) => {
        const message = JSON.parse(e.data);
        const status =
          message.type === 'PROCESS_EXPIRED' || message.type === 'BUSINESS_PROCESS_EXPIRED'
            ? 'expired'
            : message.type === 'PROCESS_COMPLETED' || message.type === 'BUSINESS_PROCESS_COMPLETED'
            ? 'completed'
            : message.type === 'PROCESS_CONVERSION_ERROR' || message.type === 'BUSSINESS_PROCESS_CONVERSION_ERROR'
            ? 'error'
            : false;

        if (message.skips) dispatch(userProfile());

        if (message.type === 'PROCESS_CONVERSION' && message.progress === 'draft') {
          dispatch(postFilesSuccess(message?.data?.person_files, message?.data?.total_count_rows, message?.data?.id));
          dispatch(changeProcessData(message?.data?.person_files, message?.data?.total_count_rows, message?.data?.id));
          dispatch(changeProcessStatus(message?.data?.process_id, message.progress));
        }

        if (message.type === 'PROCESS_CONVERSION' && message.progress === 'conversion') {
          dispatch(addPersonConversionProcess(message?.data));
        }

        if (message.type === 'BUSINESS_PROCESS_CONVERSION' && message.progress === 'draft') {
          dispatch(postBusinessFilesSuccess(message?.data?.business_files, message?.data?.total_count_rows, message?.data?.id));
          dispatch(changeBusinessProcessData(message?.data?.business_files, message?.data?.total_count_rows, message?.data?.id));
          dispatch(changeProcessStatus(message?.data?.process_id, message.progress));
        }

        if (message.type === 'BUSINESS_PROCESS_CONVERSION' && message.progress === 'conversion') {
          dispatch(addBusinessConversionProcess(message?.data));
        }

        if (status) dispatch(changeProcessStatus(message.data.process_id ?? message.data.id, status));
      };

      const ping = () => {
        ws.current.send(JSON.stringify({ user: wsUser?.id && wsUser?.id.toString() }));
      };

      const pingInterval = setInterval(ping, 50000);

      return () => {
        clearInterval(pingInterval);
        ws.current.close();
      };
    }
  }, [ws, wsUser, dispatch]);

  return (
    <div className="dashboard">
      <div className="dashboard__body">
        <div className="dashboard__body-item">
          <Finder state={state} file={filesPeople} setFile={setFilesPeople} title="Import Data for" blueTitle="Person Skiptrace" margin />
          <Finder state={state} type="business" file={filesBusiness} setFile={setFilesBusiness} title="Import Data for" blueTitle="LLC Skiptrace" />
        </div>
        <div className="dashboard__body-item">
          <div className="table">
            <div className="table__article">
              <button disabled={state || loading} className={`table-btn ${state ? 'table-btn__active' : ''}`} onClick={() => navigationHandler(true)}>
                People
              </button>
              <h3>Import History</h3>
              <button disabled={!state || loading} className={`table-btn ${!state ? 'table-btn__active' : ''}`} onClick={() => navigationHandler(false)}>
                Business
              </button>
            </div>
            <div className="table__header">
              <div className="tr">
                <div className="td">Name</div>
                <div className="td">Date</div>
                <div className="td">Status</div>
                <div className="td">DOWNLOAD</div>
                <div className="td">VIEW</div>
              </div>
            </div>
            <div className="table__body">
              <Spin spinning={loading} size="large" style={{ minHeight: width > 1600 ? '490px' : '400px' }}>
                <CustomScrollbar maxHeight={width > 1600 ? '490px' : width < 1400 ? '395px' : '410px'}>
                  <div ref={ref} />
                  {history.map(({ name, status, created_at, id, person_files, business_files, total_count_rows, error_description }, index) => (
                    <div className={`tr ${index % 2 ? '' : 'tr-bg'}`} key={index}>
                      <div className="td">
                        <Tooltip placement="top" title={name}>
                          <b>{sliceString(width, name, 35, 15, 12)}</b>
                        </Tooltip>
                      </div>
                      <div className="td">{created_at}</div>
                      <div className="td">
                        <Status
                          type={!!business_files}
                          id={id}
                          status={status}
                          total_count_rows={total_count_rows}
                          files={business_files ? business_files : person_files}
                          error_description={error_description}
                        />
                      </div>
                      <div className="td">
                        {status === 'completed' ? (
                          <Tooltip placement="top" title="Download" onClick={() => downloadFilesHandler(business_files ? business_files : person_files)}>
                            <CheckCircleOutlined style={{ fontSize: '20px', color: 'var(--main-color)' }} />
                          </Tooltip>
                        ) : status === 'expired' ? (
                          <CheckCircleOutlined style={{ fontSize: '20px', color: '#b1b1b1' }} />
                        ) : (
                          <p>
                            <LazyLoadImage src={Line} alt="-" />
                          </p>
                        )}
                      </div>
                      <div className="td">
                        <Link
                          to={status === 'completed' && state ? `/library/${id}` : status === 'completed' && !state ? `/library/business/${id}` : '#'}
                          disabled={status !== 'completed'}
                        >
                          <EyeOutlined style={{ fontSize: '20px', color: 'var(--main-color)' }} />
                        </Link>
                      </div>
                    </div>
                  ))}
                </CustomScrollbar>
              </Spin>
            </div>
            {pages > 1 && (
              <button className="primary-button" onClick={() => setPage((prev) => (prev + 1 <= pages ? prev + 1 : prev))}>
                View More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
