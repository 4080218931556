import React, { useContext, useEffect, useState } from 'react';
import TextField from '../../Components/common/TextField/TextField';
import MessageIcon from '../../Components/SVG-Icon/MessageIcon';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../store/actions/userActions';
import { Spin } from 'antd';
import UserNameIcon from '../../assets/auth/userName.svg';
import { Link, useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { apiURL } from '../../utils/config';
import { toCapitalize } from '../../utils/functions';
import moment from 'moment/moment';
import { AppContext } from '../../appContext';
import AuthenticationLayout from '../../Components/UI/AuthenticationLayout/AuthenticationLayout';
import SocialBlock from '../../Components/UI/SocialBlock/SocialBlock';
import ReactGa from 'react-ga4';
import './styles.scss';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const [email, setEmail] = useState('');
  const [state, setState] = useState(false);
  const [error, setError] = useState(false);
  const loading = useSelector((state) => state?.user.loading);
  const pathname = useHistory().location.pathname;

  const { brand, isSkipCloud } = useContext(AppContext);

  // whitelabel images from config
  const subLogo = useSelector((state) => state.config.config?.images?.logo_2);
  const authBackground = useSelector((state) => state.config.config?.images?.auth_background);

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forgotHandler = async () => {
    if (email.trim().includes('@') || email.trim() > 3) {
      setError(false);
      const response = await dispatch(forgotPassword({ email }));
      if (response) setState(true);
    } else {
      setError(true);
    }
  };

  const enterSubmitHandler = async (e) => {
    if (e.code === 'Enter') {
      await forgotHandler();
    }
  };

  let textFieldButtonSize = '430px';
  if (width < 460 && width > 370) {
    textFieldButtonSize = '350px';
  } else if (width <= 370) {
    textFieldButtonSize = '300px';
  }

  const registerStyles = {
    margin: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${apiURL + authBackground}) 0 -2px no-repeat`,
    backgroundSize: isSkipCloud ? 'contain' : 'cover',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    height: 'calc(100vh)',
  };

  return (
    <div className="auth" style={registerStyles}>
      <div className="auth__body">
        {width >= 1200 && <AuthenticationLayout />}
        {!state ? (
          <div className="auth__body-form m-t-auto m-b-auto">
            <LazyLoadImage className="login__logo" draggable={false} src={`${apiURL + subLogo}`} alt="logo" />
            <h3 className="auth__title">
              Forgot <span>Password</span>
            </h3>
            <div className="login__border" />
            <Spin spinning={loading} size={'large'}>
              <TextField
                width={textFieldButtonSize}
                icon={UserNameIcon}
                error={error}
                value={email}
                setState={setEmail}
                type="text"
                placeholder="Email"
                parentClassName="m-t-20"
                onKeyPress={enterSubmitHandler}
              />
            </Spin>
            <button className="auth__button m-b-10 m-t-30" onClick={forgotHandler}>
              Forgot password
            </button>
            <div className="login__border m-b-25" />
            <p className="forgot__login ">
              Go back to
              <Link className="forgot__link" to="/login">
                Login?
              </Link>
            </p>
            <div className="login__border" />
            {width <= 1200 && <SocialBlock />}
          </div>
        ) : (
          <div className="forgot__message">
            <LazyLoadImage className="login__logo" draggable={false} src={`${apiURL + subLogo}`} alt="logo" />
            <h3 className="auth__title">
              Forgot <span>Password</span>
            </h3>
            <div className="login__border" />
            <div style={{ marginTop: '40px' }}>
              <MessageIcon />
            </div>
            <h3>Check your email</h3>
            <p className="m-b-20 m-t-20">
              We have sent a password reset request to<span className="m-l-5">{email}</span>
            </p>
            <div className="login__border m-b-25" />
            <p className="forgot__login ">
              Go back to
              <Link className="forgot__link" to="/login">
                Login?
              </Link>
            </p>
            <div className="login__border" />
          </div>
        )}
      </div>
      <div className="auth__footer">
        <div className="auth__footer__outer">
          <p>
            All rights reserved. {toCapitalize(brand)}, LLC. {moment(new Date()).format('YYYY')}
          </p>{' '}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
