import {
  EDIT_EMAIL_FAILURE,
  EDIT_EMAIL_REQUEST,
  EDIT_EMAIL_SUCCESS,
  EDIT_PASSWORD_FAILURE,
  EDIT_PASSWORD_REQUEST,
  EDIT_PASSWORD_SUCCESS,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  FORGOT_USER_FAILURE,
  FORGOT_USER_REQUEST,
  FORGOT_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  PAYMENT_STRIPE_FAILURE,
  PAYMENT_STRIPE_REQUEST,
  PAYMENT_STRIPE_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  RESET_USER_FAILURE,
  RESET_USER_REQUEST,
  RESET_USER_SUCCESS,
  USER_PROFILE_FAILURE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  UPDATE_RECEIVE_NOTIFICATIONS_REQUEST,
  UPDATE_RECEIVE_NOTIFICATIONS_SUCCESS,
  UPDATE_RECEIVE_NOTIFICATIONS_FAILURE,
} from '../actionsTypes';
import axiosApi from '../../utils/axios';
import { saveToLocalStorage } from '../../utils/localStorage';
import history from '../../utils/history';
import { showNotification } from '../../App';

const loginUserRequest = () => ({ type: LOGIN_USER_REQUEST });
const loginUserSuccess = (user) => ({ type: LOGIN_USER_SUCCESS, user });
const loginUserFailure = (error) => ({ type: LOGIN_USER_FAILURE, error });

const registerUserRequest = () => ({ type: REGISTER_USER_REQUEST });
const registerUserSuccess = (user) => ({ type: REGISTER_USER_SUCCESS, user });
const registerUserFailure = (error) => ({ type: REGISTER_USER_FAILURE, error });

const forgotUserRequest = () => ({ type: FORGOT_USER_REQUEST });
const forgotUserSuccess = () => ({ type: FORGOT_USER_SUCCESS });
const forgotUserFailure = (error) => ({ type: FORGOT_USER_FAILURE, error });

const resetUserRequest = () => ({ type: RESET_USER_REQUEST });
const resetUserSuccess = () => ({ type: RESET_USER_SUCCESS });
const resetUserFailure = (error) => ({ type: RESET_USER_FAILURE, error });

const userProfileRequest = () => ({ type: USER_PROFILE_REQUEST });
const userProfileSuccess = (profile) => ({ type: USER_PROFILE_SUCCESS, profile });
const userProfileFailure = (error) => ({ type: USER_PROFILE_FAILURE, error });

const editProfileRequest = () => ({ type: EDIT_PROFILE_REQUEST });
const editProfileSuccess = (profile) => ({ type: EDIT_PROFILE_SUCCESS, profile });
const editProfileFailure = (error) => ({ type: EDIT_PROFILE_FAILURE, error });

const editEmailRequest = () => ({ type: EDIT_EMAIL_REQUEST });
const editEmailSuccess = () => ({ type: EDIT_EMAIL_SUCCESS });
const editEmailFailure = (error) => ({ type: EDIT_EMAIL_FAILURE, error });

const createAvatarRequest = () => ({ type: EDIT_EMAIL_REQUEST });
const createAvatarSuccess = () => ({ type: EDIT_EMAIL_SUCCESS });
const createAvatarFailure = (error) => ({ type: EDIT_EMAIL_FAILURE, error });

const paymentStripeRequest = () => ({ type: PAYMENT_STRIPE_REQUEST });
const paymentStripeSuccess = (profile) => ({ type: PAYMENT_STRIPE_SUCCESS, profile });
const paymentStripeFailure = (error) => ({ type: PAYMENT_STRIPE_FAILURE, error });

const editPasswordRequest = () => ({ type: EDIT_PASSWORD_REQUEST });
const editPasswordSuccess = (states) => ({ type: EDIT_PASSWORD_SUCCESS, states });
const editPasswordFailure = (error) => ({ type: EDIT_PASSWORD_FAILURE, error });

export const loginUser = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(loginUserRequest());
      const response = await axiosApi.post('/login', userData);
      await dispatch(loginUserSuccess(response.data));
      history.push('/dashboard');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(loginUserFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const loginGoogle = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(loginUserRequest());
      const response = await axiosApi.post('/google/auth', userData);
      await dispatch(loginUserSuccess(response.data));

      if (response.data?.register) window.fpr('referral', { email: userData.email });
      history.push('/dashboard');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(loginUserFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const loginFacebook = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(loginUserRequest());
      const response = await axiosApi.post('/facebook/auth', userData);
      await dispatch(loginUserSuccess(response.data));
      if (response.data?.register) window.fpr('referral', { email: userData.email });
      history.push('/dashboard');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(loginUserFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const registerUser = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(registerUserRequest());
      const response = await axiosApi.post('/register', userData);
      dispatch(registerUserSuccess(null));
      saveToLocalStorage('verify-email', userData.email);
      history.push('/verify-email');
      showNotification('success', response.data.message, 'Please check your Email', 30, 'top');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(registerUserFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(registerUserFailure({ global: 'No internet' }));
      }
    }
  };
};

export const registerUserInvites = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(registerUserRequest());
      const response = await axiosApi.post('/register-by-invite', userData);
      dispatch(registerUserSuccess(response.data));
      history.push('/dashboard');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(registerUserFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(registerUserFailure({ global: 'No internet' }));
      }
    }
  };
};

export const forgotPassword = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(forgotUserRequest());
      await axiosApi.post('/reset-password', userData);
      dispatch(forgotUserSuccess());
      return true;
    } catch (error) {
      if (error?.response?.data?.errors?.message || true) {
        dispatch(forgotUserFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(forgotUserFailure({ global: 'No internet' }));
      }
      return false;
    }
  };
};

export const resetPassword = (token, userData) => {
  return async (dispatch) => {
    try {
      dispatch(resetUserRequest());
      await axiosApi.post(`/reset-password/reset/${token}`, userData);
      dispatch(resetUserSuccess());
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(resetUserFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(resetUserFailure({ global: 'No internet' }));
      }
      return false;
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    saveToLocalStorage('users', {});
    await axiosApi.post('/logout');
    dispatch({ type: LOGOUT_USER });
    showNotification('success', 'Log out success', 'Log out');
  };
};

export const userProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(userProfileRequest());
      const response = await axiosApi.get('/profile');
      dispatch(userProfileSuccess(response?.data.user));
    } catch (error) {
      dispatch(userProfileFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const editProfile = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(editProfileRequest());
      const response = await axiosApi.put('/profile/edit', userData);
      dispatch(userProfile());
      dispatch(editProfileSuccess(response.data));
      showNotification('success', 'Your Profile updated', 'Update profile');
    } catch (error) {
      dispatch(editProfileFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const editEmail = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(editEmailRequest());
      await axiosApi.put('/profile/change-email', userData);
      dispatch(editEmailSuccess());
      showNotification('success', 'Your Email updated', 'Update Email');
      dispatch(logoutUser());
      return true;
    } catch (error) {
      dispatch(editEmailFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const createAvatar = (avatar) => {
  const formData = new FormData();

  formData.append('avatar', avatar);

  return async (dispatch) => {
    try {
      dispatch(createAvatarRequest());
      const response = await axiosApi.post('/profile/update-avatar', formData);
      dispatch(userProfile());
      dispatch(createAvatarSuccess());
      showNotification('success', response.data.message, 'Update Avatar');
    } catch (error) {
      dispatch(createAvatarFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const paymentStripe = (payment) => {
  return async (dispatch) => {
    try {
      dispatch(paymentStripeRequest());
      const response = await axiosApi.post('/payment/purchase', payment);
      dispatch(paymentStripeSuccess(response.data.user));
      showNotification('success', response.data.message, 'Skips');
      return true;
    } catch (error) {
      dispatch(paymentStripeFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const paymentStripeMethod = (payment) => {
  return async (dispatch) => {
    try {
      dispatch(paymentStripeRequest());
      const response = await axiosApi.post('/payment/purchase-card', payment);
      dispatch(paymentStripeSuccess(response.data.user));
      showNotification('success', response.data.message, 'Skips');
      return true;
    } catch (error) {
      dispatch(paymentStripeFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const editPassword = (passwordUser) => {
  return async (dispatch) => {
    try {
      dispatch(editPasswordRequest());
      const response = await axiosApi.put('/profile/change-password', passwordUser);
      dispatch(editPasswordSuccess());
      showNotification('success', response.data.message, 'Update Password');
    } catch (error) {
      dispatch(editPasswordFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const verifyEmail = (token) => {
  return async (dispatch) => {
    try {
      dispatch(loginUserRequest());
      const response = await axiosApi.get(`/verify/email/${token}`);
      await dispatch(loginUserSuccess(response.data));
      history.push('/dashboard');
      showNotification('success', response.data.message, 'Verify Email');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const loginByToken = (token) => {
  return async (dispatch) => {
    try {
      dispatch(loginUserRequest());
      const response = await axiosApi.post('/login-by-token', { token });
      await dispatch(loginUserSuccess(response.data));
      history.push('/dashboard');
      return true;
    } catch (error) {
      dispatch(loginUserFailure(error?.response?.data?.errors?.message || true));
      return false;
    }
  };
};

export const loginAsUser = (wlID, userId, wlURL) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/admin/white-label/${wlID}/users/login-as-user/${userId}`);
      saveToLocalStorage('users', {});
      window.location.replace(`https://${wlURL}/login/user/${response.data?.token}`);
      return true;
    } catch (error) {
      return false;
    }
  };
};

export const checkInviteToken = (token) => {
  return async () => {
    try {
      const response = await axiosApi.put('/check-invite-token', { token });
      showNotification('success', response.data.message, 'Token', 10, 'top');
      return true;
    } catch (e) {
      history.push('/login');
      showNotification('info', e?.response?.data?.errors?.message || '', 'Error', 20, 'top');
      return false;
    }
  };
};

const updateNotificationsRequest = () => ({ type: UPDATE_RECEIVE_NOTIFICATIONS_REQUEST });
const updateNotificationsSuccess = () => ({ type: UPDATE_RECEIVE_NOTIFICATIONS_SUCCESS });
const updateNotificationsFailure = () => ({ type: UPDATE_RECEIVE_NOTIFICATIONS_FAILURE });

export const updateReceiveNotifications = (receiveNotifications) => {
  return async (dispatch) => {
    try {
      dispatch(updateNotificationsRequest());
      const response = await axiosApi.put('/profile/update-receive-notifications', { receiveNotifications });
      dispatch(updateNotificationsSuccess());
      dispatch(userProfileSuccess(response?.data.user));
      showNotification('success', response.data.message, 'Successfully');
    } catch (error) {
      showNotification('info', error?.response?.data?.errors?.message || '', 'Error', 20, 'top');
      dispatch(updateNotificationsFailure());
      return false;
    }
  };
};
