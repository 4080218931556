import React, { useContext, useMemo, useState } from 'react';
import { deleteFaqs, editFaqs, insertAfter, insertBefore } from '../../../../store/actions/adminActions';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../../../appContext';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import useResizeHeight from '../../../../utils/hooks/useResizeHeight';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const FaqsTab = ({ faq, index, wlID }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [question, setQuestion] = useState(faq.question || '');
  const [answer, setAnswer] = useState(faq.answer || '');
  const [position, setPosition] = useState('');
  const [type, setType] = useState(false);
  const [show, setShow] = useState(false);

  const width = useResizeWindows();
  const height = useResizeHeight();

  const { isUserSuperAdmin } = useContext(AppContext);

  const updateFaqs = (e) => {
    e.stopPropagation();
    dispatch(editFaqs(wlID, faq.id, { question, answer }));
    setEdit(false);
  };

  const deleteFaqsHandler = (e) => {
    e.stopPropagation();
    dispatch(deleteFaqs(wlID, faq.id));
  };

  const positionHandler = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (type) dispatch(insertBefore(wlID, position, id));
    else dispatch(insertAfter(wlID, position, id));
    setShow(false);
    setPosition('');
  };

  const showInsert = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    setType(type);
    setShow(!show);
    setPosition('');
  };

  const mediaQueryHandler = useMemo(() => {
    return width < 1600 && height < 750;
  }, [width, height]);

  return (
    <li className="faq__questions-item">
      <label className="admin-faqs-label" htmlFor={faq.id}>
        <input id={faq.id} type="checkbox" />
        <div className="admin-faqs-title">
          {edit ? (
            <input className="admin-faqs-title-input" onChange={(e) => setQuestion(e.target.value)} type="text" value={question} />
          ) : (
            <h4>
              <span className="m-r-5"> {index + 1}.</span>
              {faq.question}
            </h4>
          )}
          <div className="admin-faqs-actions">
            {!show && (
              <>
                {mediaQueryHandler ? (
                  <>
                    <Tooltip title="place above">
                      <UpOutlined className="manipulate-btn" onClick={(e) => showInsert(e, true)} />
                    </Tooltip>
                    <Tooltip title="place below">
                      <DownOutlined className="manipulate-btn" onClick={(e) => showInsert(e, false)} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <button onClick={(e) => showInsert(e, true)}>Place Above</button>
                    <button onClick={(e) => showInsert(e, false)}>Place Below</button>
                  </>
                )}
              </>
            )}
            {!show && (
              <>
                <button onClick={() => setEdit(!edit)}>{edit ? 'Cancel' : 'Edit'}</button>
                {isUserSuperAdmin && <button onClick={deleteFaqsHandler}>Delete</button>}
              </>
            )}

            {edit && <button onClick={updateFaqs}>Save</button>}
            {show && (
              <div className="faqs__position">
                <input className="number__field" type="number" value={position} onChange={(e) => setPosition(e.target.value)} />
                <button onClick={(e) => showInsert(e, false)}>Cancel</button>
                <button onClick={(e) => positionHandler(e, faq.id)}>Save</button>
              </div>
            )}
          </div>
        </div>
        <div className="admin-faqs-content">{!edit ? <p>{faq.answer}</p> : <textarea value={answer} onChange={(e) => setAnswer(e.target.value)} />}</div>
        <b>+</b>
      </label>
    </li>
  );
};

export default FaqsTab;
