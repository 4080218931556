import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Table } from 'antd';
import { AppContext } from '../../../appContext';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { PaginationPanelComponent } from '../../common/PaginationPanel/paginationPanel';
import { ITEMS_PER_PAGE } from '../../../utils/constants';
import _ from 'lodash';
import classnames from 'classnames';
import NoResult from '../../common/NoResult/NoResult';
import DatePickers from '../Statistics/DatePickers/DatePickers';
import useResizeHeight from '../../../utils/hooks/useResizeHeight';
import { getTransactionSuperAdmin } from '../../../store/actions/superAdminReportsActions';

const TransactionSuperAdmin = () => {
  const id = 'all'
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.reportsSuperAdmin.transaction);
  const pages = useSelector((state) => state.reportsSuperAdmin.transaction_pages);
  const loading = useSelector((state) => state.reportsSuperAdmin.loading);
  const [tmpStart, setTmpStart] = useState('');
  const [tmpEnd, setTmpEnd] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(1);
  const [user, setUser] = useState('');

  const { collapsed } = useContext(AppContext);
  const width = useResizeWindows();
  const height = useResizeHeight();

  const searchById = (id)=>{
    setUser(id)
    setPage(1)
  }

  const columns = [
    {
      title: 'Transaction ID',
      width: 70,
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      fixed: 'left',
    },
    {
      title: 'Date and Time',
      width: 200,
      dataIndex: 'date_and_time',
      key: 'date_and_time',
      fixed: 'left',
    },
    {
      title: 'User ID',
      width: 70,
      dataIndex: 'user_id',
      key: 'user_id',
      render: (_, record) => (
        <div className="history-table-btn" onClick={() => searchById(record.user_id)}>
          {record.user_id}
        </div>
      ),
    },
    {
      title: 'User',
      width: 200,
      dataIndex: 'user_first_name',
      key: 'user_first_name',
      render: (_, record) => (
        <div className="history-table-btn" onClick={() => searchById(record.user_id)}>
          {record?.user_first_name} {record?.user_last_name}
        </div>
      ),
    },
    {
      title: 'User Email',
      width: 200,
      dataIndex: 'user_email',
      key: 'user_email',
    },
    {
      title: 'User Type',
      width: 200,
      dataIndex: 'wl_domain',
      key: 'wl_domain',
    },
    {
      title: 'Phone Number',
      width: 200,
      dataIndex: 'user_phone',
      key: 'user_phone',
    },
    {
      title: 'White Label Company Name',
      width: 100,
      dataIndex: 'wl_name',
      key: 'wl_name',
    },

    {
      title: 'White Label Parent ID',
      width: 70,
      dataIndex: 'wl_id',
      key: 'wl_id',
    },

    {
      title: 'White Label Contract Rate',
      width: 100,
      dataIndex: 'wl_contract_rate',
      key: 'wl_contract_rate',
    },

    {
      title: 'Package Description',
      width: 100,
      dataIndex: 'package_id',
      key: 'package_id',
    },
    {
      title: 'Promo Code Used?',
      width: 100,
      dataIndex: 'promocode_used',
      key: 'promocode_used',
    },

    {
      title: 'Promo Code',
      width: 100,
      dataIndex: 'promocode',
      key: 'promocode',
    },

    {
      title: 'Normal Price per Skip',
      width: 100,
      dataIndex: 'normal_price_per_skip',
      key: 'normal_price_per_skip',
    },

    {
      title: 'Actual Price per Skip Charged',
      width: 100,
      dataIndex: 'actual_price_per_skip_charged',
      key: 'actual_price_per_skip_charged',
    },
    {
      title: 'Number of Skips in each Package',
      width: 100,
      dataIndex: 'number_of_skips_in_each_package',
      key: 'number_of_skips_in_each_package',
    },
    {
      title: 'Package Units Purchased',
      width: 100,
      dataIndex: 'number_of_packages_purchased',
      key: 'number_of_packages_purchased',
    },

    {
      title: 'Total Skips Purchased',
      width: 100,
      dataIndex: 'total_skips_puchased',
      key: 'total_skips_puchased',
    },

    {
      title: 'Sub Total Amount Paid',
      width: 100,
      dataIndex: 'sub_total_amount_paid',
      key: 'sub_total_amount_paid',
    },

    {
      title: 'Total Tax Amount Paid',
      width: 100,
      dataIndex: 'total_tax_amount_paid',
      key: 'total_tax_amount_paid',
    },
    {
      title: 'Total Amount Paid',
      width: 100,
      dataIndex: 'total_amount_paid',
      key: 'total_amount_paid',
    },
    {
      title: 'Card Last 4 Used',
      width: 100,
      dataIndex: 'card_last4_used',
      key: 'card_last4_used',
    },
  ];

  const startDateHandler = (date, dateString) => {
    setTmpStart(dateString);
  };

  const endDateHandler = (date, dateString) => {
    setTmpEnd(dateString);
  };

  const submitDateHandler = () => {
    setStartDate(tmpStart);
    setEndDate(tmpEnd);
  };

  useEffect(() => {
    if (id) dispatch(getTransactionSuperAdmin(id, startDate, endDate, false, page, user));
  }, [dispatch, startDate, endDate, id, page, user]);

  const tableWidth = useMemo(() => {
    return width <= 1279 ? '1200px' : collapsed ? `${width - 250}px` : `${width - 370}px`;
  }, [width, collapsed]);

  const tableHeight = useMemo(() => {
    return width < 1400 && height < 750 ? `${pages > 1 ? '230px' : '275px'}` : width < 1450 ? '255px' : '375px';
  }, [width, height, pages]);

  return (
    <Spin spinning={loading}>
      <div className={classnames('white-label-table  ', { 'with-pagination-reports': pages > 1 })}>
        <DatePickers
          user={user}
          setUser={setUser}
          startDateHandler={startDateHandler}
          endDateHandler={endDateHandler}
          request={getTransactionSuperAdmin}
          id={id}
          startDate={startDate}
          endDate={endDate}
          dispatch={dispatch}
          submitDate={submitDateHandler}
        />
        {!loading && (
          <>
            {_.isEmpty(payments) && !loading ? (
              <NoResult text="transaction history is empty" />
            ) : (
              <>
                <Table
                  loading={loading}
                  pagination={false}
                  columns={columns}
                  dataSource={payments}
                  style={{
                    width: tableWidth,
                    margin: '0 auto',
                    height: '400px',
                    borderTop: '1px solid var(--main-color)',
                  }}
                  scroll={{
                    x: '1000px',
                    y: tableHeight,
                  }}
                  tableLayout="fixed"
                  rowKey="index"
                />
              </>
            )}
          </>
        )}
        <div className="m-t-auto m-l-auto m-r-10">
          {!loading && (
            <PaginationPanelComponent pagintationPage={page} setPaginationPage={setPage} totalCount={pages * ITEMS_PER_PAGE} itemsPerPage={ITEMS_PER_PAGE} />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default TransactionSuperAdmin;
