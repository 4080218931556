import React, { useContext, useEffect } from 'react';
import NavigationSection from '../../Components/common/LandingHeader/NavigationSection';
import money from '../../assets/landing/money.png';
import search from '../../assets/landing/search.png';
import time from '../../assets/landing/time.png';
import phone from '../../assets/landing/phone.png';
import { Link, useHistory } from 'react-router-dom';
import { SignUpLink } from '../Landing/Landing';
import { ReactComponent as ReactAheadLogoSvg } from '../../assets/white-label/icons/icon-landing-ahead.svg';
import FooterSection from '../../Components/common/LandingFooter/footerSection';
import { AppContext } from '../../appContext';
import { toCapitalize } from '../../utils/functions';
import ReactGa from 'react-ga4';
import './styles.scss';

const optionsContent = [
  {
    id: 1,
    desciption: `We were tired of getting our data searches back and finding
  nothing but `,
    underlineDescription: 'innacurate phone numbers.',
    class: 'phone',
    iconUrl: phone,
  },
  {
    id: 2,
    desciption: `Other Skip Tracing softwares were confusing, difficult to
  navigate around, and just `,
    underlineDescription: 'didn’t feel right. ',
    class: 'search',
    iconUrl: search,
  },
  {
    id: 3,
    desciption: ` in our business, so we found a way to
  get extremely accurate results, instantly.`,
    underlineDescription: 'Time is of the essence',
    class: 'time',
    iconUrl: time,
  },
  {
    id: 4,
    desciption: `With the amount of data we’re pulling in our company on a monthly basis, we wanted better pricing but couldn’t find it... `,
    underlineDescription: 'so we created it.',
    class: 'money',
    iconUrl: money,
  },
];

const AboutUs = () => {
  const { brand } = useContext(AppContext);
  const pathname = useHistory().location.pathname;

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="about-us">
      <NavigationSection
        headHeader="OUR STORY"
        headText="Helpful tools created from over a century of combined "
        isSubHeader={true}
        subHeader="experience from the SkipCloud Team!"
      />
      <section className="about-us__section">
        <div className="people-cards">
          <p>At <span>SkipCloud</span>, we understand the importance of having accurate and updated data in the fast-paced world of real estate.</p>
          <p>
            That's why we've created the nation's premier data provider, with a team of experts led by founders who successfully scaled their company to become
            one of the largest residential real estate wholesaling operations in the nation using data as a key factor for success. With <span>SkipCloud</span>, you too can
            benefit from the same level of data that has helped our founders achieve incredible success in real estate.
          </p>
          <p>Don't let poor data hold you back - join the ranks of satisfied <span>SkipCloud</span> customers and see your business soar to new heights! 🚀</p>
        </div>
      </section>
      <div className="block"></div>
      <section className="why__skipcloud__container">
        <h1>WHY {brand.toUpperCase()}?</h1>
        <p>
          Building an <b>8-Figure Wholesaling Operation</b> & Dealing With The Inefficiencies Of 99% OF Skip Tracing Softwares Led To Building This...
        </p>
      </section>
      <section className="options">
        {optionsContent.map((item) => (
          <div className="options__item">
            <div className="options__item__img">
              <img src={item.iconUrl} alt="options" className={item.class} />
            </div>
            {item.id === 3 ? (
              <>
                <span className="underline__text">{item.underlineDescription}</span>
                <span>{item.desciption}</span>
              </>
            ) : (
              <>
                <span>{item.desciption}</span>
                <span className="underline__text">{item.underlineDescription}</span>
              </>
            )}
            <p></p>
          </div>
        ))}
      </section>
      <div className="about__account-container">
        <div className="about__account-svg-container">
          <ReactAheadLogoSvg />
        </div>
        <h4 className="contact__account-header">
          <span>Haven't used</span>
          <b>{toCapitalize(brand)}</b>
          <span>yet?</span>
        </h4>
        <SignUpLink text={`Show me what i'm missing out on!`} className="about__login-button navigation-section__login-link-button" />
        <span>
          We'll
          <Link to={'/sign-up'} className="about__login-link navigation-section__login-link">
            make you an account
          </Link>
          for FREE so you can check it out first!
        </span>
      </div>
      <FooterSection />
    </div>
  );
};

export default AboutUs;
