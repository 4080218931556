import React, { useContext, useEffect, useState } from 'react';
import TextField from '../../Components/common/TextField/TextField';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FacebookIcon from '../../assets/auth/fb2.svg';
import GoogleIcon from '../../assets/auth/g+.svg';
import PasswordIcon from '../../assets/auth/password.svg';
import UserNameIcon from '../../assets/auth/userName.svg';
import HighLevelIcon from '../../assets/auth/highlevel.png';
import { useDispatch, useSelector } from 'react-redux';
import { loginFacebook, loginGoogle, loginUser, verifyEmail } from '../../store/actions/userActions';
import { Spin } from 'antd';
import CheckBox from '../../Components/common/Checkbox/Checkbox';
import { Link, useHistory } from 'react-router-dom';
import { showNotification } from '../../App';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { apiURL } from '../../utils/config';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { toCapitalize } from '../../utils/functions';
import { AppContext } from '../../appContext';
import moment from 'moment/moment';
import AuthenticationLayout from '../../Components/UI/AuthenticationLayout/AuthenticationLayout';
import SocialBlock from '../../Components/UI/SocialBlock/SocialBlock';
import { removeFromLocalStorage } from '../../utils/localStorage';
import ReactGa from 'react-ga4';
import './styles.scss';

const Login = () => {
  const token = useHistory().location.search;
  const pathname = useHistory().location.pathname;
  const { brand, isSkipCloud } = useContext(AppContext);
  const dispatch = useDispatch();
  const error = useSelector((state) => state?.user.error);
  const loading = useSelector((state) => state?.user.loading);
  const fbKey = useSelector((state) => state.config.config?.fb_auth_key);
  const subLogo = useSelector((state) => state.config.config?.images?.logo_2);
  const whiteLabelName = useSelector((state) => state.config?.config?.name);
  const authBackground = useSelector((state) => state.config.config?.images?.auth_background);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const width = useResizeWindows();

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token.length > 10 && token.startsWith('?expires')) dispatch(verifyEmail(token));
  }, [token, dispatch]);

  let textFieldButtonSize = '430px';
  if (width < 460 && width > 370) {
    textFieldButtonSize = '350px';
  } else if (width <= 370) {
    textFieldButtonSize = '300px';
  }

  const loginStyles = {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    background: `url(${apiURL + authBackground}) 0 -2px no-repeat`,
    backgroundSize: isSkipCloud ? 'contain' : 'cover',
    overflow: 'hidden',
    height: 'calc(100vh)',
    '@media screen and (maxWidth: 1200px)': {
      display: 'none',
    },
  };

  const submitHandler = async () => {
    removeFromLocalStorage('verify-email');
    if (email.trim().length > 3 || password.trim() > 5) {
      await dispatch(loginUser({ email, password }));
    }
  };

  const loginFacebookHandler = (response) => {
    const arrayName = response.name.split(' ', 2);
    dispatch(
      loginFacebook({
        avatar: response.picture.data.url,
        email: response.email,
        firstName: arrayName[0],
        lastName: arrayName[1],
      })
    );
  };

  const loginGoogleHandlerUpd = (response) => {
    dispatch(
      loginGoogle({
        avatar: response?.picture,
        email: response?.email,
        firstName: response?.given_name,
        lastName: response?.family_name,
      })
    );
  };

  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${respose.access_token}`,
          },
        });

        if (Boolean(res)) {
          loginGoogleHandlerUpd(res.data);
        }
      } catch (err) {
        showNotification('error', 'Please Refresh page', 'Google Error');
      }
    },
  });

  const enterSubmitHandler = async (e) => {
    if (e.code === 'Enter') {
      await submitHandler();
    }
  };

  return (
    <div className="login" style={loginStyles}>
      <div className="login__body">
        {width >= 1200 && <AuthenticationLayout />}
        <div className="login__body-form m-t-auto m-b-auto">
          <LazyLoadImage className="login__logo " draggable={false} src={`${apiURL + subLogo}`} alt="logo" />
          <h3 className="auth__title">
            Sign in to <span>{whiteLabelName}</span>
          </h3>
          <div className="login__border" />
          <Spin spinning={loading} size={'large'}>
            <TextField
              icon={UserNameIcon}
              width={textFieldButtonSize}
              value={email}
              setState={setEmail}
              placeholder="Email"
              error={error}
              parentClassName="m-t-20"
              onKeyPress={enterSubmitHandler}
            />
            <TextField
              icon={PasswordIcon}
              width={textFieldButtonSize}
              value={password}
              setState={setPassword}
              placeholder="Password"
              error={error}
              parentClassName="m-b-20"
              type="password"
              onKeyPress={enterSubmitHandler}
            />
            <div className="flex flex-align-item-center flex-justify-content-between m-b-30">
              <CheckBox text="Remember me" checked={rememberMe} setChecked={setRememberMe} className="login-check" />
              <Link to="/forgot-password" className="login__button-forgot">
                Forgot password ?
              </Link>
            </div>
          </Spin>
          <button className="auth__button login-button" onClick={submitHandler}>
            Sign In
          </button>
          <div className="login__border" />
          <h3>Or</h3>
          <div className="login__social">
            <FacebookLogin
              fields="name,email,picture"
              appId={fbKey}
              callback={loginFacebookHandler}
              render={(props) => (
                <button className="google-button" onClick={props.onClick}>
                  <p>
                    Sign in with <span>Facebook</span>
                  </p>
                  <LazyLoadImage className="icon" src={FacebookIcon} alt="facebook_logo" />
                </button>
              )}
            />
            <button onClick={login} className="google-button">
              <p style={{ letterSpacing: '1px' }}>
                Sign in with <span>Google</span>
              </p>
              <LazyLoadImage className="icon" src={GoogleIcon} alt="google_logo" />
            </button>
          </div>

          <div className="login__border m-b-25" />
          <p className="login__register">
            Don't have an account yet?
            <Link className="login__link" to="/sign-up">
              Sign up now!
            </Link>
          </p>
          <div className="login__border" />

          <Link className="sign__up-highlevel google-button" to="/sign-up-highlevel">
            <p> Sign up <span>HighLevel</span></p>

            <LazyLoadImage className="icon" src={HighLevelIcon} alt="highlevel_logo" />
          </Link>

          <div className="login__corp" />
          {width <= 1200 && <SocialBlock />}
        </div>
      </div>
      <div className="auth__footer">
        <div className="auth__footer__outer">
          <p>
            All rights reserved. {toCapitalize(brand)}, LLC. {moment(new Date()).format('YYYY')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
