import React, { useState } from 'react';
import TextArea from '../../common/TextArea/TextArea';
import { useDispatch } from 'react-redux';
import { createCallendly } from '../../../store/actions/whiteLabelActions';

const CreateCallendly = ({ id, setShow }) => {
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const inputRef = React.useRef();

  const activateInput = () => {
    inputRef.current?.click();
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };

  const createHandler = async () => {
    const response = await dispatch(createCallendly(id, { avatar, name, url, description }));
    if (response) {
      setAvatar(null);
      setName('');
      setUrl('');
      setDescription('');
      setShow(false);
    }
  };

  return (
    <div className="create-callendly ">
      <h3>Create Callendly</h3>

      <input type="file" onChange={onFileChange} ref={inputRef} style={{ display: 'none' }} />
      {!avatar ? (
        <p>
          <button className="avatar__button" onClick={activateInput}>
            Upload Avatar
          </button>
        </p>
      ) : (
        <p onClick={() => setAvatar(null)}>
          <img className="callendly__modal-admin-avatar" src={URL.createObjectURL(avatar)} alt="avatar" />
        </p>
      )}
      <TextArea height={'50px'} value={name} setState={setName} label="Name" />
      <TextArea height={'100px'} value={description} setState={setDescription} label="Description" />
      <TextArea height={'80px'} label="Url" value={url} setState={setUrl} />
      <div className="m-l-auto m-t-auto flex flex-align-items-center gap-10">
        <button onClick={() => setShow(false)}>Cancel</button>
        <button onClick={createHandler}>Save</button>
      </div>
    </div>
  );
};

export default CreateCallendly;
