import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePrimaryCard, deleteCard, getCards } from '../../../../store/actions/paymentActions';
import MasterCard from '../../../../assets/payment/master-card.svg';
import Visa from '../../../../assets/payment/visa.svg';
import Discover from '../../../../assets/payment/discover.svg';
import Amex from '../../../../assets/payment/amex.svg';
import { Spin } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './styles.scss';
import ReactGa from 'react-ga4';

const OtherCards = () => {
  const pathname = useHistory().location.pathname;
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.payment.cards);
  const loading = useSelector((state) => state.payment.loading);
  const [card, setCard] = useState('');

  useEffect(() => {
    dispatch(getCards(true));
  }, [dispatch]);

  useEffect(() => {
    ReactGa.send({ hitType: "pageview", page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="other-cards">
      <div className="flex flex-align-center flex-justify-content-between m-t-20 m-l-20 m-r-20 m-b-20">
        <h1>Manage your credits cards</h1>
        <Link to="/profile/edit-payment">Add New Card</Link>
      </div>
      <Spin spinning={!!loading} size="large">
        <ul className="other-cards__list">
          <li className="other-cards__list-item">
            <h3>Default Card</h3>
            <h3>Credit Card Number</h3>
            <h3>Cardholder Name</h3>
            <h3>Exp.Date</h3>
            <h3>Action</h3>
          </li>
          {cards.map(({ last4, card_holder_name, exp_month, exp_year, brand, id, is_primary }) => (
            <li className="other-cards__list-item" key={id}>
              <input
                type="radio"
                name="options"
                value={last4}
                checked={is_primary || card === id}
                onChange={() => {
                  setCard(id);
                  dispatch(changePrimaryCard(id));
                }}
              />
              <p>
                <img
                  src={
                    brand.toLowerCase() === 'visa' ? Visa : brand.toLowerCase() === 'amex' ? Amex : brand.toLowerCase() === 'mastercard' ? MasterCard : Discover
                  }
                  alt="brand"
                />
                {last4}
              </p>
              <p>{card_holder_name}</p>
              <p>
                {exp_month.toString().length === 1 ? '0' + exp_month : exp_month}/{exp_year}
              </p>
              <button onClick={() => dispatch(deleteCard(id))}>Delete</button>
            </li>
          ))}
        </ul>
      </Spin>
    </div>
  );
};

export default OtherCards;
