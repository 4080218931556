import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { AppContext } from '../../appContext';
import { loginFacebook, loginGoogle, verifyEmail } from '../../store/actions/userActions';
import { apiURL } from '../../utils/config';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { showNotification } from '../../App';
import AuthenticationLayout from '../../Components/UI/AuthenticationLayout/AuthenticationLayout';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FacebookIcon from '../../assets/auth/fb2.svg';
import GoogleIcon from '../../assets/auth/g+.svg';
import SocialBlock from '../../Components/UI/SocialBlock/SocialBlock';
import { toCapitalize } from '../../utils/functions';
import moment from 'moment';
import history from '../../utils/history';
import { Spin } from 'antd';
import { loadFromLocalStorage, removeFromLocalStorage } from '../../utils/localStorage';
import './style.scss';
import ReactGa from 'react-ga4';

const VerifyEmail = () => {
  const token = useHistory().location.search;
  const dispatch = useDispatch();
  const fbKey = useSelector((state) => state.config.config?.fb_auth_key);
  const loading = useSelector((state) => state?.user.loading);
  const width = useResizeWindows();
  const [email, setEmail] = useState('');
  const pathname = useHistory().location.pathname;

  // whitelabel images from config
  const { brand } = useContext(AppContext);
  const subLogo = useSelector((state) => state.config.config?.images?.logo_2);
  const whiteLabelName = useSelector((state) => state.config?.config?.name);
  const authBackground = useSelector((state) => state.config.config?.images?.auth_background);

  useEffect(() => {
    if (token.length > 10) dispatch(verifyEmail(token));
  }, [token, dispatch]);

  useEffect(() => {
    const localEmail = loadFromLocalStorage('verify-email');
    if (localEmail) setEmail(localEmail);
    else history.push('/login');
  }, []);

  useEffect(() => {
    if (email) window.fpr('referral', { email });
  }, [email]);

  const loginFacebookHandler = (response) => {
    const arrayName = response.name.split(' ', 2);
    dispatch(
      loginFacebook({
        avatar: response.picture.data.url,
        email: response.email,
        firstName: arrayName[0],
        lastName: arrayName[1],
      })
    );
  };

  const loginHandler = () => {
    removeFromLocalStorage('verify-email');
    history.push('/login');
  };

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginStyles = {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    background: `url(${apiURL + authBackground}) 0 -2px no-repeat`,
    backgroundSize: 'contain',
    overflow: 'hidden',
    height: 'calc(100vh)',
    '@media screen and (maxWidth: 1200px)': {
      display: 'none',
    },
  };

  const loginGoogleHandlerUpd = (response) => {
    dispatch(
      loginGoogle({
        avatar: response?.picture,
        email: response?.email,
        firstName: response?.given_name,
        lastName: response?.family_name,
      })
    );
    removeFromLocalStorage('verify-email');
  };

  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${respose.access_token}`,
          },
        });

        if (Boolean(res)) {
          loginGoogleHandlerUpd(res.data);
        }
        removeFromLocalStorage('verify-email');
      } catch (err) {
        showNotification('error', 'Please Refresh page', 'Google Error');
      }
    },
  });

  return (
    <div className="login" style={loginStyles}>
      <div className="login__body">
        {width >= 1200 && <AuthenticationLayout />}
        <div className="login__body-form m-t-auto m-b-auto">
          <LazyLoadImage className="login__logo " draggable={false} src={`${apiURL + subLogo}`} alt="logo" />
          <h3 className="verify-title">
            Verify <span>{whiteLabelName}</span> Account
          </h3>
          <div className="login__border" />

          <Spin spinning={loading} size={'large'}>
            <div className="verify-email">
              <h3>Thank you for Signing Up for {whiteLabelName}! </h3>
              <p className="verify-email__email">
                We sent you a verification email at <span>{email}</span>.
              </p>
              <p> Please check your inbox and click on the link that we sent to verify your account and Log In !</p>
            </div>
          </Spin>

          <button className="auth__button login-button" onClick={loginHandler}>
            GO TO LOGIN
          </button>

          <div className="login__border" />
          <h3>Or</h3>
          <div className="login__social">
            <FacebookLogin
              fields="name,email,picture"
              appId={fbKey}
              callback={loginFacebookHandler}
              render={(props) => (
                <button className="google-button" onClick={props.onClick}>
                  <p>
                    Sign in with <span>Facebook</span>
                  </p>
                  <LazyLoadImage className="icon" src={FacebookIcon} alt="facebook_logo" />
                </button>
              )}
            />
            <button onClick={login} className="google-button">
              <p style={{ letterSpacing: '1px' }}>
                Sign in with <span>Google</span>
              </p>
              <LazyLoadImage className="icon" src={GoogleIcon} alt="google_logo" />
            </button>
          </div>
          <div className="login__border m-b-25" />
          <p className="login__register">
            Don't have an account yet?
            <Link className="login__link" to="/sign-up">
              Sign up now!
            </Link>
          </p>
          <div className="login__border" />
          <div className="login__corp" />
          {width <= 1200 && <SocialBlock />}
        </div>
      </div>
      <div className="auth__footer">
        <div className="auth__footer__outer">
          <p>
            All rights reserved. {toCapitalize(brand)}, LLC. {moment(new Date()).format('YYYY')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
