import {
  EDIT_TEMPLATE_SEND_GRID_FAILURE,
  EDIT_TEMPLATE_SEND_GRID_REQUEST,
  EDIT_TEMPLATE_SEND_GRID_SUCCESS,
  GET_TEMPLATE_SEND_GRID_FAILURE,
  GET_TEMPLATE_SEND_GRID_REQUEST,
  GET_TEMPLATE_SEND_GRID_SUCCESS,
} from '../actionsTypes';
import axiosApi from '../../utils/axios';

export const getSendGridRequest = () => ({ type: GET_TEMPLATE_SEND_GRID_REQUEST });
export const getSendGridSuccess = (templates) => ({ type: GET_TEMPLATE_SEND_GRID_SUCCESS, templates });
export const getSendGridFailure = (error) => ({ type: GET_TEMPLATE_SEND_GRID_FAILURE, error });

export const editSendGridRequest = () => ({ type: EDIT_TEMPLATE_SEND_GRID_REQUEST });
export const editSendGridSuccess = (templates) => ({ type: EDIT_TEMPLATE_SEND_GRID_SUCCESS, templates });
export const editSendGridFailure = (error) => ({ type: EDIT_TEMPLATE_SEND_GRID_FAILURE, error });

export const getSendGrid = (wlID) => {
  return async (dispatch) => {
    try {
      dispatch(getSendGridRequest());
      const { data } = await axiosApi.get(`/admin/white-label/${wlID}/templates`);
      dispatch(getSendGridSuccess(data));
    } catch (error) {
      dispatch(getSendGridFailure(error?.response?.data?.errors?.message || true));
    }
  };
};


export const editSendGrid = (wlID, templateData) => {
  return async (dispatch) => {
    try {
      dispatch(editSendGridRequest());
      const response = await axiosApi.put(`/admin/white-label/${wlID}/template/edit`, templateData);
      dispatch(editSendGridSuccess(response.data.data));
      return true;
    } catch (error) {

      dispatch(editSendGridFailure(error?.response?.data?.errors?.message || true));
      return false;

    }
  };
};
