import React from 'react';

const FigureHeader = ({ className }) => {
  return (
    <div className={`svg ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg"  xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 862 72.79">
        <defs>
          <linearGradient id="SKIPCLOUD__HEADER" y1="36.4" x2="862" y2="36.4" gradientUnits="userSpaceOnUse">
            <stop offset="0"  style={{ stopColor: 'var(--gradient-1)' }} stopColor="var(--gradient-1)" />
            <stop offset="0.7"  style={{ stopColor: 'var(--gradient-2)' }} stopColor="var(--gradient-2)" />
            <stop offset="1"  style={{ stopColor: 'var(--gradient-3)' }} stopColor="var(--gradient-3)" />
          </linearGradient>
        </defs>
        <g id="Слой_2" data-name="Слой 2">
          <g id="Layer_1" data-name="Layer 1">
            <path fill="url(#SKIPCLOUD__HEADER)" className="cls-1" d="M0,0S745.12,72.79,785.54,72.79,862,39.31,862,0Z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default FigureHeader;
