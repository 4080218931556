import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSummaryStatistics } from '../../../../store/actions/whiteLabelActions';
import { Spin, Table } from 'antd';
import Navigation from '../Navigation/Navigation';
import DatePickers from '../DatePickers/DatePickers';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import { AppContext } from '../../../../appContext';
import _ from 'lodash';
import NoResult from '../../../common/NoResult/NoResult';

const columns = [
  {
    title: 'Account Launch Date and Time',
    width: 200,
    dataIndex: 'launched_at_datetime',
    key: 'launched_at_datetime',
    fixed: 'left',
  },
  {
    title: 'White Label Company Name',
    width: 200,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
  {
    title: 'White Label Parent ID',
    width: 150,
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'White Label Contract Rate',
    width: 150,
    dataIndex: 'contract_rate',
    key: 'contract_rate',
  },
  {
    title: 'Total Number of Users',
    width: 150,
    dataIndex: 'total_number_of_users',
    key: 'total_number_of_users',
  },
  {
    title: 'Total Number of Active Users',
    width: 150,
    dataIndex: 'total_number_of_active_users',
    key: 'total_number_of_active_users',
  },
  {
    title: 'New Users Signed Up',
    width: 150,
    dataIndex: 'new_users_signed_up',
    key: 'new_users_signed_up',
  },
  {
    title: 'Total Skips Purchased',
    width: 150,
    dataIndex: 'total_skips_purchased',
    key: 'total_skips_purchased',
  },
  {
    title: 'Average Cost/Skip',
    width: 150,
    dataIndex: 'avg_cost_skip',
    key: 'avg_cost_skip',
  },
  {
    title: 'Total Revenue',
    width: 150,
    dataIndex: 'total_revenue',
    key: 'total_revenue',
  },
  {
    title: 'Total Revenue For White Label Company',
    width: 150,
    dataIndex: 'total_revenue_for_wl_company',
    key: 'total_revenue_for_wl_company',
  },
  {
    title: 'Company Status',
    width: 150,
    dataIndex: 'status',
    key: 'status',
  },
];

const Summary = (props) => {
  const id = props.id || props?.match?.params?.id;
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const summary = useSelector((state) => state.whiteLabel.summary);
  const loading = useSelector((state) => state.whiteLabel.loading);
  const [tmpStart, setTmpStart] = useState('');
  const [tmpEnd, setTmpEnd] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { collapsed } = useContext(AppContext);

  const startDateHandler = (date, dateString) => {
    setTmpStart(dateString);
  };

  const endDateHandler = (date, dateString) => {
    setTmpEnd(dateString);
  };

  const submitDateHandler = () => {
    setStartDate(tmpStart);
    setEndDate(tmpEnd);
  };

  useEffect(() => {
    dispatch(getSummaryStatistics(id, startDate, endDate));
  }, [dispatch, startDate, endDate, id]);

  const tableWidth = useMemo(() => {
    return width <= 1279 ? '1200px' : collapsed ? `${width - 250}px` : `${width - 370}px`;
  }, [width, collapsed]);

  const tableHeight = useMemo(() => {
    return width <= 1280 ? '275px' : width <= 1450 ? '305px' : '375px';
  }, [width]);

  return (
    <Spin spinning={loading}>
      <div className="white-label-table">
        <Navigation id={id} />
        <DatePickers
          startDateHandler={startDateHandler}
          endDateHandler={endDateHandler}
          request={getSummaryStatistics}
          id={id}
          startDate={startDate}
          endDate={endDate}
          dispatch={dispatch}
          submitDate={submitDateHandler}
        />
        {!loading && (
          <>
            {_.isEmpty(summary) && !loading ? (
              <NoResult text="White Label Summary is empty" />
            ) : (
              <>
                <Table
                  loading={loading}
                  pagination={false}
                  style={{
                    width: tableWidth,
                    margin: '0 auto',
                    height: tableHeight,
                    borderTop: '1px solid var(--main-color)',
                  }}
                  columns={columns}
                  dataSource={summary}
                  scroll={{
                    x: '1000px',
                    y: tableHeight,
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default Summary;
