import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCallendly } from '../../../store/actions/whiteLabelActions';
import { Modal, Spin } from 'antd';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import Row from './Row';
import CreateCallendly from './CreateCallendly';
import './styles.scss';

const CallendlyModal = ({ id, dispatch, show, setShow }) => {
  const callendly = useSelector((state) => state.whiteLabel.callendly);
  const loading = useSelector((state) => state.whiteLabel.callendlyLoading);
  const width = useResizeWindows();
  const [state, setState] = useState(false);

  useEffect(() => {
    if (id) dispatch(getCallendly(id));
  }, [id, dispatch]);

  return (
    <Modal
      visible={show}
      onCancel={() => setShow(false)}
      footer={null}
      className="callendly__modal-admin"
      bodyStyle={{
        width: width < 1280 ? '1180px' : '100%',
        padding: '0',
        borderRadius: '50px',
        background: 'var(--content-bg)',
        height: width > 1500 ? '600px' : '500px',
      }}
    >
      <Spin spinning={loading}>
        {!state ? (
          <>
            <div className="callendly__modal-admin-header">
              <h3>Callendly</h3>
              <button onClick={() => setState(true)}>Create Callendly</button>
            </div>
            <div className="callendly__modal-admin-body">
              <div className="callendly__user">
                <p>Avatar</p>
                <p>Name</p>
                <p>Description</p>
                <p>Callendly Link</p>
                <p>Actions</p>
              </div>
              {callendly?.length ? callendly.map((user) => <Row width={width} dispatch={dispatch} id={id} key={user.id} user={user} />) : null}
            </div>
          </>
        ) : (
          <CreateCallendly id={id} setShow={setState} />
        )}
      </Spin>
    </Modal>
  );
};

export default CallendlyModal;
