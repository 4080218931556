import {
  SKIPTRACE_HISTORY_ALL_FAILURE,
  SKIPTRACE_HISTORY_ALL_REQUEST,
  SKIPTRACE_HISTORY_ALL_SUCCESS,
  TRANSACTION_HISTORY_ALL_FAILURE,
  TRANSACTION_HISTORY_ALL_REQUEST,
  TRANSACTION_HISTORY_ALL_SUCCESS,
  USER_ACTIVITY_HISTORY_ALL_FAILURE,
  USER_ACTIVITY_HISTORY_ALL_REQUEST,
  USER_ACTIVITY_HISTORY_ALL_SUCCESS,
} from '../actionsTypes';

const initialState = {
  loading: false,
  error: null,
  userActivity: [],
  userActivity_pages: 1,
  transaction: [],
  transaction_pages: 1,
  skiptrace: [],
  skiptrace_pages: 1,
};

const superAdminReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SKIPTRACE_HISTORY_ALL_REQUEST:
      return { ...state, loading: true };
    case SKIPTRACE_HISTORY_ALL_SUCCESS:
      return { ...state, loading: false, skiptrace: action.skiptrace.data, skiptrace_pages: action.skiptrace.pages };
    case SKIPTRACE_HISTORY_ALL_FAILURE:
      return { ...state, loading: false, error: action.error };

    case USER_ACTIVITY_HISTORY_ALL_REQUEST:
      return { ...state, loading: true };
    case USER_ACTIVITY_HISTORY_ALL_SUCCESS:
      return { ...state, loading: false, userActivity: action.activity.data, userActivity_pages: action.activity.pages };
    case USER_ACTIVITY_HISTORY_ALL_FAILURE:
      return { ...state, loading: false, error: action.error };

    case TRANSACTION_HISTORY_ALL_REQUEST:
      return { ...state, loading: true };
    case TRANSACTION_HISTORY_ALL_SUCCESS:
      return { ...state, loading: false, transaction: action.transaction.data, transaction_pages: action.transaction.pages };
    case TRANSACTION_HISTORY_ALL_FAILURE:
      return { ...state, loading: false, error: action.error };

    default:
      return { ...state };
  }
};

export default superAdminReportsReducer;
