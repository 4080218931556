import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Modal } from 'antd';
import { apiURL } from '../../utils/config';
import NoAvatar from '../../assets/UI/no_avatar.png';
import MessageIcon from '../../assets/account/message-icon.svg';
import FileInput from '../../Components/common/FileInput/FileInput';
import { createAvatar, userProfile } from '../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useHistory } from 'react-router-dom';
import BillingInformation from './Routes/BillingInformation/BillingInformation';
import PersonDetails from './Routes/PersonDetails/PersonDetails';
import EditPersonalDetails from './Routes/EditPersonalDetails/EditPersonalDetails';
import EditBillingDetails from './Routes/EditBillingDetails/EditBillingDetails';
import CreatePaymentMethods from './Routes/CreatePaymentMethods/CreatePaymentMethods';
import moment from 'moment';
import { sliceString, toCapitalize } from '../../utils/functions';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { getCards } from '../../store/actions/paymentActions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import OtherCards from './Routes/OtherCards/OtherCards';
import CameraIcon from '../../Components/SVG-Icon/CameraIcon';
import ArrowRight from '../../Components/SVG-Icon/ArrowRight';
import { AppContext } from '../../appContext';
import ReactGa from 'react-ga4';
import './styles.scss';
import SearchSettings from './Routes/SearchSettings/SearchSettings';
import NotificationSettings from './Routes/NotificationSettings/NotificationSettings';

const AccountManagement = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.user?.profile);
  const cards = useSelector((state) => state?.payment?.cards);
  const config = useSelector((state) => state.config.config);
  const pathname = useHistory().location.pathname;
  const width = useResizeWindows();
  const [show, setShow] = useState(false);
  const modalWidth = width <= 1300 ? '1180px' : width <= 1500 ? '95%' : '80%';
  const { brand } = useContext(AppContext);

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(userProfile());
    dispatch(getCards());
  }, [dispatch]);

  return (
    <div className="account">
      <div className="account__body">
        <div className="account__body-item">
          <div className="avatar__block">
            <h3>Your Profile</h3>
            <Avatar
              size={width > 1400 ? 150 : 130}
              src={profile?.social ? profile?.avatar : profile?.avatar ? apiURL + '/uploads/avatars/' + profile?.avatar : NoAvatar}
              className="avatar"
            />
            <FileInput
              multiple={false}
              className="avatar-edit"
              text={<CameraIcon />}
              onChange={(avatar) => dispatch(createAvatar(avatar))}
              accept={'image/png, image/jpeg'}
            />
            <h2>
              {sliceString(width, profile?.first_name, 20, 10, 10)} {sliceString(width, profile?.last_name, 20, 10, 10)}
            </h2>
            <p>Member since {moment(profile?.created_at).format('YYYY')}</p>
          </div>
          <div className="help__block">
            <LazyLoadImage src={MessageIcon} alt="" />
            <h2>Need help?</h2>
            <p>Have questions or concerns regarding your {toCapitalize(brand)} account? We have our team of live support ready to help! </p>

            <button className="help__block-button" onClick={() => setShow(true)} disabled={!config?.jivochat_url}>
              <span>Chat With Us Now </span>
              <ArrowRight />
            </button>
          </div>
        </div>
        <div className="account__body-item">
          <div className="account__nav">
            <Link to="/profile" className={pathname === '/profile' || pathname === '/profile/account-edit' ? 'active' : ''}>
              Personal Details
            </Link>
            <Link
              to="/profile/billing"
              className={
                pathname === '/profile/billing' ||
                pathname.includes('/profile/billing-edit') ||
                pathname === '/profile/edit-payment' ||
                pathname === '/profile/other-cards'
                  ? 'active'
                  : ''
              }
            >
              Billing Information
            </Link>

            <Link to="/profile/search-settings" className={pathname === '/profile/search-settings' ? 'active' : ''}>
              Search Settings
            </Link>
            <Link to="/profile/notification-settings" className={pathname === '/profile/notification-settings' ? 'active' : ''}>
              Notification Settings
            </Link>
          </div>
          <Route exact path="/profile/search-settings" component={SearchSettings} />
          <Route exact path="/profile/notification-settings" component={NotificationSettings} />
          <div className="account-content">
            <Route exact path="/profile" component={PersonDetails} />
            <Route exact path="/profile/billing">
              {cards.length ? <BillingInformation /> : <CreatePaymentMethods />}
            </Route>
            <Route exact path="/profile/account-edit" component={EditPersonalDetails} />
            <Route exact path="/profile/other-cards" component={OtherCards} />
            <Route exact path="/profile/billing-edit/:id" component={EditBillingDetails} />
            <Route exact path="/profile/edit-payment" component={CreatePaymentMethods} />
          </div>
        </div>
      </div>
      <Modal
        visible={show}
        onCancel={() => setShow(false)}
        bodyStyle={{ width: width <= 1300 ? '1180px' : '100%', padding: '0', borderRadius: '50px', height: '80vh' }}
        footer={null}
        width={modalWidth}
      >
        <iframe src={`https://jivo.chat/${config?.jivochat_url}`} title="jivoChat" width={'100%'} height={'100%'} frameBorder="0" />
      </Modal>
    </div>
  );
};

export default AccountManagement;
