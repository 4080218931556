import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSkiptraceStatistics } from '../../../../store/actions/whiteLabelActions';
import Navigation from '../Navigation/Navigation';
import DatePickers from '../DatePickers/DatePickers';
import { Spin, Table } from 'antd';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import { AppContext } from '../../../../appContext';
import { PaginationPanelComponent } from '../../../common/PaginationPanel/paginationPanel';
import { ITEMS_PER_PAGE } from '../../../../utils/constants';
import { downloadOriginal, downloadUpdated } from '../../../../store/actions/fileActions';
import DatePickerTable from '../../DatePickerTable/DatePickerTable';
import _ from 'lodash';
import NoResult from '../../../common/NoResult/NoResult';
import classnames from 'classnames';
import useResizeHeight from '../../../../utils/hooks/useResizeHeight';

const Skiptrace = (props) => {
  const id = props.id || props?.match?.params?.id;
  const dispatch = useDispatch();
  const skiptrace = useSelector((state) => state.whiteLabel.skiptrace);
  const pages = useSelector((state) => state.whiteLabel.skiptrace_pages);
  const loading = useSelector((state) => state.whiteLabel.loading);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tmpStart, setTmpStart] = useState('');
  const [tmpEnd, setTmpEnd] = useState('');
  const [page, setPage] = useState(1);
  const [user, setUser] = useState('');
  const { collapsed, isUserSuperAdmin } = useContext(AppContext);
  const width = useResizeWindows();
  const height = useResizeHeight();

  const searchById = (id) => {
    setUser(id);
    setPage(1);
  };

  const columns = useMemo(() => {
    const column = [
      {
        title: 'Date and Time',
        width: 200,
        dataIndex: 'date_time',
        key: 'date_time',
        fixed: 'left',
      },
      {
        title: 'Searched File Name',
        width: 200,
        dataIndex: 'searched_file_name',
        key: 'searched_file_name',
        fixed: 'left',
      },
      {
        title: 'Sheet Name',
        width: 150,
        dataIndex: 'sheet_name',
        key: 'sheet_name',
        fixed: 'left',
      },
      {
        title: 'User ID',
        width: 70,
        dataIndex: 'user_id',
        key: 'user_id',
        render: (_, record) => (
          <div className="history-table-btn" onClick={() => searchById(record.user_id)}>
            {record.user_id}
          </div>
        ),
      },
      {
        title: 'User',
        width: 200,
        dataIndex: 'user_name',
        key: 'user_name',
        render: (_, record) => (
          <div className="history-table-btn" onClick={() => searchById(record.user_id)}>
            {record?.user_name} {record?.user_last_name}
          </div>
        ),
      },
      {
        title: 'User Email',
        width: 200,
        dataIndex: 'user_email',
        key: 'user_email',
      },
      {
        title: 'Phone Number',
        width: 200,
        dataIndex: 'user_phone',
        key: 'user_phone',
      },
      {
        title: 'Type of Search',
        width: 120,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Number of Rows in File',
        width: 150,
        dataIndex: 'total_records',
        key: 'total_records',
      },
      {
        title: 'Total Hits',
        width: 100,
        dataIndex: 'total_hits',
        key: 'total_hits',
      },
      {
        title: 'Hit Percentage',
        width: 100,
        dataIndex: 'hit_percentage',
        key: 'hit_percentage',
      },
      {
        title: 'Skips Prior to Search',
        width: 120,
        dataIndex: 'skips_prior_search',
        key: 'skips_prior_search',
      },
      {
        title: 'Skips Client Was Charged',
        width: 100,
        dataIndex: 'skips_client_was_charged',
        key: 'skips_client_was_charged',
      },
      {
        title: 'Skips saved due to duplicate detection',
        width: 100,
        dataIndex: 'skips_saved',
        key: 'skips_saved',
      },
      {
        title: 'Skips Remaining After Search',
        width: 150,
        dataIndex: 'skips_remaining_after_search',
        key: 'skips_remaining_after_search',
      },
      {
        title: 'How many seconds did the search take?',
        width: 100,
        dataIndex: 'search_time',
        key: 'search_time',
      },
      {
        title: 'Total duplicates',
        width: 90,
        dataIndex: 'total_duplicates',
        key: 'total_duplicates',
      },
      {
        title: 'Phone hits',
        width: 100,
        dataIndex: 'total_phone_hits',
        key: 'total_phone_hits',
      },
      {
        title: 'Email hits',
        width: 100,
        dataIndex: 'total_email_hits',
        key: 'total_email_hits',
      },
    ];

    if (isUserSuperAdmin) {
      const adminColumn = [
        {
          title: 'Skiptrace Original File Download Link',
          width: 150,
          dataIndex: 'original',
          key: 'original',
          render: (_, record) => (
            <>
              {isUserSuperAdmin && (
                <button
                  className="white-label-table__csv"
                  style={{ height: '25px' }}
                  onClick={() =>
                    dispatch(
                      downloadOriginal(
                        {
                          file_id: record.file_id,
                          user_id: record.user_id,
                          type: record.type,
                        },
                        record.searched_file_name
                      )
                    )
                  }
                >
                  Download
                </button>
              )}
            </>
          ),
        },
        {
          title: 'Skiptrace Results File Download Link',
          width: 150,
          dataIndex: 'results',
          key: 'results',
          render: (_, record) => (
            <>
              {isUserSuperAdmin && (
                <button
                  className="white-label-table__csv"
                  style={{ height: '25px' }}
                  onClick={() => dispatch(downloadUpdated({ file_id: record.file_id, user_id: record.user_id, type: record.type }, record.searched_file_name))}
                >
                  Download
                </button>
              )}
            </>
          ),
        },
        {
          title: 'Update expiration date',
          width: 200,
          dataIndex: 'date',
          key: 'date',
          render: (_, record) => <DatePickerTable data={record} />,
        },
      ];
      adminColumn.forEach((item) => {
        column.push(item);
      });
    }

    return column;
  }, [isUserSuperAdmin, dispatch]);

  const startDateHandler = (date, dateString) => {
    setTmpStart(dateString);
  };

  const endDateHandler = (date, dateString) => {
    setTmpEnd(dateString);
  };

  const submitDateHandler = () => {
    setStartDate(tmpStart);
    setEndDate(tmpEnd);
  };

  useEffect(() => {
    dispatch(getSkiptraceStatistics(id, startDate, endDate, false, page, user));
  }, [dispatch, startDate, endDate, id, page, user]);

  const tableWidth = useMemo(() => {
    return width <= 1279 ? '1200px' : collapsed ? `${width - 250}px` : `${width - 370}px`;
  }, [width, collapsed]);

  const tableHeight = useMemo(() => {
    return width < 1400 && height < 750 ? `${pages > 1 ? '230px' : '275px'}` : width < 1450 ? '255px' : '375px';
  }, [width, height, pages]);

  return (
    <Spin spinning={loading}>
      <div className={classnames('white-label-table', { 'with-pagination-skiptrace': pages > 1 })}>
        <Navigation id={id} />
        <DatePickers
          user={user}
          setUser={setUser}
          startDateHandler={startDateHandler}
          endDateHandler={endDateHandler}
          request={getSkiptraceStatistics}
          id={id}
          startDate={startDate}
          endDate={endDate}
          dispatch={dispatch}
          submitDate={submitDateHandler}
        />
        {!loading && (
          <>
            {_.isEmpty(skiptrace) && !loading ? (
              <NoResult text="Skiptrace history is empty" />
            ) : (
              <>
                <Table
                  loading={loading}
                  pagination={false}
                  style={{
                    width: tableWidth,
                    margin: '0 auto',
                    height: '0px',
                    borderTop: '1px solid var(--main-color)',
                  }}
                  columns={columns}
                  dataSource={skiptrace}
                  scroll={{
                    x: '1000px',
                    y: tableHeight,
                  }}
                  rowKey="index"
                />
                <div className="m-t-auto m-l-auto m-r-10">
                  {!loading && (
                    <PaginationPanelComponent
                      pagintationPage={page}
                      setPaginationPage={setPage}
                      totalCount={pages * ITEMS_PER_PAGE}
                      itemsPerPage={ITEMS_PER_PAGE}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default Skiptrace;
