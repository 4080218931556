import React from 'react';

const HeaderSvg = ({ children, className }) => {
  return (
    <div className={className + ' svg'}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 605.98 210.28">
        <defs>
          <linearGradient id="SKIPCLOUD" y1="105.14" x2="584.03" y2="105.14" gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{ stopColor: 'var(--gradient-1)' }} stopColor="var(--gradient-1)" />
            <stop offset="0.7" style={{ stopColor: 'var(--gradient-2)' }} stopColor="var(--gradient-2)" />
            <stop offset="1" style={{ stopColor: 'var(--gradient-3)' }} stopColor="var(--gradient-3)" />
          </linearGradient>
        </defs>
        <g id="Слой_2" data-name="Слой 2">
          <g id="Layer_1" data-name="Layer 1">
            <path fill="url(#SKIPCLOUD)" className="cls-1" d="M0,0V87.24c0,28.42,22.41,70.31,70.31,75.58S606,210.28,606,210.28V0Z" />
          </g>
        </g>
      </svg>
      {children}
    </div>
  );
};

export default HeaderSvg;
