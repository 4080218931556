import React from 'react';
import { Input } from 'antd';

const Form = ({ setSkipsCount, skipsCount, setState, wlID, userID, callback, state, disabled }) => {
  const cancelHandler = () => {
    setSkipsCount('');
    setState((prev) => !prev);
  };

  const submitHandler = () => {
    callback(wlID, userID);
  };

  return (
    <>
      {state && (
        <div className="flex flex-align-items-center flex-justify-content-between">
          <Input width="300px" placeholder="Skips" onChange={(e) => setSkipsCount(e.target.value)} value={skipsCount} className="m-r-10" type="number" />
          <button className="m-r-5 skips-btn" onClick={cancelHandler}>
            Cancel
          </button>
          <button className="skips-btn" onClick={submitHandler} disabled={disabled}>
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default Form;
