import React from 'react';
import { ReactComponent as ReactVideoBackSvg } from '../../../../assets/landing/video-background.svg';
import VideoBackground from './video-background-mobile.svg';
import { ReactComponent as ReactQouteSvg } from '../../../../assets/landing/icons/icon-quote.svg';
import logoPng from '../../../../assets/auth/login_logo.png';
import { Select } from 'antd';
import { months, sort_examples } from '../../../../utils/constants';
import { ReactComponent as ReactSearchSvg } from '../../../../assets/UI/search.svg';
import { ReactComponent as ReactCheckboxSvg } from '../../../../assets/landing/checkbox.svg';
import { ReactComponent as ReactmultibleSvg } from '../../../../assets/landing/icons/icon-copy.svg';
import { SignUpLink } from '../../Landing';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InfoIcon from '../../../../assets/UI/info_icon.svg';
import Download from '../../../../assets/pop-up/table-download.svg';
import { sliceString } from '../../../../utils/functions';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import Skeleton from '../../../../assets/landing/skeleton-table.svg';
import './styles.scss';

const Video = () => {
  const width = useResizeWindows();

  return (
    <section className="landing__video-section">
      <h2>
        <b>Find Anyone</b>, Anywhere, Anytime.
      </h2>
      <p>A Next-Generation Skip Tracing tool that gives you the data you need instantly and accurately.</p>
      <iframe className="landing__video-iframe" title="video" src="https://www.youtube.com/embed/p9vNZO4HVnk?rel=0" frameBorder="0" allowFullScreen />
      {width < 600 ? (
        <img src={VideoBackground} className="landing__video-back-decoration" alt="" />
      ) : (
        <ReactVideoBackSvg className="landing__video-back-decoration" />
      )}

      {width > 800 ? (
        <form className="landing__example-form">
          <ReactQouteSvg />
          <img src={logoPng} alt="logoPng" />
          <fieldset className="landing__example-fieldset">
            <Select className="landing__example-select" defaultValue={sort_examples[0]}>
              {sort_examples.map((month) => (
                <Select.Option key={month.id} value={month.value}>
                  {month.name}
                </Select.Option>
              ))}
            </Select>
            <Select className="landing__example-select" defaultValue={months[0]}>
              {months.map((month) => (
                <Select.Option key={month.id} value={month.value}>
                  {month.name}
                </Select.Option>
              ))}
            </Select>
            <div className="landing__example-search-container">
              <input className="landing__example-search" type="text" placeholder="Search file name" defaultValue={''} />
              <ReactSearchSvg />
            </div>
          </fieldset>
          <ul className="landing__example-list">
            <li className="landing__example-item">
              <div>Check area</div>
              <div className="landing__example-content-container landing__example-content-container--heading">
                <h4>File name</h4>
                <h4>Date</h4>
                <h4>Total hits</h4>
                <h4>Result breakdown</h4>
                <h4>Download</h4>
              </div>
            </li>
            <li className="landing__example-item">
              <div className="landing__example-checkbox-container">
                <input id="checkbox1" type="checkbox" className="landing__example-checkbox" />
                <label htmlFor="checkbox1" className="landing__example-checkbox-label">
                  <ReactCheckboxSvg />
                </label>
              </div>
              <div className="landing__example-content-container">
                <div>
                  <button className="plus" type="button">
                    <span>+</span>
                  </button>
                  <div className="landing__title-block">
                    <h4>{sliceString(width, 'Skip Cloud - Sample Pre-Skip Data 2.x')}</h4>
                    <span className="landing__example-multiply">
                      <ReactmultibleSvg />
                      <span>MULTIPLE FILES</span>
                    </span>
                  </div>
                </div>
                <div>6/29/22</div>
                <div>
                  <p className="landing__results">378 RESULTS</p>
                </div>
                <button className="button-action" type="button">
                  <LazyLoadImage className="action__icon" src={InfoIcon} alt="icon" />
                </button>
                <button className="button-action" type="button">
                  <LazyLoadImage className="action__icon" src={Download} alt="icon" />
                </button>
              </div>
            </li>
            <li className="landing__example-item">
              <div className="landing__example-checkbox-container">
                <input id="checkbox2" type="checkbox" className="landing__example-checkbox" />
                <label htmlFor="checkbox2" className="landing__example-checkbox-label">
                  <ReactCheckboxSvg />
                </label>
              </div>
              <div className="landing__example-content-container">
                <div>
                  <h4>{sliceString(width, 'Skip Cloud - Sample Pre-Skip Data 2.x')}</h4>
                </div>
                <div>6/29/22</div>
                <div>
                  <p className="landing__results">192 RESULTS</p>
                </div>
                <button className="button-action" type="button">
                  <LazyLoadImage className="action__icon" src={InfoIcon} alt="icon" />
                </button>
                <button className="button-action" type="button">
                  <LazyLoadImage className="action__icon" src={Download} alt="icon" />
                </button>
              </div>
            </li>
          </ul>
        </form>
      ) : (
        <img src={Skeleton} alt="" className="mobile-table" />
      )}

      <h3>
        NAME A <b>FASTER, MORE ACCURATE, BETTER LOOKING</b>
        <br />
        SKIP TRACING SOLUTION.
        <span>WE’LL WAIT.....</span>
      </h3>
      <SignUpLink text={'Sign me up now!'} className="landing__example-link-button landing__signup-link" />
    </section>
  );
};

export default Video;
