import {
  CREATE_TEMPLATE_SEND_GRID_FAILURE,
  CREATE_TEMPLATE_SEND_GRID_REQUEST,
  CREATE_TEMPLATE_SEND_GRID_SUCCESS,
  DELETE_TEMPLATE_SEND_GRID_FAILURE,
  DELETE_TEMPLATE_SEND_GRID_REQUEST,
  DELETE_TEMPLATE_SEND_GRID_SUCCESS,
  EDIT_TEMPLATE_SEND_GRID_FAILURE,
  EDIT_TEMPLATE_SEND_GRID_REQUEST,
  EDIT_TEMPLATE_SEND_GRID_SUCCESS,
  GET_SOLO_TEMPLATE_SEND_GRID_FAILURE,
  GET_SOLO_TEMPLATE_SEND_GRID_REQUEST,
  GET_SOLO_TEMPLATE_SEND_GRID_SUCCESS,
  GET_TEMPLATE_SEND_GRID_FAILURE,
  GET_TEMPLATE_SEND_GRID_REQUEST,
  GET_TEMPLATE_SEND_GRID_SUCCESS,
} from '../actionsTypes';

const initialState = {
  loading: false,
  error: null,
  templates: [],
  template: {},
};

const sendGridReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_TEMPLATE_SEND_GRID_REQUEST:
      return { ...state, loading: true };
    case GET_TEMPLATE_SEND_GRID_SUCCESS:
      return { ...state, loading: false, templates: action.templates };
    case GET_TEMPLATE_SEND_GRID_FAILURE:
      return { ...state, loading: false, error: action.error };

    case GET_SOLO_TEMPLATE_SEND_GRID_REQUEST:
      return { ...state, loading: true };
    case GET_SOLO_TEMPLATE_SEND_GRID_SUCCESS:
      return { ...state, loading: false, template: action.template };
    case GET_SOLO_TEMPLATE_SEND_GRID_FAILURE:
      return { ...state, loading: false, error: action.error };

    case CREATE_TEMPLATE_SEND_GRID_REQUEST:
      return { ...state, loading: true };
    case CREATE_TEMPLATE_SEND_GRID_SUCCESS:
      return { ...state, loading: false };
    case CREATE_TEMPLATE_SEND_GRID_FAILURE:
      return { ...state, loading: false, error: action.error };

    case DELETE_TEMPLATE_SEND_GRID_REQUEST:
      return { ...state, loading: true };
    case DELETE_TEMPLATE_SEND_GRID_SUCCESS:
      return { ...state, loading: false };
    case DELETE_TEMPLATE_SEND_GRID_FAILURE:
      return { ...state, loading: false, error: action.error };

    case EDIT_TEMPLATE_SEND_GRID_REQUEST:
      return { ...state, loading: true };
    case EDIT_TEMPLATE_SEND_GRID_SUCCESS:
      return { ...state, loading: false, templates: action.templates };
    case EDIT_TEMPLATE_SEND_GRID_FAILURE:
      return { ...state, loading: false, error: action.error };

    default:
      return { ...state };
  }
};

export default sendGridReducer;
