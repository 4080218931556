import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { getUsersList } from '../../../../store/actions/adminActions';
import { PaginationPanelComponent } from '../../../../Components/common/PaginationPanel/paginationPanel';
import { ITEMS_PER_PAGE, USER_LIST_HEADER } from '../../../../utils/constants';
import { useParams, useRouteMatch } from 'react-router-dom';
import { AppContext } from '../../../../appContext';
import _ from 'lodash';
import NoResult from '../../../../Components/common/NoResult/NoResult';
import classNames from 'classnames';
import SearchComponent from './components/Search';
import SortArrow from '../../../../assets/UI/sort-arrow.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Row from './components/Row';
import './styles.scss';

const UsersListComponent = () => {
  const dispatch = useDispatch();
  const usersList = useSelector((state) => state?.admin.users);
  const pageCount = useSelector((state) => state?.admin.pages);
  const loading = useSelector((state) => state?.admin.loading);
  const { whiteLabelId, isUserAdmin, isUserSuperAdmin, isUserMainAdmin } = useContext(AppContext);
  const { url } = useRouteMatch();
  const { id } = useParams();
  const anotherWL = url.includes('white-label');
  const currentWLId = anotherWL ? id : whiteLabelId;
  const [paginationPage, setPaginationPage] = useState(1);
  const [type, setType] = useState('all');
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('');
  const [sort, setSort] = useState(true);
  const [access, setAccess] = useState(false);

  const actionApprove = useMemo(() => {
    return (isUserMainAdmin && !anotherWL) || isUserSuperAdmin || isUserAdmin;
  }, [isUserSuperAdmin, isUserAdmin, isUserMainAdmin, anotherWL]);

  useEffect(() => {
    if (access) {
      dispatch(getUsersList(currentWLId, paginationPage, type, search, order || 'id', sort));
      setAccess(false);
    }
  }, [dispatch, paginationPage, currentWLId, type, order, sort, search, access]);

  useEffect(() => {
    dispatch(getUsersList(currentWLId));
  }, [dispatch, currentWLId]);

  const searchHandler = () => {
    setPaginationPage(1);
    setAccess(true);
  };

  const orderHandler = (value) => {
    setSort((prev) => !prev);
    setOrder(value);
    setPaginationPage(1);
    setAccess(true);
  };

  return (
    <Spin spinning={loading} size={'large'}>
      <SearchComponent search={search} setSearch={setSearch} searchHandler={searchHandler} setType={setType} />
      <ul className="users-list">
        <li className="users-list__item users-list__item--heading">
          {USER_LIST_HEADER.map((item, index) => (
            <h3 onClick={() => orderHandler(item.order)} key={index}>
              {item.value}
              <LazyLoadImage className={`sort-icon ${!sort && order === item.order ? 'sort-icon-top' : ''}`} src={SortArrow} alt="arrow" />
            </h3>
          ))}
          <h3>notifications</h3>
          {actionApprove && <h3>Actions</h3>}
        </li>
      </ul>
      <div className={classNames('users-list__container', { 'with-pagination': pageCount > 1 })}>
        {!loading && (
          <>
            {_.isEmpty(usersList) ? (
              <NoResult text="User list is empty" />
            ) : (
              <ul className="users-list">
                {usersList.map((user, index) => (
                  <Row url={url} user={user} key={index} anotherWL={anotherWL} actionApprove={actionApprove} currentWLId={currentWLId} />
                ))}
              </ul>
            )}
          </>
        )}
      </div>
      <PaginationPanelComponent
        pagintationPage={paginationPage}
        setPaginationPage={setPaginationPage}
        setAccess={setAccess}
        totalCount={pageCount * ITEMS_PER_PAGE}
        itemsPerPage={ITEMS_PER_PAGE}
      />
    </Spin>
  );
};

export default UsersListComponent;
