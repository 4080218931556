import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Welcome from './sections/Welcome/Welcome';
import Video from './sections/Video/Video';
import Price from './sections/Price/Price';
import Advantages from './sections/Advantages/Advantages';
import { ReactComponent as ReactArrowSvg } from '../../assets/landing/icons/icon-arrow.svg';
import Brands from '../../assets/landing/brands.svg';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { useSelector } from 'react-redux';
import { apiURL } from '../../utils/config';
import ReactGa from 'react-ga4';
import './styles.scss';
import { saveToLocalStorage } from '../../utils/localStorage';

export const SignUpLink = ({ text, className = '', onClick = null }) => {
  return (
    <Link to={'/sign-up'} className={`${className}`} onClick={onClick && onClick}>
      <span>{text}</span>
      <span>
        <ReactArrowSvg />
      </span>
    </Link>
  );
};

const LandingPageComponent = () => {
  const widthScreen = useResizeWindows();
  const loginLogo = useSelector((state) => state.config.config?.images?.logo_1);
  const pathname = useHistory().location.pathname;
  const { search } = useLocation();

  useEffect(() => {
    saveToLocalStorage('promoter', search);
  }, [search]);

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="landing">
      <Welcome />
      <article className="landing__brand-article">
        <div className="landing__brand-list">
          <img src={Brands} alt="forbes" />
        </div>
      </article>
      <Video />
      <Advantages />
      {/*<Success />*/}
      <Price />
      {/*<AboutCompany />*/}
      {widthScreen > 500 && (
        <div className="landing__footer">
          <img src={apiURL + loginLogo} alt="" className="logo__img" />
        </div>
      )}
      {widthScreen < 500 && (
        <div className="landing__mobile__footer">
          <img src={apiURL + loginLogo} alt="" className="logo__img" />
        </div>
      )}
    </div>
  );
};

export default LandingPageComponent;
