import React, { useEffect, useMemo, useRef, useState } from 'react';
import Field from '../../Components/common/DataMatchingField/DataMatchingField';
import CheckBox from '../../Components/common/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { editFilesHeader, personProcessHelper, postFilesSuccess, postSheet } from '../../store/actions/fileActions';
import { Select, Spin, Steps } from 'antd';
import { data_columns, dataMatchingInitial, state, stateSelect } from '../../utils/constants';
import ResultsModal from '../../Components/common/ResultsModal/ResultsModal';
import history from '../../utils/history';
import { sliceString } from '../../utils/functions';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import ReactGa from 'react-ga4';
import { useHistory } from 'react-router-dom';
import { wsURL } from '../../utils/config';
import { changeProcessStatus } from '../../store/actions/processActions';
import './styles.scss';

const DataMatching = () => {
  const dispatch = useDispatch();
  const pathname = useHistory().location.pathname;
  const initialFiles = useSelector((state) => state?.files?.files);
  const processId = useSelector((state) => state?.files?.processId);
  const files = useMemo(() => (initialFiles ? initialFiles : []), [initialFiles]);
  const loading = useSelector((state) => state?.files?.sheetsLoading);
  const rows = useSelector((state) => state?.files?.rows);
  const [totalRows, setTotalRows] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentFile, setCurrentFile] = useState(0);
  const [checkbox, setCheckbox] = useState(files?.length ? !!files[currentFile]?.sheets[currentStep]?.skip_header : false);
  const [selectState, setSelectState] = useState(stateSelect);
  const [dataMatching, setDataMatching] = useState(dataMatchingInitial);
  const [show, setShow] = useState(false);
  const [loadingWS, setLoadingWS] = useState(true);
  const width = useResizeWindows();
  const ws = useRef(null);
  const wsUser = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (processId) {
      setTimeout(() => dispatch(personProcessHelper(processId)), 10000);
    }
  }, [processId, dispatch]);

  useEffect(() => {
    setTotalRows(rows);
  }, [rows]);

  useEffect(() => {
    if (wsUser && wsUser?.id && ws) {
      const ping = () => {
        ws.current.send(JSON.stringify({ user: wsUser?.id && wsUser?.id.toString() }));
      };

      ws.current = new WebSocket(wsURL);
      ws.current.onopen = () => {
        ws.current.send(JSON.stringify({ user: wsUser?.id && wsUser?.id.toString() }));
      };

      ws.current.onmessage = (e) => {
        const message = JSON.parse(e.data);
        if (message.type === 'PROCESS_CONVERSION' && message.progress === 'draft') {
          dispatch(postFilesSuccess(message?.data?.person_files, message?.data?.total_count_rows, message?.data?.id));
          dispatch(changeProcessStatus(message?.data?.process_id, message.progress));
        }

        if (message.type === 'PROCESS_CONVERSION_ERROR') history.push('/dashboard');
      };

      const pingInterval = setInterval(ping, 50000);

      return () => {
        ws.current.close();
        clearInterval(pingInterval);
      };
    }
  }, [ws, wsUser, dispatch]);

  useEffect(() => {
    if (files.length) setLoadingWS(false);
  }, [files.length]);

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (files.length) {
      if (currentStep < files[currentFile].sheets.length) {
        if (checkbox) {
          if (files[currentFile].sheets[currentStep]?.skip_header === null || !!files[currentFile].sheets[currentStep].skip_header !== checkbox) {
            setDataMatching(dataMatchingInitial);
            setSelectState(
              files[currentFile].sheets[currentStep]?.first_row_values.map((item) => ({
                ...item,
                disabled: false,
              }))
            );
          } else {
            setDataMatching(
              Object.keys(files[currentFile].sheets[currentStep].data_matching).length
                ? files[currentFile].sheets[currentStep].data_matching
                : dataMatchingInitial
            );
            setSelectState(
              files[currentFile].sheets[currentStep].first_row_values.map((items) => {
                Object.keys(files[currentFile].sheets[currentStep].data_matching).forEach((item) => {
                  if (items.value === files[currentFile].sheets[currentStep].data_matching[item]) items.disabled = true;
                });
                return items;
              })
            );
          }
        } else if (!checkbox) {
          if (files[currentFile].sheets[currentStep].skip_header === null && !!files[currentFile].sheets[currentStep].skip_header !== checkbox) {
            setDataMatching(dataMatchingInitial);
            setSelectState(
              files[currentFile].sheets[currentStep].first_row_keys.map((item, index) => ({
                ...item,
                sub: files[currentFile].sheets[currentStep].first_row_values[index].key,
                disabled: false,
              }))
            );
          } else {
            setDataMatching(
              Object.keys(files[currentFile].sheets[currentStep].data_matching).length
                ? files[currentFile].sheets[currentStep].data_matching
                : dataMatchingInitial
            );
            setSelectState(
              files[currentFile].sheets[currentStep].first_row_keys.map((items, index) => {
                Object.keys(files[currentFile].sheets[currentStep].data_matching).forEach((item) => {
                  if (items.value === files[currentFile].sheets[currentStep].data_matching[item]) items.disabled = true;
                });
                return { ...items, sub: files[currentFile].sheets[currentStep].first_row_values[index].key };
              })
            );
          }
        }
      }
    } else {
      setSelectState(stateSelect);
      setDataMatching(dataMatchingInitial);
    }
  }, [checkbox, currentFile, currentStep, files]);

  const stepsNavigation = (value, file) => {
    setCurrentStep(value);
    if (files[file].sheets[value]?.data_matching && Object.keys(files[file].sheets[value]?.data_matching).length) {
      setCheckbox(!!files[file].sheets[value].skip_header);
      setDataMatching(files[file].sheets[value].data_matching);
    } else {
      setCheckbox(!!files[file].sheets[value].skip_header);
      setDataMatching(dataMatchingInitial);
    }
  };

  const nextFile = async () => {
    if (files[currentFile].sheets.length - 1 > currentStep) {
      const response = await dispatch(
        postSheet({
          id: files[currentFile].sheets[currentStep].sheet_id,
          dataMatching,
          skipHeader: checkbox,
        })
      );
      if (response) stepsNavigation(currentStep + 1, currentFile);
    } else {
      if (files.length - 1 > currentFile) {
        const response = await dispatch(
          postSheet({
            id: files[currentFile].sheets[currentStep].sheet_id,
            dataMatching,
            skipHeader: checkbox,
          })
        );
        if (response) {
          setCurrentFile((prev) => (prev + 1 <= files.length ? prev + 1 : prev));
          stepsNavigation(0, currentFile + 1);
          setCheckbox(false);
        }
      } else if (files[currentFile].sheets.length - 1 === currentStep && files.length - 1 === currentFile) {
        const response = await dispatch(
          postSheet({
            id: files[currentFile].sheets[currentStep].sheet_id,
            dataMatching,
            skipHeader: checkbox,
          })
        );
        if (response) {
          setShow(true);
          setCurrentFile(0);
          setCurrentStep(0);
        }
      }
    }
  };

  const prevFile = () => {
    if (!currentStep && !currentFile) {
      history.push('/dashboard');
    }

    if (currentStep > 0) {
      stepsNavigation(currentStep - 1, currentFile);
    } else if (currentFile > 0) {
      setCurrentFile((prev) => prev - 1);
      stepsNavigation(files[currentFile - 1].sheets.length - 1, currentFile - 1);
    }
  };

  const fileNavigationHandler = (index) => {
    setCurrentFile(index);
    stepsNavigation(0, index);
    dispatch(
      postSheet({
        id: files[currentFile].sheets[currentStep].sheet_id,
        dataMatching,
        skipHeader: checkbox,
      })
    );
  };

  const stepsNavigationHandler = (value) => {
    stepsNavigation(value, currentFile);
    dispatch(
      postSheet({
        id: files[currentFile].sheets[currentStep].sheet_id,
        dataMatching,
        skipHeader: checkbox,
      })
    );
  };

  return (
    <Spin spinning={!!loading || loadingWS} size="large">
      <div className="container">
        <div className="data-matching__article-container">
          <h1 className="data-matching__article">
            Let's Match <span>Your data</span>
          </h1>
          <h6 className="data-matching__sub">
            At minimum you must make a selection for ‘Mailing Address’, ‘Mailing City’, and ‘Mailing State’. Note that the more columns you are able to match,
            the more accurate your results will be. Don’t forget to click the checkbox at the bottom if your file contains a header row
          </h6>
          {files.length ? (
            <div className="steps">
              <Steps current={currentStep} onChange={(value) => stepsNavigationHandler(value)}>
                {files[currentFile]?.sheets.map((sheet, index) => (
                  <Steps.Step
                    title={sheet.name}
                    key={index}
                    status={currentStep === index ? 'process' : sheet.status}
                    className={!!sheet?.error ? 'data-matching__error' : ''}
                  />
                ))}
              </Steps>
            </div>
          ) : null}
        </div>
        <div className="data-matching">
          <div className="data-matching__item">
            <h4>DATA FILES</h4>
            <div className="data-matching__item-inner">
              {files.map((file, index) => (
                <div className={`${currentFile === index ? 'data-matching__item-file' : ''}`} key={file.id}>
                  <Field
                    error={!!file?.error}
                    width="90%"
                    disabled
                    value={file.original_name}
                    shortValue={file.original_name.length > 25 ? file.original_name.slice(0, 25) + '...' : file.original_name}
                    label=""
                    placeholder=""
                    onClick={() => fileNavigationHandler(index)}
                  />
                </div>
              ))}
            </div>
            <div className="total-rows">
              <p>Total rows count : {totalRows}</p>
              <p className="m-t-5">Maximum skips required is {totalRows}</p>
            </div>
          </div>
          <div className="data-matching__item">
            <div className="data-matching__form">
              <Field disabled value={state[0]} />
              <Field disabled value={state[1]} />
              <Field disabled value={state[2]} />
              <Field disabled value={state[3]} />
              <Field disabled value={state[4]} />
              <Field parentClasName=" m-b-20" disabled value={state[5]} />
              <CheckBox
                checked={checkbox}
                setChecked={(value) => {
                  setCheckbox(value);
                  dispatch(editFilesHeader(files[currentFile].sheets[currentStep]?.sheet_id, value));
                }}
                className="data-matching__checkbox m-t-20"
                id={1}
                text="The first row in my file is a column header, do not import it."
              />
            </div>
          </div>
          <div className="data-matching__item">
            <div className="data-matching__form-user ">
              {data_columns.map((name, index) => (
                <Select
                  key={index + name}
                  value={dataMatching[name]}
                  onChange={(value) => {
                    setDataMatching((prev) => ({
                      ...prev,
                      [name]: value,
                    }));
                    setSelectState((prev) =>
                      prev.map((item) =>
                        item.value === dataMatching[name]
                          ? { ...item, disabled: false }
                          : item.value === value
                          ? {
                              ...item,
                              disabled: true,
                            }
                          : item
                      )
                    );
                  }}
                >
                  <Select.Option value={''} key="333">
                    Select Column
                  </Select.Option>
                  {selectState.map(({ value, sub, key, id, disabled }) => (
                    <Select.Option value={value} key={id} disabled={disabled}>
                      <div className="select-variant">
                        <p> {key}</p>
                        {sub && <span>({sliceString(width, sub, 26, 20, 20)})</span>}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              ))}
              <button className="data-matching__button data-matching__continue" onClick={nextFile}>
                {(files[currentFile]?.sheets?.length - 1 === currentStep && files?.length - 1 === currentFile) || !files.length
                  ? 'Submit'
                  : files[currentFile]?.sheets?.length === currentStep
                  ? 'NEXT'
                  : 'CONTINUE'}
              </button>
              <button className="data-matching__button" onClick={prevFile}>
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
      <ResultsModal setShow={setShow} show={show} files={files} />
    </Spin>
  );
};
export default DataMatching;
