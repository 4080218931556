import React, { useContext, useState } from 'react';
import ReactLogoSvg from '../../../../assets/landing/icons/logo.png';
import { ReactComponent as ReactMonitorSvg } from '../../../../assets/landing/icons/monitor.svg';
import { SignUpLink } from '../../Landing';
import Navigation from '../../components/Navigation/Navigation';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import MobileNavigation from '../../components/Navigation/MobileNavigation';
import { toCapitalize } from '../../../../utils/functions';
import { AppContext } from '../../../../appContext';
import { saveToLocalStorage } from '../../../../utils/localStorage';
import { postWebHook } from '../../../../store/actions/webHookActions';
import { useDispatch } from 'react-redux';
import './styles.scss';

const Welcome = () => {
  const width = useResizeWindows();
  const dispatch = useDispatch();
  const { brand } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');

  const saveDataHandler = () => {
    saveToLocalStorage('register-user', { email, name, lastName });
    dispatch(postWebHook({ email, first_name: name, last_name: lastName }));
  };

  return (
    <section className="lading__welcome-section">
      <div className="landing__welcome-decoraion" />
      <header className="landing__header">{width > 900 ? <Navigation /> : <MobileNavigation />}</header>
      <div className="landing__feature-container">
        <img src={ReactLogoSvg} alt="logo" />

        <div className="landing__monitor-container">
          <span>As low as $0.09 per HIT</span>
          <ReactMonitorSvg className="landing__monitor-picture" />
        </div>

        <div className="landing__welcome-text-container">
          <h1>
            THE <b>FUTURE</b> OF SKIP TRACING IS HERE
          </h1>
          <p>
            {toCapitalize(brand)} is the <b>leading cloud-based</b> public and private records software for skip tracing.
          </p>
          <form className="landing__welcome-form">
            {width <= 800 && (
              <>
                <h6>
                  Make a <b>FREE</b> {toCapitalize(brand)} Account Now!
                </h6>
                <p>No credit card info needed to sign up.</p>
              </>
            )}
            <input className="landing__welcome-name" type={'text'} placeholder="Your First Name?" value={name} onChange={(e) => setName(e.target.value)} />
            <input
              className="landing__welcome-last-name"
              type={'text'}
              placeholder="Your Last Name?"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <div className="landing__input-container">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="landing__welcome-email"
                type={'email'}
                placeholder="Best Email Address?"
              />
              {width > 800 ? <SignUpLink text={'sign up free'} className="landing__welcome-form-link landing__signup-link" onClick={saveDataHandler} /> : null}
            </div>
            {width <= 800 ? <SignUpLink text={'sign up free'} className="landing__welcome-form-link landing__signup-link" onClick={saveDataHandler} /> : null}
          </form>
          <span>We’ll make you an account so you can check it out and see if you like it first before you pay a dime!</span>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
