import React, { useEffect, useState } from 'react';
import { ReactComponent as ReactHomeSvg } from '../../../assets/white-label/icons/icon-price-card-home.svg';
import { ReactComponent as ReactLockSvg } from '../../../assets/white-label/icons/icon-lock.svg';
import { ReactComponent as ReactPhoneSvg } from '../../../assets/white-label/icons/icon-phone.svg';
import CallendlyList from '../Callendly/CallendlyList';
import './styles.scss';
import { getUserCallendly } from '../../../store/actions/callendlyActions';
import { useDispatch, useSelector } from 'react-redux';

const AdditionalPriceCard = ({ skips }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const loading = useSelector((state) => state?.callendly.loading);
  const list = useSelector((state) => state.callendly.callendly);

  useEffect(() => {
    dispatch(getUserCallendly());
  }, [dispatch]);

  return (
    <div className="additional-price-card">
      <header className="additional-price-card__header">
        <span className="additional-price-card__header-decoration">
          <ReactHomeSvg />
        </span>
        <h3 className="additional-price-card__header-heading">
          <b>ENTERPRISE</b>
          PRICING
        </h3>
        <p>Pricing For High-Volume Searches</p>
      </header>

      <div className="additional-price-card__content-container">
        <div className="additional-price-card__skips-contanier">
          <p className="additional-price-card__price-value">
            <b>{skips}+ </b>
            skips
          </p>
        </div>

        <div className="additional-price-card__blur-container">
          <div className="additional-price-card__blur-content">
            <h4 className="additional-price-card__blur-header">
              $$$$
              <sup>00</sup>
            </h4>
            <p className="price-card__price-skip">${0.07} PER HIT</p>

            <div className="price-card__count-container">
              <button>ADD TO CART</button>
              <div className="price-card__count-inner">
                <button>-</button>
                <p>1</p>
                <button>+</button>
              </div>
            </div>
          </div>

          <div className="additional-price-card__clear-content">
            <ReactLockSvg />
            <h4>Schedule a call for enterprise</h4>
            <button className="additional-price-card__call-button" onClick={() => setShow(true)} disabled={!list.length}>
              Schedule now
              <span>
                <ReactPhoneSvg />
              </span>
            </button>
            <p>Never too late to save money</p>
          </div>
        </div>
      </div>
      <CallendlyList show={show} setShow={setShow} list={list} loading={loading} />
    </div>
  );
};

export default AdditionalPriceCard;
