import { showNotification } from '../../App';
import axios from 'axios';
import {
  CREATE_WEBHOOK_FAILURE,
  CREATE_WEBHOOK_REQUEST, CREATE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_FAILURE,
  DELETE_WEBHOOK_REQUEST,
  DELETE_WEBHOOK_SUCCESS,
  EDIT_WEBHOOK_FAILURE,
  EDIT_WEBHOOK_REQUEST,
  EDIT_WEBHOOK_SUCCESS,
  GET_WEBHOOK_FAILURE,
  GET_WEBHOOK_REQUEST,
  GET_WEBHOOK_SUCCESS, TEST_WEBHOOK_FAILURE, TEST_WEBHOOK_REQUEST, TEST_WEBHOOK_SUCCESS,
} from '../actionsTypes';
import axiosApi from '../../utils/axios';

const getWebHookRequest = () => ({ type: GET_WEBHOOK_REQUEST });
const getWebHookSuccess = (webhooks) => ({ type: GET_WEBHOOK_SUCCESS, webhooks });
const getWebHookFailure = (error) => ({ type: GET_WEBHOOK_FAILURE, error });

const editWebHookRequest = () => ({ type: EDIT_WEBHOOK_REQUEST });
const editWebHookSuccess = (webhook) => ({ type: EDIT_WEBHOOK_SUCCESS, webhook });
const editWebHookFailure = (error) => ({ type: EDIT_WEBHOOK_FAILURE, error });


const createWebHookRequest = () => ({ type: CREATE_WEBHOOK_REQUEST });
const createWebHookSuccess = (webhook) => ({ type: CREATE_WEBHOOK_SUCCESS, webhook });
const createWebHookFailure = (error) => ({ type: CREATE_WEBHOOK_FAILURE, error });

const testWebHookRequest = () => ({ type: TEST_WEBHOOK_REQUEST });
const testWebHookSuccess = () => ({ type: TEST_WEBHOOK_SUCCESS });
const testWebHookFailure = (error) => ({ type: TEST_WEBHOOK_FAILURE, error });

const deleteWebHookRequest = () => ({ type: DELETE_WEBHOOK_REQUEST });
const deleteWebHookSuccess = (id) => ({ type: DELETE_WEBHOOK_SUCCESS, id });
const deleteWebHookFailure = (error) => ({ type: DELETE_WEBHOOK_FAILURE, error });

export const postWebHook = (userData) => {
  return async () => {
    try {
      await axios.post('https://hook.integromat.com/sx3urfx03ndy98wnvrta0u1qkoluv6uo', userData);
    } catch (error) {
      showNotification('error', 'No Internet', 'Error');
    }
  };
};

export const getWebHook = () => {
  return async (dispatch) => {
    try {
      dispatch(getWebHookRequest());
      const response = await axiosApi.get('/admin/webhooks');
      dispatch(getWebHookSuccess(response.data.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getWebHookFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getWebHookFailure({ global: 'No internet' }));
      }
    }
  };
};

export const editWebHook = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch(editWebHookRequest());
      const response = await axiosApi.put(`/admin/webhooks/edit/${id}`, data);
      dispatch(editWebHookSuccess(response.data.data));
      showNotification('success', response.data.message, `Edited Webhook ${id}`);
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(editWebHookFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(editWebHookFailure({ global: 'No internet' }));
      }

      return false;
    }
  };
};

export const createWebHook = (data) => {
  return async (dispatch) => {
    try {
      dispatch(createWebHookRequest());
      const response = await axiosApi.post('/admin/webhooks/new', data);
      dispatch(createWebHookSuccess(response.data.data));
      showNotification('success', response.data.message, 'Created Webhook');
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(createWebHookFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(createWebHookFailure({ global: 'No internet' }));
      }

      return false;
    }
  };
};

export const deleteWebHook = (id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteWebHookRequest());
      await axiosApi.delete(`/admin/webhooks/delete/${id}`);
      dispatch(deleteWebHookSuccess(id));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(deleteWebHookFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(deleteWebHookFailure({ global: 'No internet' }));
      }
    }
  };
};

export const postTestWebHook = (url, userData) => {
  return async (dispatch) => {
    try {
      dispatch(testWebHookRequest())
      await axios.post(url, userData);
      showNotification('success', 'Test data sent', 'Test Data');
      dispatch(testWebHookSuccess())
    } catch (error) {
      dispatch(testWebHookFailure())
      showNotification('error', 'Test data not sent', 'Error');
    }
  };
};
