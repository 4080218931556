import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { deleteCallendly, editCallendly } from '../../../store/actions/whiteLabelActions';
import { apiURL } from '../../../utils/config';
import { sliceString } from '../../../utils/functions';

const Row = ({ width, user, id, dispatch }) => {
  const [avatar, setAvatar] = useState();
  const [state, setState] = useState(false);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const inputRef = React.useRef();

  useEffect(() => {
    if (user) {
      setName(user.name);
      setUrl(user.url);
      setDescription(user.description);
    }
  }, [user]);

  const activateInput = () => {
    inputRef.current?.click();
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };

  const editHandler = () => {
    dispatch(editCallendly(id, user.id, { avatar, name, url, description }));
    setState(false);
  };

  return (
    <>
      {!state ? (
        <div className="callendly__user">
          <p>
            <Avatar src={apiURL + user.avatar} size={60} />
          </p>
          <p>{user.name}</p>
          <p>{sliceString(width, user.description, 40, 40, 40)}</p>
          <p>{user.url}</p>
          <div className="callendly__user-actions">
            <button onClick={() => setState(true)}>Edit</button>
            <button className="delete" onClick={() => dispatch(deleteCallendly(id, user.id))}>
              Delete
            </button>
          </div>
        </div>
      ) : (
        <div className="callendly__user-edit">
          <input type="file" onChange={onFileChange} ref={inputRef} style={{ display: 'none' }} />
          {!avatar ? (
            <p>
              <button className="avatar__button" onClick={activateInput}>
                Upload Avatar
              </button>
            </p>
          ) : (
            <p onClick={() => setAvatar(null)}>
              <img className="callendly__modal-admin-avatar" src={URL.createObjectURL(avatar)} alt="avatar" />
            </p>
          )}
          <textarea className="input" value={name} onChange={(e) => setName(e.target.value)} />
          <textarea className="input" value={description} onChange={(e) => setDescription(e.target.value)} />
          <textarea className="input" value={url} onChange={(e) => setUrl(e.target.value)} />
          <div className="callendly__user-actions">
            <button onClick={() => setState(false)}>Cancel</button>
            <button onClick={editHandler}>Save</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Row;
