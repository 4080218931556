import { Spin } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaginationPanelComponent } from '../../../../../Components/common/PaginationPanel/paginationPanel';
import { getInactiveDiscounts } from '../../../../../store/actions/adminActions';
import { ITEMS_PER_PAGE } from '../../../../../utils/constants';
import CodeItem from './CodeItem';
import './styles.scss';

const InactiveCodes = ({ wlId }) => {
  const dispatch = useDispatch();
  const [inactivePagintationPage, setInactivePagintationPage] = useState(1);
  const inactivePages = useSelector((state) => state?.admin?.inactiveDiscountsPages) || 1;
  const inactiveDiscounts = useSelector((state) => state?.admin?.inactiveDiscounts);
  const loading = useSelector((state) => state?.admin.inactiveDiscountsLoading);

  useEffect(() => {
    if (wlId) {
      dispatch(getInactiveDiscounts(wlId, inactivePagintationPage));
    }
  }, [dispatch, inactivePagintationPage, wlId]);
  return (
    <Spin spinning={loading}>
      {!loading && (
        <>
          {_.isEmpty(inactiveDiscounts) ? (
            <div className="empty__promo">Inactive codes is empty</div>
          ) : (
            <ul className="promo__codes__table">
              <li className="promo__codes__item users-list__item--heading">
                <h3>Code</h3>
                <h3>Settings</h3>
                <h3>Actions</h3>
              </li>
              {inactiveDiscounts.map((item, index) => (
                <CodeItem key={index} item={item} isActiveCodes={false} />
              ))}
            </ul>
          )}
          <PaginationPanelComponent
            pagintationPage={inactivePagintationPage}
            setPaginationPage={setInactivePagintationPage}
            totalCount={inactivePages * ITEMS_PER_PAGE}
            itemsPerPage={ITEMS_PER_PAGE}
          />
        </>
      )}
    </Spin>
  );
};

export default InactiveCodes;
