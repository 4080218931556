import React, { useContext } from 'react';
import { DatePicker, Select } from 'antd';
import { AppContext } from '../../../../appContext';
import { ACTIVITY_HISTORY } from '../../../../utils/constants';
import './styles.scss';

const DatePickers = ({
  dispatch,
  startDate,
  startDateHandler,
  endDateHandler,
  endDate,
  id,
  request,
  submitDate,
  activityHistory,
  setType = false,
  clearHandler,
  setUser,
  user,
}) => {
  const { isUserBasic } = useContext(AppContext);

  const clearUserHandler = () => setUser('');

  return (
    <div className="white-label-table__header">
      <div className="white-label-table__dates">
        <DatePicker onChange={startDateHandler} />
        <DatePicker onChange={endDateHandler} />
        {activityHistory && (
          <>
            <Select defaultValue={'All'} onChange={(value) => setType(value)}>
              {ACTIVITY_HISTORY.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        {clearHandler && (
          <button onClick={clearHandler} className="white-label-table__csv">
            Clear
          </button>
        )}

        <button onClick={submitDate} className="white-label-table__csv">
          submit
        </button>

        {user && (
          <button onClick={clearUserHandler} className="white-label-table__csv">
            Back to the report
          </button>
        )}
      </div>
      {!isUserBasic && (
        <button className="white-label-table__csv" onClick={() => dispatch(request(id, startDate, endDate, true))}>
          Download CSV
        </button>
      )}
    </div>
  );
};

export default DatePickers;
