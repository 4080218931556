import React, { useState } from 'react';

import StarIcon from '../../../assets/pop-up/star.svg';
import DollarIcon from '../../../assets/landing/dollar-price.svg';
import classnames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './styles.scss';

const PopularOption = () => {
  return (
    <div className={`default-price-card-option default-price-card-option--popular`}>
      <LazyLoadImage src={StarIcon} alt="star" style={{ width: '20px', height: '19px' }} />
      <p>Popular option</p>
    </div>
  );
};

const BestOption = () => {
  return (
    <div className={`default-price-card-option default-price-card-option--best`}>
      <LazyLoadImage src={DollarIcon} alt="star" style={{ width: '20px', height: '19px' }} />
      <p>Best deal</p>
    </div>
  );
};

const DefaultPriceCard = ({ skips, price, perHit, type, setTotalOrder, disabled, example = false }) => {
  const [count, setCount] = useState(1);

  const addToCart = () => {
    setTotalOrder((prev) => prev + (count * price) / 100);
  };

  const TYPE_OPTION = {
    best_deal: <BestOption />,
    popular_option: <PopularOption />,
    default: null,
  };

  return (
    <div className="default-price-card">
      {TYPE_OPTION[type]}
      <header className={classnames('price-card__header', { special__header: type !== 'default' })}>
        <h3 className="price-card__header-heading">
          <b>{new Intl.NumberFormat('en').format(skips)}</b>
          {` SKIPS`}
        </h3>
      </header>
      <div className="price-card__content-container">
        <p className={`${type !== 'default' ? '' : 'price-card__price-default'} price-card__price-value`}>
          ${price / 100}
          <sup>{price.toString().slice(-2)}</sup>
        </p>

        <p className="price-card__price-skip">${perHit} PER HIT</p>

        {!example && (
          <div className="price-card__button-container">
            <div className="price-card__count-container">
              <button onClick={addToCart}>ADD TO CART</button>
              <div className="price-card__count-inner">
                <button disabled={disabled} onClick={() => setCount((prev) => (prev - 1 <= 0 ? prev : prev - 1))}></button>
                <p>{count}</p>
                <button disabled={disabled} onClick={() => setCount((prev) => (prev < 10 ? prev + 1 : prev))}></button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DefaultPriceCard;
