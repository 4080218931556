import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editSendGrid, getSendGrid } from '../../../store/actions/sendGridActions';
import Template from './Template';
import { Spin } from 'antd';
import './styles.scss';

const SendGridTemplate = ({ id }) => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.sendGrid.templates);
  const loading = useSelector((state) => state.sendGrid.loading);

  useEffect(() => {
    dispatch(getSendGrid(id));
  }, [dispatch, id]);

  const saveHandler = async (template, key, setState) => {
    await dispatch(editSendGrid(id, { ...templates, [template]: key }));
    setState((prev) => !prev);
  };

  return (
    <div className="sendgrid">
      <Spin spinning={loading} size="large">
        <h3>SendGrid Templates</h3>
        <div className="sendgrid-template p-b-10">
          <p>Type</p> <p>Key</p> <p className="p-r-10">Actions</p>
        </div>
        {Object.keys(templates)
          .filter((item) => item !== 'id')
          .map((template, index) => (
            <Template key={index} templateType={template} $sendGridTemplateId={templates[template]} saveHandler={saveHandler} />
          ))}
      </Spin>
    </div>
  );
};

export default SendGridTemplate;
