import React, { useMemo, useState } from 'react';
import { Modal } from 'antd';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import parse from 'html-react-parser';

import './styles.scss';

const Description = ({ record }) => {
  const [show, setShow] = useState(false);
  const width = useResizeWindows();
  const modalWidth = useMemo(() => (width <= 1300 ? '1180px' : width <= 1500 ? '95%' : '80%'), [width]);

  return (
    <>
      <button className="description-btn" onClick={() => setShow(true)}>
        Show Description
      </button>

      {show ? (
        <Modal
          className="description__modal"
          bodyStyle={{
            width: width < 1280 ? '1180px' : '100%',
            padding: '20px',
            borderRadius: '24px',
            background: 'var(--content-bg)',
            height: '600px',
          }}
          visible={show}
          onCancel={() => setShow(false)}
          footer={null}
          width={modalWidth}
        >
          <div className="description__modal-header">
            <h3>
              <span>Event:</span> {record?.activity_description}
            </h3>
            <div className="description__modal-header-user">
              <h4>
                <span>Email:</span> {record?.user_email}
              </h4>
              <h4>
                <span>Name:</span> {record?.user_name}
              </h4>
            </div>
            <h4>
              <span>Date:</span> {record?.date_and_time}
            </h4>
          </div>

          <div className="description__modal-body">
            <p>{parse(record.text_short_activity_description || ' ')}</p>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default Description;
