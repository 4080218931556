import React, { useContext, useEffect, useState } from 'react';
import { whiteLabelNameText } from '../../../utils/white-label-annotations';
import AdminFileInput from '../AdminFileInput/AdminFileInput';
import ColorPicker from '../ColorPicker/ColorPicker';
import GradientPicker from '../GradientPicker/GradientPicker';
import { useDispatch, useSelector } from 'react-redux';
import { addImagesWL, createWLColors, editWLColorList, editWLSuccess, getSoloColorsWl, getSoloImagesWl } from '../../../store/actions/whiteLabelActions';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../appContext';

import MainGradient1 from '../../../assets/Example/main-gradient-1.png';
import MainGradient2 from '../../../assets/Example/main-gradient-2.png';
import MainGradient3 from '../../../assets/Example/main-gradient-3.png';

import ActionGradient1 from '../../../assets/Example/action-gradient-1.png';
import ActionGradient2 from '../../../assets/Example/action-gradient-2.png';
import HeaderInner from '../../../assets/Example/Header-inner-1.png';
import HeaderSkips from '../../../assets/Example/header-skips.png';

import MainColor1 from '../../../assets/Example/main-color.png';
import MainColor2 from '../../../assets/Example/main-color-2.png';

import MainColor1Hover from '../../../assets/Example/main-color-hover.png';
import MainColor2Hover from '../../../assets/Example/main-color-hover-2.png';

import ContentBg1 from '../../../assets/Example/content-bg-1.png';
import ContentBg2 from '../../../assets/Example/content-bg-2.png';
import ContentBg3 from '../../../assets/Example/content-bg-3.png';

import TableRow from '../../../assets/Example/table-row.png';
import TableRow2 from '../../../assets/Example/table-row-1.png';

import ActionColor from '../../../assets/Example/action-color-1.png';
import NavColor from '../../../assets/Example/navigation-color.png';

import TextColor1 from '../../../assets/Example/text-color-1.png';
import TextColor2 from '../../../assets/Example/text-color-2.png';
import TextColor3 from '../../../assets/Example/text-color-3.png';
import TextColor4 from '../../../assets/Example/text-color-4.png';

import TotalGradient1 from '../../../assets/Example/totals_gradient-1.png';
import TotalGradient2 from '../../../assets/Example/totals_gradient-2.png';

const mainGradientImages = [MainGradient1, MainGradient2, MainGradient3];
const actionGradientImages = [ActionGradient1, ActionGradient2];
const headerInnerImages = [HeaderInner];
const headerSkipsImages = [HeaderSkips];
const mainColorImages = [MainColor1, MainColor2];
const mainHoverImages = [MainColor1Hover, MainColor2Hover];
const contentBgImages = [ContentBg1, ContentBg2, ContentBg3];
const textColorImages = [TextColor1, TextColor2, TextColor3, TextColor4];
const tableRowImages = [TableRow, TableRow2];
const actionColorImages = [ActionColor];
const navColorImages = [NavColor];
const navColorHoverImages = [NavColor];
const totalsGradientImage = [TotalGradient1, TotalGradient2];

const ImageColorSettings = ({ paramsID, setSettingContent }) => {
  const { isUserSuperAdmin } = useContext(AppContext);
  const pathname = useHistory().location.pathname;
  const dispatch = useDispatch();
  const createdWLId = useSelector((state) => state.whiteLabel?.createdWLId);
  const id = pathname.includes('edit') || pathname.includes('settings') || !isUserSuperAdmin ? paramsID : createdWLId;
  const colors = useSelector((state) => state?.whiteLabel?.colors);
  const images = useSelector((state) => state?.whiteLabel?.images);

  const [gradient_1, setGradient_1] = useState('#000');
  const [gradient_2, setGradient_2] = useState('#000');
  const [gradient_3, setGradient_3] = useState('#000');

  const [header_inner_1, setHeader_inner_1] = useState('#000');
  const [header_inner_2, setHeader_inner_2] = useState('#000');
  const [header_inner_3, setHeader_inner_3] = useState('#000');

  const [header_skips_1, setHeader_skips_1] = useState('#000');
  const [header_skips_2, setHeader_skips_2] = useState('#000');
  const [header_skips_3, setHeader_skips_3] = useState('#000');

  const [action_gradient_1, setAction_gradient_1] = useState('#000');
  const [action_gradient_2, setAction_gradient_2] = useState('#000');
  const [action_gradient_3, setAction_gradient_3] = useState('#000');

  const [mainColor, setMainColor] = useState('#000');
  const [mainHoverColor, setMainHoverColor] = useState('#000');

  const [contentBg, setContentBg] = useState('#000');
  const [textColor, setTextColor] = useState('#000');

  const [tableRow, setTableRow] = useState('#000');
  const [actionColor, setActionColor] = useState('#000');

  const [navColor, setNavColor] = useState('#000');
  const [navColorActive, setNavColorActive] = useState('#000');
  const [backgroundTotals1, setBackgroundTotals1] = useState('#000');
  const [backgroundTotals2, setBackgroundTotals2] = useState('#000');
  const [backgroundTotals3, setBackgroundTotals3] = useState('#000');

  const [logo1, setLogo1] = useState('');
  const [logo2, setLogo2] = useState('');
  const [logo3, setLogo3] = useState('');
  const [background, setBackground] = useState('');
  const [sidebarLogo, setSidebarLogo] = useState('');
  const [sidebarLogoCollapsed, setSidebarLogoCollapsed] = useState('');
  const [authBackground, setAuthBackground] = useState('');
  const [authBackgroundInner, setAuthBackgroundInner] = useState('');

  useEffect(() => {
    if (id) {
      dispatch(getSoloColorsWl(id));
      dispatch(getSoloImagesWl(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(editWLSuccess());
    };
  }, [dispatch]);

  useEffect(() => {
    if (id && colors) {
      setGradient_1(colors.gradient_1 || '#000');
      setGradient_2(colors.gradient_2 || '#000');
      setGradient_3(colors.gradient_3 || '#000');
      setMainColor(colors.main_color);
      setMainHoverColor(colors.main_color_hover);
      setTableRow(colors.tr_bg);
      setTextColor(colors.text_color);
      setActionColor(colors.action_color);
      setContentBg(colors.content_bg);
      setNavColorActive(colors.navigation_text_active || '#000');
      setNavColor(colors.navigation_text || '#000');

      setHeader_inner_1(colors?.header_inner_gradient_1 || '#000');
      setHeader_inner_2(colors?.header_inner_gradient_2 || '#000');
      setHeader_inner_3(colors?.header_inner_gradient_3 || '#000');

      setHeader_skips_1(colors.header_skips_gradient_1 || '#000');
      setHeader_skips_2(colors.header_skips_gradient_2 || '#000');
      setHeader_skips_3(colors.header_skips_gradient_3 || '#000');

      setAction_gradient_1(colors.action_gradient_1 || '#000');
      setAction_gradient_2(colors.action_gradient_2 || '#000');
      setAction_gradient_3(colors.action_gradient_3 || '#000');
      setBackgroundTotals1(colors.background_totals_1 || '#000');
      setBackgroundTotals2(colors.background_totals_2 || '#000');
      setBackgroundTotals3(colors.background_totals_3 || '#000');

      const imagesHandler = (type) => {
        return images.filter((item) => item.type === type).length ? images.filter((item) => item.type === type)[0].url : '';
      };

      if (images.length) {
        setLogo1(imagesHandler(0));
        setLogo2(imagesHandler(1));
        setLogo3(imagesHandler(2));
        setBackground(imagesHandler(3));
        setSidebarLogo(imagesHandler(4));
        setSidebarLogoCollapsed(imagesHandler(5));
        setAuthBackground(imagesHandler(6));
        setAuthBackgroundInner(imagesHandler(7));
      }
    }
  }, [images, id, colors]);

  const submitHandler = async () => {
    if (id) {
      dispatch(
        editWLColorList(
          {
            main_color: mainColor,
            main_color_hover: mainHoverColor,
            action_color: actionColor,
            gradient_1,
            gradient_2,
            gradient_3,
            content_bg: contentBg,
            tr_bg: tableRow,
            text_color: textColor,
            header_inner_gradient_1: header_inner_1,
            header_inner_gradient_2: header_inner_2,
            header_inner_gradient_3: header_inner_3,
            header_skips_gradient_1: header_skips_1,
            header_skips_gradient_2: header_skips_2,
            header_skips_gradient_3: header_skips_3,
            action_gradient_1,
            action_gradient_2,
            action_gradient_3,
            navigation_text: navColor,
            navigation_text_active: navColorActive,
            background_totals_1: backgroundTotals1,
            background_totals_2: backgroundTotals2,
            background_totals_3: backgroundTotals3,
          },
          id
        )
      );
    } else {
      await dispatch(
        createWLColors(createdWLId, {
          main_color: mainColor,
          main_color_hover: mainHoverColor,
          action_color: actionColor,
          gradient_1,
          gradient_2,
          gradient_3,
          content_bg: contentBg,
          tr_bg: tableRow,
          text_color: textColor,
          header_inner_gradient_1: header_inner_1,
          header_inner_gradient_2: header_inner_2,
          header_inner_gradient_3: header_inner_3,
          header_skips_gradient_1: header_skips_1,
          header_skips_gradient_2: header_skips_2,
          header_skips_gradient_3: header_skips_3,
          action_gradient_1,
          action_gradient_2,
          action_gradient_3,
          navigation_text: navColor,
          navigation_text_active: navColorActive,
          background_totals_1: backgroundTotals1,
          background_totals_2: backgroundTotals2,
          background_totals_3: backgroundTotals3,
        })
      );
    }

    typeof logo1 !== 'string' && dispatch(addImagesWL(logo1, id));
    typeof logo2 !== 'string' && dispatch(addImagesWL(logo2, id));
    typeof logo3 !== 'string' && dispatch(addImagesWL(logo3, id));
    typeof background !== 'string' && dispatch(addImagesWL(background, id));
    typeof sidebarLogo !== 'string' && dispatch(addImagesWL(sidebarLogo, id));
    typeof sidebarLogoCollapsed !== 'string' && dispatch(addImagesWL(sidebarLogoCollapsed, id));
    typeof authBackground !== 'string' && dispatch(addImagesWL(authBackground, id));
    typeof authBackgroundInner !== 'string' && dispatch(addImagesWL(authBackgroundInner, id));

    setSettingContent('send-grid');
  };

  return (
    <>
      <div className="white-label-files">
        <AdminFileInput type={0} value={logo1} className="white-label-file" text="Main Logo" onChange={(value) => setLogo1(value)} />
        <AdminFileInput type={1} value={logo2} className="white-label-file" text="Sub Logo" onChange={(value) => setLogo2(value)} />
        <AdminFileInput type={2} value={logo3} className="white-label-file" text="Domain Logo" onChange={(value) => setLogo3(value)} />
        <AdminFileInput type={3} value={background} className="white-label-file" text="Background" onChange={(value) => setBackground(value)} />
        <AdminFileInput id={4} type={4} className="white-label-file" text="Sidebar Logo" onChange={(value) => setSidebarLogo(value)} value={sidebarLogo} />
        <AdminFileInput
          id={5}
          type={5}
          className="white-label-file"
          text="Sidebar Logo Collapsed"
          onChange={(value) => setSidebarLogoCollapsed(value)}
          value={sidebarLogoCollapsed}
        />
        <AdminFileInput
          id={6}
          type={6}
          className="white-label-file"
          text="Auth Background"
          onChange={(value) => setAuthBackground(value)}
          value={authBackground}
        />
        <AdminFileInput
          id={7}
          type={7}
          className="white-label-file"
          text="Auth Background Inner"
          onChange={(value) => setAuthBackgroundInner(value)}
          value={authBackgroundInner}
        />
      </div>

      <div className="create-white-label__body">
        <GradientPicker
          title="Main Gradient"
          gradientFirst={gradient_1}
          gradientLast={gradient_3}
          gradientSecond={gradient_2}
          setGradientFirst={setGradient_1}
          setGradientSecond={setGradient_2}
          setGradientLast={setGradient_3}
          images={mainGradientImages}
          text={whiteLabelNameText}
        />
        <GradientPicker
          title="Action Gradient"
          gradientFirst={action_gradient_1}
          gradientLast={action_gradient_3}
          gradientSecond={action_gradient_2}
          setGradientFirst={setAction_gradient_1}
          setGradientSecond={setAction_gradient_2}
          setGradientLast={setAction_gradient_3}
          images={actionGradientImages}
        />
        <GradientPicker
          title="Header Inner"
          gradientFirst={header_inner_1}
          gradientLast={header_inner_3}
          gradientSecond={header_inner_2}
          setGradientFirst={setHeader_inner_1}
          setGradientSecond={setHeader_inner_2}
          setGradientLast={setHeader_inner_3}
          images={headerInnerImages}
        />
        <GradientPicker
          title="Header Skips"
          gradientFirst={header_skips_1}
          gradientLast={header_skips_3}
          gradientSecond={header_skips_2}
          setGradientFirst={setHeader_skips_1}
          setGradientSecond={setHeader_skips_2}
          setGradientLast={setHeader_skips_3}
          images={headerSkipsImages}
        />
        <ColorPicker images={mainColorImages} title="Main Color" color={mainColor} setColor={setMainColor} />
        <ColorPicker images={mainHoverImages} title="Main Hover Color" color={mainHoverColor} setColor={setMainHoverColor} />
        <ColorPicker images={contentBgImages} title="Content Background" color={contentBg} setColor={setContentBg} />
        <ColorPicker images={textColorImages} title="Text Color" color={textColor} setColor={setTextColor} />
        <ColorPicker images={tableRowImages} title="Table row background" color={tableRow} setColor={setTableRow} />
        <ColorPicker images={actionColorImages} title="Action Color" color={actionColor} setColor={setActionColor} />
        <ColorPicker images={navColorImages} title="Navigation Color" color={navColor} setColor={setNavColor} />
        <ColorPicker images={navColorHoverImages} title="Navigation Color Active" color={navColorActive} setColor={setNavColorActive} />

        <GradientPicker
          title="Total Colors"
          gradientFirst={backgroundTotals1}
          gradientSecond={backgroundTotals2}
          gradientLast={backgroundTotals3}
          setGradientFirst={setBackgroundTotals1}
          setGradientSecond={setBackgroundTotals2}
          setGradientLast={setBackgroundTotals3}
          images={totalsGradientImage}
        />
      </div>
      <button className="create-white-label-btn" onClick={submitHandler} disabled={!id}>
        Save Changes
      </button>
    </>
  );
};

export default ImageColorSettings;
