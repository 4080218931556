import React, { useState } from 'react';

const Template = ({ $sendGridTemplateId, templateType, saveHandler }) => {
  const [state, setState] = useState(false);
  const [value, setValue] = useState($sendGridTemplateId);

  const cancelHandler = () => {
    setState(false);
    setValue($sendGridTemplateId);
  };

  return (
    <div className="sendgrid-template">
      <p>{templateType}</p>
      {state ? <input type="text" value={value} onChange={(e) => setValue(e.target.value)} /> : <p>{$sendGridTemplateId}</p>}
      {state ? (
        <div className="sendgrid-template__buttons">
          <button onClick={cancelHandler}>Cancel</button>
          <button onClick={() => saveHandler(templateType, value, setState)}>Save</button>
        </div>
      ) : (
        <div className="sendgrid-template__buttons">
          <button onClick={() => setState(true)}>Edit</button>
        </div>
      )}
    </div>
  );
};

export default Template;
