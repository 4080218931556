import {
  EDIT_SETTINGS_DUPLICATE_FAILURE,
  EDIT_SETTINGS_DUPLICATE_REQUEST,
  EDIT_SETTINGS_DUPLICATE_SUCCESS,
  SETTINGS_DUPLICATE_FAILURE,
  SETTINGS_DUPLICATE_REQUEST,
  SETTINGS_DUPLICATE_SUCCESS,
} from '../actionsTypes';

const initialState = {
  loading: false,
  error: null,
  duplicate: {},
};

const searchSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_DUPLICATE_REQUEST:
      return { ...state, loading: true };
    case SETTINGS_DUPLICATE_SUCCESS:
      return { ...state, loading: false, duplicate: action.duplicate };
    case SETTINGS_DUPLICATE_FAILURE:
      return { ...state, loading: false, error: action.error };

    case EDIT_SETTINGS_DUPLICATE_REQUEST:
      return { ...state, loading: true };
    case EDIT_SETTINGS_DUPLICATE_SUCCESS:
      return { ...state, loading: false };
    case EDIT_SETTINGS_DUPLICATE_FAILURE:
      return { ...state, loading: false, error: action.error };

    default:
      return { ...state };
  }
};

export default searchSettingsReducer;
