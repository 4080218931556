import React from 'react';

export const AppContext = React.createContext();

export function AppContextProvider({
  children,
  sideBarIsShown,
  setSideBarIsShown,
  isUserAdmin,
  isUserSuperAdmin,
  isUserBasic,
  whiteLabelId,
  collapsed,
  setCollapsed,
  isUserMainAdmin,
  brand,
  isSkipCloud,
}) {
  return (
    <AppContext.Provider
      value={{
        sideBarIsShown,
        setSideBarIsShown,
        isUserAdmin,
        isUserSuperAdmin,
        isUserBasic,
        whiteLabelId,
        collapsed,
        setCollapsed,
        isUserMainAdmin,
        brand,
        isSkipCloud,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
