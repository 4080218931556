import _ from 'lodash';
import React from 'react';
import FBIcon from '../../../assets/auth/social-fb.svg';
import InstagramIcon from '../../../assets/auth/social-insta.svg';
import YouTubeIcon from '../../../assets/auth/social-yt.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import './styles.scss';

const SocialBlock = () => {
  const { facebook, instagram, youtube } = useSelector((state) => state?.config?.config?.social_links);
  const showSocialMediaBlock = Boolean(facebook || instagram || youtube);

  return showSocialMediaBlock ? (
    <div className="social-block__adaptive">
      <h4>Follow US ON SOCIAL MEDIA</h4>
      <div className="social-link__items">
        {!_.isEmpty(facebook) && (
          <a href={facebook} target="_blank" rel="noreferrer">
            <LazyLoadImage className="social-block__auth__icon" src={FBIcon} alt="facebook" />
          </a>
        )}
        {!_.isEmpty(instagram) && (
          <a href={instagram} target="_blank" rel="noreferrer">
            <LazyLoadImage className="social-block__auth__icon" src={InstagramIcon} alt="instagram" />
          </a>
        )}
        {!_.isEmpty(youtube) && (
          <a href={youtube} target="_blank" rel="noreferrer">
            <LazyLoadImage className="social-block__auth__icon" src={YouTubeIcon} alt="you-tube" />
          </a>
        )}
      </div>
    </div>
  ) : null;
};

export default SocialBlock;
