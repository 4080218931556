import { Modal, Spin } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaginationPanelComponent } from '../../../../../Components/common/PaginationPanel/paginationPanel';
import { getActiveDiscounts } from '../../../../../store/actions/adminActions';
import { ITEMS_PER_PAGE } from '../../../../../utils/constants';
import CodeItem from './CodeItem';
import EditDiscount from './EditDIscount';
import './styles.scss';

const ActiveCodes = ({ wlId }) => {
  const [discountId, setDiscountId] = useState('');
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(null);
  const [activePagintationPage, setActivePagintationPage] = useState(1);
  const activePages = useSelector((state) => state?.admin?.activeDiscountsPages) || 1;
  const activeDiscounts = useSelector((state) => state?.admin?.activeDiscounts);
  const loading = useSelector((state) => state?.admin.activeDiscountsLoading);

  useEffect(() => {
    if (wlId) {
      dispatch(getActiveDiscounts(wlId, activePagintationPage));
    }
  }, [dispatch, activePagintationPage, wlId]);

  return (
    <Spin spinning={loading}>
      {!loading && (
        <>
          {_.isEmpty(activeDiscounts) ? (
            <div className="empty__promo">Acive codes is empty</div>
          ) : (
            <ul className="promo__codes__table">
              <li className="promo__codes__item users-list__item--heading">
                <h3>Code</h3>
                <h3>Discount Percent</h3>
                <h3>Actions</h3>
              </li>
              {activeDiscounts.map((item) => (
                <CodeItem key={item.id} item={item} isActiveCodes={true} setCodeId={setDiscountId} setOpenModal={setIsEdit} />
              ))}
            </ul>
          )}
          <PaginationPanelComponent
            pagintationPage={activePagintationPage}
            setPaginationPage={setActivePagintationPage}
            totalCount={activePages * ITEMS_PER_PAGE}
            itemsPerPage={ITEMS_PER_PAGE}
          />
        </>
      )}

      <Modal visible={isEdit} footer={null} width={'850px'} destroyOnClose={true} style={{ height: '300px' }}>
        <EditDiscount discountId={discountId} closeModal={() => setIsEdit(false)} />
      </Modal>
    </Spin>
  );
};

export default ActiveCodes;
