import React, { useContext, useEffect, useMemo, useState } from 'react';
import TextField from '../../Components/common/TextField/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { checkInviteToken, registerUser, registerUserInvites } from '../../store/actions/userActions';
import { Spin } from 'antd';
import PhoneField from '../../Components/common/PhoneField/PhoneField';
import PasswordIcon from '../../assets/auth/password.svg';
import UserNameIcon from '../../assets/auth/userName.svg';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classnames from 'classnames';
import { showNotification } from '../../App';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { apiURL } from '../../utils/config';
import { AppContext } from '../../appContext';
import { toCapitalize } from '../../utils/functions';
import moment from 'moment/moment';
import { loadFromLocalStorage, removeFromLocalStorage } from '../../utils/localStorage';
import AuthenticationLayout from '../../Components/UI/AuthenticationLayout/AuthenticationLayout';
import SocialBlock from '../../Components/UI/SocialBlock/SocialBlock';
import ReactGa from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import SelectField from '../../Components/common/SelectField/SelectField';
import FaceBookIC from '../../assets/auth/options-facebook.svg';
import FriendsIC from '../../assets/auth/options-friends.svg';
import GoogleIC from '../../assets/auth/options-google.svg';
import InstagramIC from '../../assets/auth/options-instagram.svg';
import OtherIC from '../../assets/auth/options-other.svg';
import YoutubeIC from '../../assets/auth/options-youtube.svg';
import CheckBox from '../../Components/common/Checkbox/Checkbox';
import './styles.scss';

const Register = () => {
  const { token } = useParams();
  // const emailRequest = useHistory().location.search.slice(7);
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const loading = useSelector((state) => state?.user.loading);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [hearAboutUs, setHearAboutUs] = useState('');
  const [otherSource, setOtherSource] = useState('');
  const [isAgree, setIsAgree] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);

  const [errors, setErrors] = useState({
    email: false,
    password: false,
    confirmPassword: false,
    firstName: false,
    lastName: false,
    otherSource: false,
    hearAboutUs: false,
    isAgree: false,
  });
  const [invites, setInvites] = useState(false);
  const { brand, isSkipCloud } = useContext(AppContext);

  const pathname = useHistory().location.pathname;
  const options = [
    {
      value: 'Google',
      label: 'Google',
      icon: GoogleIC,
    },
    {
      value: 'Facebook',
      label: 'Facebook',
      icon: FaceBookIC,
    },
    {
      value: 'Youtube',
      label: 'Youtube',
      icon: YoutubeIC,
    },
    {
      value: 'Instagram',
      label: 'Instagram',
      icon: InstagramIC,
    },
    {
      value: 'Friend/Colleague',
      label: 'Friend/Colleague',
      icon: FriendsIC,
    },
    {
      value: 'Other',
      label: 'Other',
      icon: OtherIC,
    },
  ];

  // whitelabel images from config
  const subLogo = useSelector((state) => state.config.config?.images?.logo_2);
  const whiteLabelName = useSelector((state) => state.config?.config?.name);
  const authBackground = useSelector((state) => state.config.config?.images?.auth_background);

  const handleSelect = (option) => {
    setHearAboutUs(option.value);
  };

  useEffect(() => {
    const userData = loadFromLocalStorage('register-user');
    if (userData) {
      setEmail(userData.email);
      setFirstName(userData.name);
      setLastName(userData.lastName);
    }

    return () => removeFromLocalStorage('register-user');
  }, []);

  useEffect(() => {
    const effect = async () => {
      const response = await dispatch(checkInviteToken(token));
      if (response) setInvites(false);
      else {
        setInvites(true);
        setEmail('');
      }
    };

    if (token) effect();
  }, [dispatch, token]);

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (emailRequest) setEmail(emailRequest);
  // }, [emailRequest]);

  const register = () => {
    const pfr = loadFromLocalStorage('promoter');

    if (
      !(email?.includes('@') && email?.length > 3) ||
      password?.trim().length <= 5 ||
      confirmPassword?.trim().length <= 5 ||
      firstName?.trim().length < 3 ||
      lastName?.trim().length < 3 ||
      hearAboutUs?.trim().length < 1 ||
      isAgree !== true
    ) {
      setErrors({
        email: !(email?.includes('@') && email?.length > 3),
        password: password?.trim().length <= 5,
        confirmPassword: password?.trim().length <= 5,
        firstName: firstName?.trim().length < 3,
        lastName: lastName?.trim().length < 3,
        otherSource: otherSource?.trim().length < 1,
        hearAboutUs: hearAboutUs?.trim().length < 1,
        isAgree: !isAgree,
      });

      showNotification('error', 'Please fill in all required fields correctly', 'Error');
      ReactPixel.trackCustom('Incomplete Registration', { firstName, lastName, email, phone });
    } else {
      dispatch(
        registerUser({
          firstName,
          lastName,
          email,
          phone,
          password: { first: password, second: confirmPassword },
          pfr: pfr ?? '',
          cameFrom: hearAboutUs !== 'Other' ? hearAboutUs : otherSource,
          receiveNotifications: sendNotification,
        })
      );
      removeFromLocalStorage('register-user');
    }
  };

  const registerInvites = () => {
    if (
      !(email?.includes('@') && email?.length > 3) ||
      password?.trim().length <= 5 ||
      confirmPassword?.trim().length <= 5 ||
      firstName?.trim().length < 3 ||
      lastName?.trim().length < 3 ||
      isAgree
    ) {
      setErrors({
        email: !(email?.includes('@') && email?.length > 3),
        password: password?.trim().length <= 5,
        confirmPassword: password?.trim().length <= 5,
        firstName: firstName?.trim().length < 3,
        lastName: lastName?.trim().length < 3,
        otherSource: otherSource?.trim().length < 1,
        hearAboutUs: hearAboutUs?.trim().length < 1,
        isAgree: !isAgree,
      });
      showNotification('error', 'Please fill in all required fields correctly', 'Error');
    } else {
      dispatch(
        registerUserInvites({
          token,
          firstName,
          lastName,
          phone,
          password: { first: password, second: confirmPassword },
        })
      );
      removeFromLocalStorage('register-user');
    }
  };

  const enterSubmitHandler = async (e) => {
    if (e.code === 'Enter') {
      await register();
    }
  };

  const textFieldButtonSize = useMemo(() => {
    let size = '440px';

    if (width < 460 && width > 370) {
      size = '350px';
    } else if (width <= 370) {
      size = '300px';
    }
    return size;
  }, [width]);

  const registerStyles = {
    margin: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${apiURL + authBackground}) 0 -2px no-repeat`,
    backgroundSize: isSkipCloud ? 'contain' : 'cover',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '100vh',
  };

  return (
    <div className="register" style={registerStyles}>
      <div className="register__body">
        {width >= 1200 && <AuthenticationLayout extendedClassName={true} />}
        <div className="register__body-form">
          <LazyLoadImage className="login__logo" draggable={false} src={`${apiURL + subLogo}`} alt="logo" />
          <h3 className="auth__title">
            Sign up to <span>{whiteLabelName}</span>
          </h3>
          <div className="login__border" />
          <div className="register__inputs">
            <Spin spinning={loading} size={'large'}>
              <div className="register__mobile">
                <div className="auth__name-block">
                  <TextField
                    icon={UserNameIcon}
                    tooltipText="Minimum length 3 characters"
                    value={firstName}
                    setState={setFirstName}
                    placeholder="First Name"
                    width={width > 1650 ? '430px' : width < 1650 ? '215px' : textFieldButtonSize}
                    error={errors.firstName}
                    parentClassName={classnames(
                      'register__input',
                      { 'register__input--correct': !errors.firstName && firstName },
                      { 'register__input--error': errors.firstName }
                    )}
                    disabled={invites}
                    onKeyPress={enterSubmitHandler}
                  />

                  <TextField
                    icon={UserNameIcon}
                    tooltipText="Minimum length 3 characters"
                    value={lastName}
                    setState={setLastName}
                    placeholder="Last Name"
                    width={width > 1650 ? '430px' : width < 1650 ? '215px' : textFieldButtonSize}
                    error={errors.lastName}
                    parentClassName={classnames(
                      'register__input',
                      { 'register__input--correct': !errors.lastName && lastName },
                      { 'register__input--error': errors.lastName }
                    )}
                    disabled={invites}
                    onKeyPress={enterSubmitHandler}
                  />
                </div>

                <TextField
                  tooltipText="Format: example@thanks.com"
                  icon={UserNameIcon}
                  width={width > 1650 ? '430px' : textFieldButtonSize}
                  value={email}
                  setState={setEmail}
                  type="text"
                  placeholder="Email"
                  error={errors.email}
                  parentClassName={classnames(
                    'register__input',
                    { 'register__input--correct': !errors.email && email },
                    { 'register__input--error': errors.email }
                  )}
                  onKeyPress={enterSubmitHandler}
                />
                <TextField
                  tooltipText="Minimum length 6 characters"
                  value={password}
                  setState={setPassword}
                  type="password"
                  width={width > 1650 ? '430px' : textFieldButtonSize}
                  placeholder="Password"
                  error={errors.password}
                  icon={PasswordIcon}
                  parentClassName={classnames(
                    'register__input',
                    { 'register__input--correct': !errors.password && password },
                    { 'register__input--error': errors.password }
                  )}
                  onKeyPress={enterSubmitHandler}
                />
                <TextField
                  tooltipText="Minimum length 6 characters"
                  value={confirmPassword}
                  setState={setConfirmPassword}
                  type="password"
                  width={width > 1650 ? '430px' : textFieldButtonSize}
                  placeholder="Confirm Password"
                  error={errors.confirmPassword}
                  icon={PasswordIcon}
                  parentClassName={classnames(
                    'register__input',
                    'm-b-15',
                    { 'register__input--correct': !errors.password && password },
                    { 'register__input--error': errors.password }
                  )}
                  disabled={invites}
                  onKeyPress={enterSubmitHandler}
                />

                <SelectField options={options} onSelect={handleSelect} isError={errors.hearAboutUs} />
                {hearAboutUs === 'Other' && (
                  <TextField
                    tooltipText="Maxim length 200 characters"
                    parentClassName={classnames(
                      'register__input',
                      { 'register__input--correct': !errors.otherSource && otherSource },
                      { 'register__input--error': errors.otherSource }
                    )}
                    value={otherSource}
                    setState={setOtherSource}
                    placeholder="How did you hear about us?"
                  />
                )}

                <div className="policy-wrapper">
                  <CheckBox text="" checked={isAgree} setChecked={setIsAgree} className="login-check" id={99} />
                  <div className={classnames({ 'is-agree_error': errors.isAgree })}>
                    I agree to the
                    <a href="https://www.skipcloud.io/terms-conditions" rel="noreferrer" target="_blank" className="m-l-5">
                      Terms and Conditions
                    </a>
                    <a href="https://www.skipcloud.io/privacy-policy" rel="noreferrer" target="_blank" className="m-l-5">
                      & Privacy Policy{'\n'}
                    </a>{' '}
                    provided by {''} SkipCloud.
                  </div>
                </div>
                <PhoneField value={phone} setState={setPhone} parentClassName={classnames('register__input m-b-10 m-t-10')} disabled={invites} />

                <div className="policy-wrapper marketing">
                  <p className="marketing-messages__text">Account Preferences</p>
                  <div className="agreed-terms__block agree_2">
                    <CheckBox text="" checked={sendNotification} setChecked={setSendNotification} className="login-check" id={97} />
                    <div>
                      I agree to receive marketing text messages which may be generated by an automated dialer to the phone number provided above. Consent not
                      required to make a purchase. Message frequency for alerts varies. Msg & Data Rates May Apply. Unsubscribe at any time by replying STOP or
                      click the unsubscribe link (where available). You may also change your selection after making your account by going to Account Management,
                      and then Notification Settings.
                    </div>
                  </div>
                </div>
                {/* {!isSkipCloud ? (
                <div className="policy-wrapper marketing">
                  <p className="marketing-messages__text">Account Preferences</p>
                  <div className="agreed-terms__block agree_2">
                    <CheckBox text="" checked={sendNotification} setChecked={setSendNotification} className="login-check" id={97} />
                    <div>
                      I agree to receive marketing text messages which may be generated by an automated dialer to the phone number provided above. Consent not
                      required to make a purchase. Message frequency for alerts varies. Msg & Data Rates May Apply. Unsubscribe at any time by replying STOP or
                      click the unsubscribe link (where available). You may also change your selection after making your account by going to Account Management,
                      and then Notification Settings.
                    </div>
                  </div>
                </div>
              ) : (
                <div className="agreed-terms__block">
                  By creating an account, you confirm that you have read, understood, and agree to our
                  <Link href="/privacy-policy" rel="noreferrer" target="_blank" className="m-l-5">
                    Privacy Policy
                  </Link>
                  and
                  <br />
                  <Link href="/terms-conditions" rel="noreferrer" target="_blank" className="m-l-5">
                    Terms and Conditions
                  </Link>
                </div> //Old
              )} */}
                {/* By creating an account, you confirm that you have read, understood, and agree to our Terms and Conditions and Privacy Policy */}
              </div>
            </Spin>

            {isSkipCloud ? (
              <div className="signup__container">
                <button className="auth__button m-b-10" onClick={token ? registerInvites : register} disabled={!isAgree}>
                  Sign Up
                </button>
              </div>
            ) : (
              <div className="signup__container">
                <button className="auth__button m-b-10" onClick={token ? registerInvites : register}>
                  Sign Up
                </button>
              </div>
            )}

            <div className="login__border m-b-25" />
            <p className="register__login">
              Already have an account?
              <Link className="login__link" to="/login">
                Log In!
              </Link>
            </p>
            <div className="login__border" />
            {width <= 1200 && <SocialBlock />}
          </div>
        </div>
      </div>
      {hearAboutUs !== 'Other' && (
        <div className="auth__footer">
          <div className="auth__footer__outer">
            <p>
              All rights reserved. {toCapitalize(brand)}, LLC. {moment(new Date()).format('YYYY')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
