import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LazyLoad from 'react-lazyload';
import { Button, notification, Result, Spin } from 'antd';
import { USER_ROLES } from './utils/constants';
import { getConfig } from './store/actions/configActions';
import Layout from './Components/UI/Layout/Layout';
import Login from './Pages/Login/Login';
import ContactUsPage from './Pages/ContactUs/contactUsPage';
import Register from './Pages/Register/Register';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import FAQ from './Pages/FAQ/FAQ';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import Dashboard from './Pages/Dashboard/Dashboard';
import DataMatching from './Pages/DataMatching/DataMatching';
import MyResults from './Pages/MyResults/MyResults';
import ResultsLibrary from './Pages/ResultsLibrary/ResultsLibrary';
import AccountManagement from './Pages/AccountManagement/AccountManagement';
import { AppContextProvider } from './appContext';
import AdminMainComponent from './Pages/Admin panel/AdminMain';
import LandingPageComponent from './Pages/Landing/Landing';
import AddMoreCredits from './Pages/AddMoreCredits/AddMoreCredits';
import DataMatchingBusiness from './Pages/DataMatchingBusiness/DataMatchingBusiness';
import Pricing from './Pages/Pricing/Pricing';
import FAQS from "./Pages/FAQ'S/FAQ'S";
import SuperAdmin from './Pages/Admin panel/SuperAdmin';
import WhiteLabelNavigation from './Components/admin/Navigation/WhiteLabelNavigation';
import AboutUs from './Pages/AboutUs/AboutUs';
import history from './utils/history';
import LoginAsUser from './Pages/LoginAsUser/LoginAsUser';
import { GoogleOAuthProvider } from '@react-oauth/google';
import EditWLColor from './Components/admin/EditWLColor/EditWLColor';
import ReactGa from 'react-ga4';
import VerifyEmail from './Pages/VerifyEmail/VerifyEmail';
import ReactPixel from 'react-facebook-pixel';
import './styles/main.scss';
import { Helmet } from 'react-helmet';
import HighLevelForm from "./Pages/HighLevelForm/HighLevelForm";

export const showNotification = (type, text, title, duration = 3, placement = 'topRight') => {
  notification[type]({
    message: title,
    description: text,
    className: 'notification-border',
    duration,
    placement,
  });
};

export const ProtectedRoute = ({ isAllowed, redirectTo, ...props }) => {
  if (isAllowed) {
    return (
      <LazyLoad>
        <Route {...props} />
      </LazyLoad>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/dashboard',
        state: { from: props.location },
      }}
    />
  );
};

const PrivateRoute = ({ children, login, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      login ? (
        <Layout>{children}</Layout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const App = () => {
  const pathname = useHistory().location.pathname;
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const config = useSelector((state) => state?.config?.config);
  const error = useSelector((state) => state.config.error);
  const loading = useSelector((state) => state?.config?.loading);
  const userRoles = useSelector((state) => state?.user?.profile?.roles);
  const isUserAdmin = userRoles?.includes(USER_ROLES.admin);
  const isUserSuperAdmin = userRoles?.includes(USER_ROLES.super);
  const isUserBasic = userRoles?.includes(USER_ROLES.basic);
  const isUserMainAdmin = userRoles?.includes(USER_ROLES.main);
  const googleKey = useSelector((state) => state.config.config?.google_auth_key);
  const isSkipCloud = config?.white_label_id === 1;
  const [sideBarIsShown, setSideBarIsShown] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  useEffect(() => {
    if (config?.google_analytics) {
      ReactGa.initialize(config?.google_analytics);
    }
  }, [config?.google_analytics]);

  useEffect(() => {
    if (config?.facebook_pixel) {
      ReactPixel.init(config?.facebook_pixel);
      ReactPixel.track('pageView', { pathname, user });
    }
  }, [pathname, user, config?.facebook_pixel]);

  if (error) {
    return (
      <div className="forbidden">
        <Result className="m-r-auto m-l-auto  m-t-auto  m-b-auto" status="500" subTitle="Sorry, something went wrong. Refresh the page." title="Error" />
      </div>
    );
  }

  return (
    <GoogleOAuthProvider clientId={googleKey}>
      {!loading && config?.first_promoter && (
        <Helmet>
          <script>
            {`(function(w) {
            w.fpr =
              w.fpr ||
              function() {
                w.fpr.q = w.fpr.q || [];
                w.fpr.q[arguments[0] == 'set' ? 'unshift' : 'push'](arguments);
              };
          })(window)
            fpr('init', {cid: '${config?.first_promoter}'});
            fpr('click');
            `}
          </script>
        </Helmet>
      )}
      <AppContextProvider
        sideBarIsShown={sideBarIsShown}
        setSideBarIsShown={setSideBarIsShown}
        isUserAdmin={isUserAdmin}
        isUserSuperAdmin={isUserSuperAdmin}
        isUserBasic={isUserBasic}
        whiteLabelId={!loading && config.white_label_id}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        isUserMainAdmin={isUserMainAdmin}
        brand={config.name}
        isSkipCloud={isSkipCloud}
      >
        <Switch>
          {loading && (
            <>
              <div className={loading ? 'main__spinner' : ''}>
                <Spin spinning size="large" />
              </div>
            </>
          )}

          <ProtectedRoute exact path="/" component={!isSkipCloud ? Login : LandingPageComponent} isAllowed={!user} redirectTo={'/dashboard'} />
          <ProtectedRoute exact path="/login" isAllowed={!user} component={Login} />
          <ProtectedRoute path="/login/user/:token" exact isAllowed={!user} component={LoginAsUser} />
          <ProtectedRoute exact path="/sign-up" component={Register} isAllowed={!user} />
          <ProtectedRoute path="/sign-up/:token" component={Register} isAllowed={!user} />
          <ProtectedRoute exact path="/verify-email" component={VerifyEmail} isAllowed={!user} />
          <ProtectedRoute path="/forgot-password" component={ForgotPassword} isAllowed={!user} />
          <ProtectedRoute path="/reset-password/:token" component={ResetPassword} isAllowed={!user} />
          <ProtectedRoute exact path="/faq" component={!isSkipCloud ? Login : FAQ} isAllowed={!user} />
          <ProtectedRoute exact path="/contact" component={!isSkipCloud ? Login : ContactUsPage} isAllowed={!user} />
          <ProtectedRoute exact path="/pricing" component={!isSkipCloud ? Login : Pricing} isAllowed={!user} />
          <ProtectedRoute exact path="/about-us" component={!isSkipCloud ? Login : AboutUs} isAllowed={!user} />
          <ProtectedRoute exact path="/sign-up-highlevel" component={HighLevelForm} isAllowed={!user} />
          <PrivateRoute login={user}>
            <Switch>
              <ProtectedRoute exact path="/dashboard" component={Dashboard} isAllowed={user} />
              <ProtectedRoute path="/faqs" component={FAQS} isAllowed={user} />
              <ProtectedRoute path="/profile" isAllowed={user} component={AccountManagement} />
              <ProtectedRoute path="/add-more-skips" component={AddMoreCredits} isAllowed={user} />
              <ProtectedRoute path="/admin" component={AdminMainComponent} isAllowed={isUserMainAdmin || isUserAdmin || isUserBasic} />
              <ProtectedRoute path="/super-admin" component={SuperAdmin} isAllowed={isUserSuperAdmin || isUserMainAdmin} />
              <ProtectedRoute path="/white-label/:id" component={WhiteLabelNavigation} isAllowed={isUserSuperAdmin || isUserMainAdmin} />
              <ProtectedRoute exact path="/library" component={ResultsLibrary} isAllowed={user} />
              <ProtectedRoute exact path="/library/:id" component={MyResults} isAllowed={user} />
              <ProtectedRoute path="/library/business/:id" component={MyResults} isAllowed={user} />
              <ProtectedRoute path="/data-matching/people/:id" component={DataMatching} isAllowed={user} />
              <ProtectedRoute path="/data-matching/business/:id" component={DataMatchingBusiness} isAllowed={user} />
              <ProtectedRoute exact path="/edit/ui/:id" component={EditWLColor} isAllowed={isUserMainAdmin} />
              <Route
                render={() => (
                  <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={
                      <Button type="primary" onClick={() => history.push('/dashboard')}>
                        Back Home
                      </Button>
                    }
                  />
                )}
              />
            </Switch>
          </PrivateRoute>
          <Redirect to={'/'} />
        </Switch>
      </AppContextProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
