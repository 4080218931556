import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCertificate, getSoloSettingsWl } from '../../../store/actions/whiteLabelActions';
import { sslCertificate, sslCertificateKey } from '../../../utils/white-label-annotations';
import SslField from '../SSLField/SSLField';
import { useHistory } from 'react-router-dom';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { AppContext } from '../../../appContext';

const CertificateSettings = ({ paramsID }) => {
  const { isUserSuperAdmin } = useContext(AppContext);
  const pathname = useHistory().location.pathname;
  const dispatch = useDispatch();
  const whiteLabel = useSelector((state) => state.whiteLabel.whiteLabel);
  const createdWLId = useSelector((state) => state.whiteLabel?.createdWLId);
  const id = pathname.includes('edit') || pathname.includes('settings') || !isUserSuperAdmin ? paramsID : createdWLId;
  const [certificateSSL, setCertificateSSL] = useState('');
  const [certificateKey, setCertificateKey] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (id) dispatch(getSoloSettingsWl(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (whiteLabel && id) {
      setCertificateSSL(whiteLabel?.certificates?.certificate || ' ');
      setCertificateKey(whiteLabel?.certificates?.cert_key || ' ');
    }
  }, [whiteLabel, id]);

  const confirmEditHandler = async () => {
    await dispatch(addCertificate({ certificate: certificateSSL.trim(), cert_key: certificateKey.trim() }, id));
    setShow(false);
  };

  const closeConfirmModal = () => {
    setShow(false);
  };

  return (
    <div className="white-label__fields">
      <SslField
        toolTipText={certificateSSL}
        text={sslCertificate}
        placeholder="SSL Certificate"
        onChange={(e) => setCertificateSSL(e.target.value)}
        value={certificateSSL}
        showSSLText
        sslText="CERTIFICATE"
      />
      <SslField
        toolTipText={sslCertificateKey}
        text={sslCertificateKey}
        placeholder="Key Certificate"
        onChange={(e) => setCertificateKey(e.target.value)}
        value={certificateKey}
        showSSLText
        sslText="PRIVATE KEY"
      />

      <button className="create-white-label-btn" onClick={() => setShow(true)} disabled={!id}>
        Create White Label
      </button>

      <ConfirmModal text={'Are you sure you want to apply the changes ?'} show={show} onCancel={closeConfirmModal} editHandler={confirmEditHandler} />
    </div>
  );
};

export default CertificateSettings;
