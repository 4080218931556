export const conversion = (number) => {
  return new Intl.NumberFormat('en-IN').format(number);
};

export const compareNumbers = (a, b) => {
  if (a.price > b.price) {
    return 1;
  }
  if (a.price < b.price) {
    return -1;
  }
  return 0;
};

export const getSkeletonRow = (length = 0) => {
  return Array.from({ length: 6 - length }, (_) => ({ skeleton: true }));
};

export const sliceString = (width, name = '', sliceXL = 35, sliceL = 20, sliceS = 15) => {
  return width >= 1800
    ? name.length > sliceXL
      ? name.slice(0, sliceXL) + '...'
      : name
    : width >= 1600
    ? name.length > sliceL
      ? name.slice(0, sliceL) + '...'
      : name
    : name.length > sliceS
    ? name.slice(0, sliceS) + '...'
    : name;
};

export const makeRange = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

export const roleFiltered = (roles) => {
  if (roles.length > 1) {
    let basicAdmin;
    let admin;
    let mainAdmin;
    let superAdmin;

    roles.forEach((item) => {
      if (item === 'ROLE_BASIC_ADMIN') basicAdmin = 'Basic Admin';
      if (item === 'ROLE_ADMIN') admin = 'Admin';
      if (item === 'ROLE_MAIN_ADMIN') mainAdmin = 'Main Admin';
      if (item === 'ROLE_SUPER_ADMIN') superAdmin = 'Super Admin';
    });

    return superAdmin ? [superAdmin] : mainAdmin ? [mainAdmin] : admin ? [admin] : basicAdmin ? [basicAdmin] : 'Error';
  } else {
    return roles.map((item) => {
      if (item === 'ROLE_USER') return 'User';
      else if (item === 'ROLE_ADMIN') return 'Admin';
      else if (item === 'ROLE_BASIC_ADMIN') return 'Basic Admin';
      else if (item === 'ROLE_SUPER_ADMIN') return 'Super Admin';
      else if (item === 'ROLE_MAIN_ADMIN') return 'Main Admin';
      return '';
    });
  }
};

export const filesHandler = (type, files) => {
  const array = [];

  const filesArray = Array.isArray(files) ? files : [files];

  filesArray.forEach((item) => {
    const filesFilter = type ? item.person_files : item.business_files;
    filesFilter.forEach((file) => {
      array.push(file.id);
    });
  });

  return array;
};

export const toCapitalize = (string) => {
  return string ? string?.charAt(0).toUpperCase() + string?.slice(1) : '';
};

export const isBoolean = (val) => 'boolean' === typeof val;

export const includesPath = (pathName, path) => pathName.includes(path);
