import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoResult from '../../../../../../Components/common/NoResult/NoResult';
import { PaginationPanelComponent } from '../../../../../../Components/common/PaginationPanel/paginationPanel';
import { getPersonFiles } from '../../../../../../store/actions/adminActions';
import { ITEMS_PER_PAGE } from '../../../../../../utils/constants';
import { sliceString } from '../../../../../../utils/functions';
import useResizeWindows from '../../../../../../utils/hooks/useResizeWindows';
import _ from 'lodash';

const PersonFiles = ({ wlID, userID, loading }) => {
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const personFiles = useSelector((state) => state.admin?.personFiles);
  const pages = useSelector((state) => state.admin?.personFilesPages);
  const [paginationPage, setPaginationPage] = useState(1);
  useEffect(() => {
    if (wlID) {
      dispatch(getPersonFiles(wlID, userID, paginationPage));
    }
  }, [dispatch, wlID, userID, paginationPage]);

  const filesJSX = (
    <>
      <ul className="file-statistic">
        <li className="file-statistic__item file-statistic__item--heading">
          <h3>File Name</h3>
          <h3>Email</h3>
          <h3>Total Hits</h3>
          <h3>Total Records</h3>
          <h3>Request Time</h3>
          <h3>Status</h3>
        </li>
        {personFiles.map((file, index) => {
          return (
            <li className="file-statistic__item" key={index}>
              <Tooltip placement="topLeft" title={file?.original_name}>
                <span className="file-name">{sliceString(width, file?.original_name, 45, 40, 30)}</span>
              </Tooltip>
              <span>{file?.user_email}</span>
              <span>{file?.total_hits}</span>
              <span>{file?.total_records}</span>
              <span>{file?.request_time}</span>
              <span className={`${file?.status === 'completed' ? 'completed' : 'expired'}`}>{file?.status}</span>
            </li>
          );
        })}
      </ul>
      <PaginationPanelComponent
        pagintationPage={paginationPage}
        setPaginationPage={setPaginationPage}
        totalCount={pages * ITEMS_PER_PAGE}
        itemsPerPage={ITEMS_PER_PAGE}
      />
    </>
  );
  return <>{!loading && <>{!_.isEmpty(personFiles) ? <>{filesJSX}</> : <NoResult text="Person Files is Empty" />}</>}</>;
};

export default PersonFiles;
