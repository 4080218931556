import _ from 'lodash';
import React, { useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { apiURL } from '../../../utils/config';
import FBIcon from '../../../assets/auth/social-fb.svg';
import InstagramIcon from '../../../assets/auth/social-insta.svg';
import YouTubeIcon from '../../../assets/auth/social-yt.svg';
import { AppContext } from '../../../appContext';
import classNames from 'classnames';
import './styles.scss';

const AuthenticationLayout = ({ extendedClassName }) => {
  const loginLogo = useSelector((state) => state.config.config?.images?.logo_1);
  const { facebook, instagram, youtube } = useSelector((state) => state?.config?.config?.social_links);
  const showSocialMediaBlock = Boolean(facebook || instagram || youtube);
  const { isSkipCloud } = useContext(AppContext);

  return (
    <div className="auth__layout-bg">
      {isSkipCloud ? (
        <a href="https://www.skipcloud.io/">
          <img src={apiURL + loginLogo} alt="Logo" className="logo-img" />
        </a>
      ) : (
        <img src={apiURL + loginLogo} alt="Logo" className="logo-img" />
      )}
      {showSocialMediaBlock && (
        <div className={classNames('social-block__auth', { 'extended-class': extendedClassName })}>
          <h4>Follow US ON SOCIAL MEDIA</h4>
          <div className="social-link__items">
            {!_.isEmpty(instagram) && (
              <a href={instagram} target="_blank" rel="noreferrer">
                <LazyLoadImage className="social-block__auth__icon" src={InstagramIcon} alt="instagram" />
              </a>
            )}
            {!_.isEmpty(facebook) && (
              <a href={facebook} target="_blank" rel="noreferrer">
                <LazyLoadImage className="social-block__auth__icon" src={FBIcon} alt="facebook" />
              </a>
            )}
            {!_.isEmpty(youtube) && (
              <a href={youtube} target="_blank" rel="noreferrer">
                <LazyLoadImage className="social-block__auth__icon" src={YouTubeIcon} alt="you-tube" />
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthenticationLayout;
