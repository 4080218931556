import axiosApi from '../../utils/axios';
import fileDownload from 'js-file-download';
import { showNotification } from '../../App';
import {
  SKIPTRACE_HISTORY_ALL_FAILURE,
  SKIPTRACE_HISTORY_ALL_REQUEST,
  SKIPTRACE_HISTORY_ALL_SUCCESS,
  TRANSACTION_HISTORY_ALL_FAILURE,
  TRANSACTION_HISTORY_ALL_REQUEST,
  TRANSACTION_HISTORY_ALL_SUCCESS,
  USER_ACTIVITY_HISTORY_ALL_FAILURE,
  USER_ACTIVITY_HISTORY_ALL_REQUEST,
  USER_ACTIVITY_HISTORY_ALL_SUCCESS,
} from '../actionsTypes';

const getPaymentStatisticsRequest = () => ({ type: TRANSACTION_HISTORY_ALL_REQUEST });
const getPaymentStatisticsSuccess = (transaction) => ({ type: TRANSACTION_HISTORY_ALL_SUCCESS, transaction });
const getPaymentStatisticsFailure = (error) => ({ type: TRANSACTION_HISTORY_ALL_FAILURE, error });

const getActivityStatisticsRequest = () => ({ type: USER_ACTIVITY_HISTORY_ALL_REQUEST });
const getActivityStatisticsSuccess = (activity) => ({ type: USER_ACTIVITY_HISTORY_ALL_SUCCESS, activity });
const getActivityStatisticsFailure = (error) => ({ type: USER_ACTIVITY_HISTORY_ALL_FAILURE, error });

const getSkiptraceStatisticsRequest = () => ({ type: SKIPTRACE_HISTORY_ALL_REQUEST });
const getSkiptraceStatisticsSuccess = (skiptrace) => ({ type: SKIPTRACE_HISTORY_ALL_SUCCESS, skiptrace });
const getSkiptraceStatisticsFailure = (error) => ({ type: SKIPTRACE_HISTORY_ALL_FAILURE, error });

export const getTransactionSuperAdmin = (id, startDate, endDate, created = false, page = 1, userId = '') => {
  return async (dispatch) => {
    try {
      if (created) {
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-transaction-history-report/?start_date=${startDate}&end_date=${endDate}&create_file=${created}`,
          { responseType: 'blob' }
        );
        fileDownload(response.data, 'report.csv');
      } else {
        dispatch(getPaymentStatisticsRequest());
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-transaction-history-report/?start_date=${startDate}&end_date=${endDate}&create_file=${created}&page=${page}&userId=${userId}`
        );
        dispatch(getPaymentStatisticsSuccess(response.data));
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getPaymentStatisticsFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getPaymentStatisticsFailure({ global: 'No internet' }));
      }
    }
  };
};

export const getUserActivitySuperAdmin = (id, startDate, endDate, create_file = false, page, type = '', userId = '') => {
  return async (dispatch) => {
    try {
      if (create_file) {
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-users-activity-history-report?start_date=${startDate}&end_date=${endDate}&create_file=${create_file}&order=id&sort=desc&userId=${userId}&activityType=${type}`,
          { responseType: 'blob' }
        );
        fileDownload(response.data, 'report-user-activity.csv');
      } else {
        dispatch(getActivityStatisticsRequest());
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-users-activity-history-report?start_date=${startDate}&end_date=${endDate}&create_file=${create_file}&page=${page}&order=id&sort=desc&userId=${userId}&activityType=${type}`
        );
        dispatch(getActivityStatisticsSuccess(response.data));
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getActivityStatisticsFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getActivityStatisticsFailure({ global: 'No internet' }));
      }
    }
  };
};

export const getSkiptraceSuperAdmin = (id, startDate, endDate, created = false, page, userId = '') => {
  return async (dispatch) => {
    try {
      if (created) {
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-skiptrace-history-report/?start_date=${startDate}&end_date=${endDate}&create_file=${created}`,
          { responseType: 'blob' }
        );
        fileDownload(response.data, 'report-skiptrace-history.csv');
      } else {
        dispatch(getSkiptraceStatisticsRequest());
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-skiptrace-history-report/?start_date=${startDate}&end_date=${endDate}&create_file=${created}&page=${page}&userId=${userId}`
        );
        dispatch(getSkiptraceStatisticsSuccess(response.data));
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getSkiptraceStatisticsFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getSkiptraceStatisticsFailure({ global: 'No internet' }));
      }
    }
  };
};
