import React, { useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import CheckBox from '../Checkbox/Checkbox';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MasterCard from '../../../assets/payment/master-card.svg';
import Visa from '../../../assets/payment/visa.svg';
import Discover from '../../../assets/payment/discover.svg';
import Amex from '../../../assets/payment/amex.svg';
import { useOptions } from '../../../utils/hooks/useOption';
import Stripe from '../../../assets/payment/stripe.svg';
import { activeDiscount } from '../../../store/actions/paymentActions';
import { useDispatch } from 'react-redux';
import DiscountHelper from './DiscountHelper/DiscountHelper';

const StripeForm = ({
  cards = [],
  setStripeModal,
  cardHolderName,
  setCardHolderName,
  savePaymentDetails,
  setSavePaymentDetails,
  error,
  cart,
  setCart,
  setCodeActive,
}) => {
  const dispatch = useDispatch();
  const option = useOptions();
  const paymentType = [MasterCard, Visa, Discover, Amex];
  const [errorDiscount, setErrorDiscount] = useState(false);
  const [code, setCode] = useState('');
  const [codeArray, setCodeArray] = useState([]);

  const activeDiscountHandler = async () => {
    const priceIds = cart.map((item) => item.id);

    const response = await dispatch(activeDiscount({ code, priceIds }));
    if (response) {
      const array = [];

      for (let i = 0; i < cart.length; i++) {
        for (let j = 0; j < response.length; j++) {
          if (cart[i].id === response[j].id) {
            cart[i].price = response[j].price;
            cart[i].discount_id = response[j].discount_id;
          }
        }
        array.push(cart[i]);
      }

      setCart(array);
      setCodeArray(response);

      setErrorDiscount(false);
      setCodeActive(true);
    } else {
      setErrorDiscount(true);
      setCodeActive(false);
    }
  };

  return (
    <>
      <div className="stripe-modal__body-item-stripe">
        {cards.length ? (
          <button className="stripe-modal__back" onClick={() => setStripeModal(false)}>
            Back to cards
          </button>
        ) : null}
        <h2>
          Please enter your <span>Payment Details</span>
        </h2>
        <h3>
          <span>You're almost done</span> Enter your card details bellow to purchase your skips and continue your search.
        </h3>
        <form className="m-b-25">
          <label>
            <span>Cardholder Name</span>
            <input
              className="stripe-modal__field"
              placeholder="John Doe"
              value={cardHolderName}
              onChange={(e) => setCardHolderName(e.target.value)}
              style={{ borderColor: error ? '#FF4C4C' : null }}
              required
            />
          </label>
          <div className="flex flex-align-items-center m-b-10">
            <label className="stripe-label">
              <span>Card Number </span>
              <CardNumberElement className="stripe-modal__field p-t-20" options={option} placeholder="4242 4242 4242 4242" />
            </label>
            <label className="stripe-label">
              <span> Exp. Date </span>
              <CardExpiryElement className="stripe-modal__field p-t-20" options={option} placeholder="Custom CVC placeholder" />
            </label>
            <label className="stripe-label">
              <span> CVC Code </span>
              <CardCvcElement className="stripe-modal__field p-t-20" options={option} />
            </label>
          </div>
          <div className="flex  flex-justify-content-between">
            <CheckBox
              checked={savePaymentDetails}
              setChecked={setSavePaymentDetails}
              className="stripe-checkbox m-r-10"
              text="Save my payment details for future purchases"
            />
            <div className="discount-code">
              <div className="discount-code__field">
                <input value={code} onChange={(e) => setCode(e.target.value)} type="text" placeholder="Enter coupon/promotional code here" />
              </div>
              {codeArray.length ? <DiscountHelper cards={codeArray} /> : null}
              <button type="button" onClick={activeDiscountHandler}>
                Apply code
              </button>

              {errorDiscount && <p>*Error: Code expired</p>}
            </div>
          </div>
        </form>
        <div className="flex flex-align-items-center">
          {paymentType.map((icon, index) => (
            <LazyLoadImage key={index} src={icon} alt="payment" style={{ width: '75px', height: '75px' }} />
          ))}
        </div>
      </div>

      <div className="stripe-modal__footer">
        <p>Powered by</p>
        <LazyLoadImage src={Stripe} alt="stripe" className="stripe-modal-img" />
      </div>
    </>
  );
};

export default StripeForm;
