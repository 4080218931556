import {
  EDIT_BUSINESS_FILE_HEADER_FAILURE,
  EDIT_BUSINESS_FILE_HEADER_REQUEST,
  EDIT_BUSINESS_FILE_HEADER_SUCCESS,
  EDIT_FILE_HEADER_FAILURE,
  EDIT_FILE_HEADER_REQUEST,
  EDIT_FILE_HEADER_SUCCESS,
  POST_BUSINESS_FILES_FAILURE,
  POST_BUSINESS_FILES_REQUEST,
  POST_BUSINESS_FILES_SUCCESS,
  POST_BUSINESS_PROCESS_FAILURE,
  POST_BUSINESS_PROCESS_REQUEST,
  POST_BUSINESS_PROCESS_SUCCESS,
  POST_BUSINESS_SHEET_FAILURE,
  POST_BUSINESS_SHEET_REQUEST,
  POST_BUSINESS_SHEET_SUCCESS,
  POST_FILES_FAILURE,
  POST_FILES_REQUEST,
  POST_FILES_SUCCESS,
  POST_PROCESS_FAILURE,
  POST_PROCESS_REQUEST,
  POST_PROCESS_SUCCESS,
  POST_SHEET_FAILURE,
  POST_SHEET_REQUEST,
  POST_SHEET_SUCCESS,
  REMOVE_BUSINESS_FILES,
  REMOVE_FILES,
} from '../actionsTypes';
import axiosApi from '../../utils/axios';
import fileDownload from 'js-file-download';
import { showNotification } from '../../App';
import { filesHandler } from '../../utils/functions';
import { changeProcessStatus, getBusinessProcessesHistory } from './processActions';
import history from '../../utils/history';

export const postFilesRequest = () => ({ type: POST_FILES_REQUEST });
export const postFilesSuccess = (files, rows, id) => ({ type: POST_FILES_SUCCESS, files, rows, id });
const postFilesFailure = (error) => ({ type: POST_FILES_FAILURE, error });

const postSheetRequest = () => ({ type: POST_SHEET_REQUEST });
const postSheetSuccess = (sheet, rows) => ({ type: POST_SHEET_SUCCESS, sheet, rows });
const postSheetFailure = (error) => ({ type: POST_SHEET_FAILURE, error });

const postProcessRequest = () => ({ type: POST_PROCESS_REQUEST });
const postProcessSuccess = () => ({ type: POST_PROCESS_SUCCESS });
const postProcessFailure = (error) => ({ type: POST_PROCESS_FAILURE, error });

const editFileHeaderRequest = () => ({ type: EDIT_FILE_HEADER_REQUEST });
const editFileHeaderSuccess = (sheet, rows) => ({ type: EDIT_FILE_HEADER_SUCCESS, sheet, rows });
const editFileHeaderFailure = (error) => ({ type: EDIT_FILE_HEADER_FAILURE, error });

export const removeFiles = () => ({ type: REMOVE_FILES });

export const postBusinessFilesRequest = () => ({ type: POST_BUSINESS_FILES_REQUEST });
export const postBusinessFilesSuccess = (files, rows, id) => ({ type: POST_BUSINESS_FILES_SUCCESS, files, rows, id });
const postBusinessFilesFailure = (error) => ({ type: POST_BUSINESS_FILES_FAILURE, error });

const postBusinessSheetRequest = () => ({ type: POST_BUSINESS_SHEET_REQUEST });
const postBusinessSheetSuccess = (sheet, rows) => ({ type: POST_BUSINESS_SHEET_SUCCESS, sheet, rows });
const postBusinessSheetFailure = (error) => ({ type: POST_BUSINESS_SHEET_FAILURE, error });

const postBusinessProcessRequest = () => ({ type: POST_BUSINESS_PROCESS_REQUEST });
const postBusinessProcessSuccess = () => ({ type: POST_BUSINESS_PROCESS_SUCCESS });
const postBusinessProcessFailure = (error) => ({ type: POST_BUSINESS_PROCESS_FAILURE, error });

const editBusinessFileHeaderRequest = () => ({ type: EDIT_BUSINESS_FILE_HEADER_REQUEST });
const editBusinessFileHeaderSuccess = (sheet, rows) => ({ type: EDIT_BUSINESS_FILE_HEADER_SUCCESS, sheet, rows });
const editBusinessFileHeaderFailure = (error) => ({ type: EDIT_BUSINESS_FILE_HEADER_FAILURE, error });

export const removeBusinessFiles = () => ({ type: REMOVE_BUSINESS_FILES });

export const postFiles = (files) => {
  const formData = new FormData();

  Object.keys(files).forEach((key) => {
    formData.append('files[]', files[key]);
  });

  return async (dispatch) => {
    try {
      dispatch(postFilesRequest());
      const response = await axiosApi.post('/person-file/new', formData);
      dispatch(postFilesSuccess([], 0, response?.data?.process_id));
      showNotification('success', 'Your Files uploaded database', response.data.message);
    } catch (error) {
      dispatch(postFilesFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      history.push('/dashboard');
    }
  };
};

export const postSheet = (sheet) => {
  const dataMatching = {};

  Object.keys(sheet.dataMatching).forEach((name) => {
    if (sheet.dataMatching[name] === 'Select Column') dataMatching[name] = '';
    else dataMatching[name] = sheet.dataMatching[name];
  });

  return async (dispatch) => {
    try {
      dispatch(postSheetRequest());
      const response = await axiosApi.put(`/person-file-sheets/edit/${sheet.id}`, { ...sheet, dataMatching });
      dispatch(postSheetSuccess(response.data.sheet, response.data.total_count_rows));
      return true;
    } catch (error) {
      dispatch(postSheetFailure(JSON.parse(error?.config?.data)?.id || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const getHandleProcess = (files) => {
  const fileIds = files.map((file) => file.id);
  return async (dispatch) => {
    try {
      dispatch(postProcessRequest());
      const response = await axiosApi.post('/person-file/handle-process', { fileIds });
      showNotification('success', '', response.data.message);
      dispatch(postProcessSuccess());
      return response.data.message;
    } catch (error) {
      dispatch(postProcessFailure(error.response.data.errors));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error', 8);
      return error?.response?.data?.errors?.message;
    }
  };
};

export const downloadFiles = (id, name) => {
  return async () => {
    try {
      const response = await axiosApi.get(`/person-file/download-file/${id}`, {
        responseType: 'blob',
      });

      fileDownload(response.data, 'results ' + name);
    } catch (error) {
      const customError = JSON.parse(await error?.response?.data.text()).errors?.message;
      showNotification('error', customError || 'File not found', 'Error');
    }
  };
};

export const downloadDashboardFiles = (fileIds) => {
  return async () => {
    try {
      const response = await axiosApi.post(
        `/person-file/download-files-zip`,
        { fileIds },
        {
          responseType: 'blob',
        }
      );

      fileDownload(response.data, 'results.zip');
    } catch (error) {
      showNotification('error', 'All the files you selected are expired', 'Error');
    }
  };
};

export const editFilesHeader = (id, skipHeader) => {
  return async (dispatch) => {
    try {
      dispatch(editFileHeaderRequest());
      const response = await axiosApi.put(`/person-file-sheets/edit-skip-header/${id}`, {
        skipHeader,
      });
      dispatch(editFileHeaderSuccess(response.data.sheet, response.data.total_count_rows));
    } catch (error) {
      dispatch(editFileHeaderFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const postBusinessFiles = (files) => {
  const formData = new FormData();

  Object.keys(files).forEach((key) => {
    formData.append('files[]', files[key]);
  });

  return async (dispatch) => {
    try {
      dispatch(postBusinessFilesRequest());
      const response = await axiosApi.post('/business-file/new', formData);
      dispatch(postBusinessFilesSuccess([], 0, response?.data?.process_id));
      showNotification('success', response.data.message, 'Your Files uploaded database');
    } catch (error) {
      dispatch(postBusinessFilesFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      history.push('/dashboard');
    }
  };
};

export const postSheetBusiness = (sheet) => {
  const dataMatching = {};

  Object.keys(sheet.dataMatching).forEach((name) => {
    if (sheet.dataMatching[name] === 'Select Column') dataMatching[name] = '';
    else dataMatching[name] = sheet.dataMatching[name];
  });

  return async (dispatch) => {
    try {
      dispatch(postBusinessSheetRequest());
      const response = await axiosApi.put(`/business-file-sheets/edit/${sheet.id}`, { ...sheet, dataMatching });
      dispatch(postBusinessSheetSuccess(response.data.sheet, response.data.total_count_rows));
      return true;
    } catch (error) {
      dispatch(postBusinessSheetFailure(JSON.parse(error?.config?.data)?.id || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const editBusinessFilesHeader = (id, skipHeader) => {
  return async (dispatch) => {
    try {
      dispatch(editBusinessFileHeaderRequest());
      const response = await axiosApi.put(`/business-file-sheets/edit-skip-header/${id}`, {
        skipHeader,
      });
      dispatch(editBusinessFileHeaderSuccess(response.data.sheet, response.data.total_count_rows));
    } catch (error) {
      dispatch(editBusinessFileHeaderFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const getHandleBusinessProcess = (files) => {
  const businessFileIds = files.map((file) => file.id);
  return async (dispatch) => {
    try {
      dispatch(postBusinessProcessRequest());
      const response = await axiosApi.post('/business-file/handle-business-process', { businessFileIds });
      showNotification('success', '', response.data.message);
      dispatch(postBusinessProcessSuccess());
      dispatch(getBusinessProcessesHistory());
      return response.data.message;
    } catch (error) {
      dispatch(postBusinessProcessFailure(error.response.data.errors));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error', 8);
      return error?.response?.data?.errors?.message;
    }
  };
};

export const downloadBusinessFiles = (id, name) => {
  return async () => {
    try {
      const response = await axiosApi.get(`/business-file/download-business-file/${id}`, {
        responseType: 'blob',
      });
      fileDownload(response.data, 'results ' + name);
    } catch (error) {
      const customError = JSON.parse(await error?.response?.data.text()).errors?.message;
      showNotification('error', customError || 'File not found', 'Error');
    }
  };
};

export const downloadAllFiles = (fileIds, process, type) => {
  return async () => {
    try {
      if (!fileIds.length) {
        const response = await axiosApi.post(`/person-file/download-files-zip`, { fileIds: filesHandler(type, process) }, { responseType: 'blob' });
        fileDownload(response.data, 'results.zip');
      } else {
        const downLoadType = filesHandler(type, process).length === 1;
        let response;
        if (downLoadType) response = await axiosApi.get(`person-file/download-file/${filesHandler(type, process)[0]}`, { responseType: 'blob' });
        else response = await axiosApi.post(`/person-file/download-files-zip`, { fileIds }, { responseType: 'blob' });
        if (response)
          fileDownload(
            response.data,
            filesHandler(type, process).length === 1 ? (Array.isArray(process) ? process[0].saved_filename : process.name) : 'results.zip'
          );
      }
    } catch (error) {
      const customError = JSON.parse(await error?.response?.data.text()).errors?.message;
      showNotification('error', customError || 'All the files you selected are expired', 'Error');
    }
  };
};

export const downloadAllBusinessFiles = (businessFileIds, process, type) => {
  return async () => {
    try {
      if (!businessFileIds.length) {
        const response = await axiosApi.post(
          `/business-file/download-business-files-zip`,
          { businessFileIds: filesHandler(type, process) },
          { responseType: 'blob' }
        );
        fileDownload(response.data, 'results.zip');
      } else {
        const downLoadType = filesHandler(type, process).length === 1;

        let response;
        if (downLoadType) response = await axiosApi.get(`business-file/download-business-file/${filesHandler(type, process)[0]}`, { responseType: 'blob' });
        else
          response = await axiosApi.post(
            `/business-file/download-business-files-zip`,
            { businessFileIds },
            {
              responseType: 'blob',
            }
          );
        if (response)
          fileDownload(
            response.data,
            filesHandler(type, process).length === 1 ? (Array.isArray(process) ? process[0].saved_filename : process.name) : 'results.zip'
          );
      }
    } catch (error) {
      const customError = JSON.parse(await error?.response?.data.text()).errors?.message;
      showNotification('error', customError || 'All the files you selected are expired', 'Error');
    }
  };
};

export const downloadDashboardBusinessFiles = (businessFileIds) => {
  return async () => {
    try {
      const response = await axiosApi.post(
        `/business-file/download-business-files-zip`,
        { businessFileIds },
        {
          responseType: 'blob',
        }
      );
      fileDownload(response.data, 'results.zip');
    } catch (error) {
      showNotification('error', 'All the files you selected are expired', 'Error');
    }
  };
};

export const downloadOriginal = (data, name) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/admin/files/download-original`, data, {
        responseType: 'blob',
      });
      fileDownload(response.data, name);
    } catch (error) {
      const customError = JSON.parse(await error?.response?.data.text()).errors?.message;
      showNotification('error', customError || 'File Not Found', 'Error');
    }
  };
};

export const downloadUpdated = (data, name) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/admin/files/download-updated`, data, {
        responseType: 'blob',
      });

      fileDownload(response.data, name);
    } catch (error) {
      const customError = JSON.parse(await error?.response?.data.text()).errors?.message;
      showNotification('error', customError || 'File Not Found', 'Error');
    }
  };
};

export const personProcessHelper = (id) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.get(`process/show-process-data/${id}`);
      if (response.data.data.status === 'draft') {
        dispatch(postFilesSuccess(response.data?.data?.person_files, response.data?.data?.total_count_rows, response.data?.data?.id));
        dispatch(changeProcessStatus(response.data?.data?.process_id, response.data?.progress));
        return true;
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const businessProcessHelper = (id) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.get(`/business-process/show-business-process-data/${id}`);
      if (response.data.data.status === 'draft') {
        dispatch(postBusinessFilesSuccess(response.data?.data?.business_files, response.data?.data?.total_count_rows, response.data?.data?.id));
        dispatch(changeProcessStatus(response.data?.data?.process_id, response.data?.progress));
        return true;
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};
