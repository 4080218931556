import React, { useContext, useEffect, useState } from 'react';
import Navigation from './Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees } from '../../../../store/actions/whiteLabelActions';
import { Popover, Spin } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { addAdminRole, addBasicRole, banUser, removeAdminRole, removeBasicRole, resetUserPassword } from '../../../../store/actions/adminActions';
import { PaginationPanelComponent } from '../../../../Components/common/PaginationPanel/paginationPanel';
import { ITEMS_PER_PAGE, USER_ROLES } from '../../../../utils/constants';
import './styles.scss';
import { roleFiltered } from '../../../../utils/functions';
import _ from 'lodash';
import NoResult from '../../../../Components/common/NoResult/NoResult';
import classNames from 'classnames';
import { AppContext } from '../../../../appContext';

const Employees = (props) => {
  const id = props.id || props?.match?.params?.id;
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.whiteLabel.employees);
  const loading = useSelector((state) => state.whiteLabel.loading);
  const { isUserSuperAdmin } = useContext(AppContext);
  const pages = useSelector((state) => state.whiteLabel.employees_pages);
  const [paginationPage, setPaginationPage] = useState(1);

  useEffect(() => {
    if (id) dispatch(getEmployees(id, false, paginationPage));
  }, [dispatch, id, paginationPage]);

  const updateHandler = async (callback) => {
    await callback;
    dispatch(getEmployees(id, false, paginationPage));
  };

  const content = (userId, roles) => {
    return (
      <div className="users-list__popover">
        <button onClick={() => updateHandler(dispatch(banUser(id, userId)))}>Ban user</button>
        <button onClick={() => updateHandler(dispatch(resetUserPassword(userId)))}>Reset password</button>
        {/* {!roles.includes(USER_ROLES.admin) && <button onClick={() => updateHandler(dispatch(addAdminRole(id, userId)))}>set admin</button>} */}
        {isUserSuperAdmin && id !== '1' && (
          <>{!roles.includes(USER_ROLES.admin) && <button onClick={() => updateHandler(dispatch(addAdminRole(id, userId)))}>set admin</button>}</>
        )}
        {!roles.includes(USER_ROLES.basic) && <button onClick={() => updateHandler(dispatch(addBasicRole(id, userId)))}>set basic admin</button>}
        {roles.includes(USER_ROLES.admin) && <button onClick={() => updateHandler(dispatch(removeAdminRole(id, userId)))}>remove admin</button>}
        {roles.includes(USER_ROLES.basic) && <button onClick={() => updateHandler(dispatch(removeBasicRole(id, userId)))}>remove basic admin</button>}
      </div>
    );
  };

  return (
    <Spin spinning={loading} size="large">
      <div className="employees">
        <Navigation id={id} />
        <div className={classNames('employees__container', { 'employees-pagination': pages > 1 })}>
          {!loading && (
            <>
              {_.isEmpty(employees) ? (
                <NoResult text="No employees" />
              ) : (
                <ul className="employees-list">
                  <li className="employees-list__item">
                    <h3>User</h3>
                    <h3>Email</h3>
                    <h3>Roles</h3>
                    <h3>Actions</h3>
                  </li>

                  {employees.map((user, index) => (
                    <li className="employees-list__item" key={index}>
                      <span>{user.first_name + user.last_name}</span>
                      <span> {user.email}</span>
                      <span>{roleFiltered(user?.roles).join(', ')}</span>
                      <span>
                        <Popover placement="topLeft" title={'Actions'} content={content(user.id, user.roles)} trigger="click">
                          <MenuOutlined />
                        </Popover>
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
        <PaginationPanelComponent
          pagintationPage={paginationPage}
          setPaginationPage={setPaginationPage}
          totalCount={pages * ITEMS_PER_PAGE}
          itemsPerPage={ITEMS_PER_PAGE}
        />
      </div>
    </Spin>
  );
};

export default Employees;
