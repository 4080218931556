import React, { useEffect, useState } from 'react';
import WebHookInput from './WebHookInput';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import { getWebHook } from '../../../../store/actions/webHookActions';
import './styles.scss';

const WebHook = () => {
  const dispatch = useDispatch();
  const webHooks = useSelector((state) => state?.webHooks?.webhooks);
  const loading = useSelector((state) => state?.webHooks?.loading);
  const loadingAction = useSelector((state) => state?.webHooks?.loadingAction);

  const [state, setState] = useState([{ defaultId: new Date(), value: '' }]);

  const addedInput = () => {
    setState((prev) => [...prev, { defaultId: new Date(), value: '' }]);
  };

  useEffect(() => {
    dispatch(getWebHook());
  }, [dispatch]);

  useEffect(() => {
    if (webHooks.length) setState(webHooks);
  }, [webHooks]);

  useEffect(() => {
    if (!state.length) setState([{ defaultId: new Date(), value: '' }]);
  }, [state]);

  return (
    <div className="web-hook__container">
      <Spin spinning={loading} size="large" className="web-hook__spinner">
        <div className="web-hook">
          {state.map((item) => (
            <WebHookInput defaultValue={item.url} key={item.id} id={item.id} setState={setState} defaultId={item.defaultId} loadingAction={loadingAction} />
          ))}
        </div>
      </Spin>

      <Button onClick={addedInput} className="web-hook__btn">
        Add
      </Button>
    </div>
  );
};

export default WebHook;
