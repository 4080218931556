import React, { useContext, useRef, useState } from 'react';
import {
  addAdminRole,
  addBasicRole,
  addMainAdminRole,
  addSkips,
  banUser,
  changeUserPassword,
  getUsersList,
  removeAdminRole,
  removeBasicRole,
  removeMainAdminRole,
  resetUserPassword,
  subtractSkips,
  unbanUser,
  verifyUser,
} from '../../../../../store/actions/adminActions';
import { USER_ROLES } from '../../../../../utils/constants';
import { loginAsUser } from '../../../../../store/actions/userActions';
import TextField from '../../../../../Components/common/TextField/TextField';
import Form from './Form';
import { useDispatch } from 'react-redux';
import { useOnClickOutside } from '../../../../../utils/hooks/useOnClickOutside';
import { showNotification } from '../../../../../App';
import { AppContext } from '../../../../../appContext';

const Content = ({ userId, userRole, isVerified, wlURL, banned, name, anotherWL, currentWLId }) => {
  const dispatch = useDispatch();
  const { isUserAdmin, isUserSuperAdmin, isUserMainAdmin } = useContext(AppContext);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [addSkipsForm, setAddSkipsForm] = useState(false);
  const [subtractSkipsForm, setSubtractSkipsForm] = useState(false);
  const [skipsCount, setSkipsCount] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [firstPassword, setFirstPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');

  const ref = useRef();

  useOnClickOutside(ref, () => {
    setShowChangePasswordForm(false);
    setAddSkipsForm(false);
    setSubtractSkipsForm(false);
  });

  const addSkipsHandler = async (wlID, userId) => {
    setDisabled(true);
    if (Number(skipsCount) > 100000) showNotification('error', 'You cant add more then 100.000 skips');
    else {
      const response = await dispatch(addSkips(wlID, userId, { skips: skipsCount }));
      if (response) {
        setAddSkipsForm(false);
        setSkipsCount('');
      }
      dispatch(getUsersList(currentWLId));
    }
    setDisabled(false);
  };

  const subtractSkipsHandler = async (wlID, userId) => {
    setDisabled(true);
    const response = await dispatch(subtractSkips(wlID, userId, { skips: skipsCount }));
    if (response) {
      setSubtractSkipsForm(false);
      setSkipsCount('');
    }
    dispatch(getUsersList(currentWLId));
    setDisabled(false);
  };

  const updatePassword = async (wlID, userId) => {
    const passwordData = {
      first: firstPassword,
      second: secondPassword,
    };
    if (firstPassword === secondPassword) {
      const response = await dispatch(changeUserPassword(wlID, userId, { password: passwordData }));
      if (response) {
        setFirstPassword('');
        setSecondPassword('');
        setShowChangePasswordForm(false);
      }
    } else showNotification('error', 'Passwords do not match', 'Error');
  };

  return (
    <div className="users-list__popover" ref={ref}>
      {!showChangePasswordForm && !addSkipsForm && !subtractSkipsForm && (
        <>
          {(isUserAdmin || (isUserMainAdmin && !anotherWL) || isUserSuperAdmin) && (
            <>
              {!banned && <button onClick={() => dispatch(banUser(currentWLId, userId, name))}>Ban user</button>}
              {banned && <button onClick={() => dispatch(unbanUser(currentWLId, userId, name))}>Unban user</button>}
              <button onClick={() => dispatch(resetUserPassword(currentWLId, userId))}>Reset password</button>
              {!isVerified && <button onClick={() => dispatch(verifyUser(currentWLId, userId))}>Verify User</button>}
            </>
          )}
          {isUserSuperAdmin && (
            <>
              {!userRole.includes(USER_ROLES.basic) && <button onClick={() => dispatch(addBasicRole(currentWLId, userId))}>set basic admin</button>}
              {userRole.includes(USER_ROLES.basic) && <button onClick={() => dispatch(removeBasicRole(currentWLId, userId))}>remove basic admin</button>}

              {userRole.includes(USER_ROLES.admin) && <button onClick={() => dispatch(removeAdminRole(currentWLId, userId))}>remove admin</button>}
              <button onClick={() => dispatch(loginAsUser(currentWLId, userId, wlURL))}>Login as User</button>
            </>
          )}
          {isUserSuperAdmin && currentWLId !== '1' && (
            <>{!userRole.includes(USER_ROLES.admin) && <button onClick={() => dispatch(addAdminRole(currentWLId, userId))}>set admin</button>}</>
          )}
          {isUserSuperAdmin && Number(currentWLId) === 1 && (
            <>
              {!userRole.includes(USER_ROLES.main) && <button onClick={() => dispatch(addMainAdminRole(currentWLId, userId))}>Set Main Admin</button>}
              {userRole.includes(USER_ROLES.main) && <button onClick={() => dispatch(removeMainAdminRole(currentWLId, userId))}>Remove Main Admin</button>}
            </>
          )}
          {isUserSuperAdmin && <button onClick={() => setShowChangePasswordForm((prev) => !prev)}>change password</button>}
          {(isUserSuperAdmin || isUserMainAdmin) && !banned && (
            <button onClick={() => setAddSkipsForm((prev) => !prev)} className="add-skips__btn">
              Add Skips
            </button>
          )}
          {(isUserSuperAdmin || isUserMainAdmin) && !banned && (
            <button onClick={() => setSubtractSkipsForm((prev) => !prev)} className="add-skips__btn">
              Subtract Skips
            </button>
          )}
        </>
      )}
      {showChangePasswordForm && (
        <div className="change-password__container">
          <TextField
            type="password"
            width={'450px'}
            placeholder="password"
            value={firstPassword}
            setState={setFirstPassword}
            parentClassName="change-user-password"
          />
          <TextField
            type="password"
            width={'450px'}
            placeholder="confirm password"
            value={secondPassword}
            setState={setSecondPassword}
            parentClassName="change-user-password"
          />
          <div className="m-t-15 flex flex-align-items-center">
            <button
              style={{ width: '50%' }}
              className="m-r-15 "
              onClick={() => {
                setShowChangePasswordForm((prev) => !prev);
                setFirstPassword('');
                setSecondPassword('');
              }}
            >
              Cancel
            </button>
            <button style={{ width: '50%' }} className="m-r-15 " onClick={() => updatePassword(currentWLId, userId)}>
              Update
            </button>
          </div>
        </div>
      )}

      {addSkipsForm && (
        <Form
          wlID={currentWLId}
          userID={userId}
          skipsCount={skipsCount}
          setSkipsCount={setSkipsCount}
          state={addSkipsForm}
          setState={setAddSkipsForm}
          callback={addSkipsHandler}
          disabled={disabled}
        />
      )}
      {subtractSkipsForm && (
        <Form
          wlID={currentWLId}
          userID={userId}
          skipsCount={skipsCount}
          setSkipsCount={setSkipsCount}
          state={subtractSkipsForm}
          setState={setSubtractSkipsForm}
          callback={subtractSkipsHandler}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default Content;
