import React, { useContext, useEffect, useState } from 'react';
import TextField from '../../Components/common/TextField/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../store/actions/userActions';
import { Link, useHistory } from 'react-router-dom';
import PasswordIcon from '../../assets/auth/password.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Spin } from 'antd';

import './styles.scss';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { apiURL } from '../../utils/config';
import { AppContext } from '../../appContext';
import { toCapitalize } from '../../utils/functions';
import moment from 'moment';
import AuthenticationLayout from '../../Components/UI/AuthenticationLayout/AuthenticationLayout';
import SocialBlock from '../../Components/UI/SocialBlock/SocialBlock';
import ReactGa from 'react-ga4';

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const token = props.match.params.token;
  const loading = useSelector((state) => state.user.loading);
  const width = useResizeWindows();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { brand, isSkipCloud } = useContext(AppContext);
  const pathname = useHistory().location.pathname;

  // whitelabel images from config
  const subLogo = useSelector((state) => state.config.config?.images?.logo_2);
  const authBackground = useSelector((state) => state.config.config?.images?.auth_background);

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPasswordHandler = () => {
    dispatch(
      resetPassword(token, {
        password: {
          first: password,
          second: confirmPassword,
        },
      })
    );
  };

  let textFieldButtonSize = '430px';
  if (width < 460 && width > 370) {
    textFieldButtonSize = '350px';
  } else if (width <= 370) {
    textFieldButtonSize = '300px';
  }

  const forgotStyles = {
    margin: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${apiURL + authBackground}) 0 -2px no-repeat`,
    backgroundSize: isSkipCloud ? 'contain' : 'cover',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    height: 'calc(100vh)',
  };

  return (
    <div className="auth" style={forgotStyles}>
      <div className="auth__body">
        {width >= 1200 && <AuthenticationLayout />}
        <div className="auth__body-form  m-t-auto m-b-auto">
          <LazyLoadImage className="login__logo" draggable={false} src={`${apiURL + subLogo}`} alt="logo" />
          <h3 className="auth__title">
            Reset <span>Password</span>
          </h3>
          <div className="login__border" />

          <Spin spinning={loading}>
            <TextField
              type="password"
              icon={PasswordIcon}
              width={textFieldButtonSize}
              value={password}
              setState={setPassword}
              placeholder="Password"
              parentClassName="m-t-20"
            />
            <TextField
              type="password"
              icon={PasswordIcon}
              width={textFieldButtonSize}
              value={confirmPassword}
              setState={setConfirmPassword}
              placeholder="Confirm password"
            />
            <button className="auth__button m-t-30" onClick={resetPasswordHandler}>
              Reset password
            </button>
          </Spin>
          <div className="login__border m-b-25" />
          <p className="forgot__login ">
            Go back to
            <Link className="forgot__link" to="/">
              Login ?
            </Link>
          </p>
          <div className="login__border" />
          {width <= 1200 && <SocialBlock />}
        </div>
      </div>
      <div className="auth__footer">
        <p>
          All rights reserved. {toCapitalize(brand)}, LLC. {moment(new Date()).format('YYYY')}
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
