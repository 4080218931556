import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { PaginationPanelComponent } from '../../../../../Components/common/PaginationPanel/paginationPanel';
import { getPayment } from '../../../../../store/actions/paymentActions';
import { ITEMS_PER_PAGE } from '../../../../../utils/constants';
import Navigation from '../Navigation';
import PaymentFullInfo from './PaymentFullInfo';
import _ from 'lodash';
import NoResult from '../../../../../Components/common/NoResult/NoResult';
import classNames from 'classnames';

const PaymentStatistic = () => {
  const dispatch = useDispatch();
  const [pagintationPage, setPagintationPage] = useState(1);
  const paymentInfo = useSelector((state) => state?.payment?.paymentInfo);
  const pages = useSelector((state) => state?.payment?.pages) || 1;
  const paymentLoading = useSelector((state) => state?.payment?.paymentLoading);
  const [showModal, setShowModal] = useState(false);
  const [paymentIdx, setPaymentIdx] = useState('');

  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const { url } = useRouteMatch();
  const { id, userId } = useParams();
  const anotherWL = url.includes('white-label');
  const currentWLId = anotherWL ? id : whiteLabelId;

  useEffect(() => {
    dispatch(getPayment(currentWLId, userId, pagintationPage));
  }, [dispatch, currentWLId, userId, pagintationPage]);

  const modData = (idx) => {
    setShowModal(true);
    setPaymentIdx(idx);
  };

  return (
    <Spin spinning={paymentLoading}>
      <div className={classNames('users-list__container-statistic', { 'container-statistic-pagination': pages > 1 })}>
        <Navigation id={currentWLId} userId={userId} />
        {!paymentLoading && (
          <>
            {_.isEmpty(paymentInfo) ? (
              <NoResult text="User didn't buy skips" />
            ) : (
              <ul className="users-list">
                <li className="detail-info__item detail-info__item--heading">
                  <h3>Skips</h3>
                  <h3>Price</h3>
                  <h3>Date</h3>
                  <h3>Actions</h3>
                </li>

                {paymentInfo.map((info, index) => {
                  return (
                    <li className="detail-info__item" key={info.id}>
                      <span>{info?.skips}</span>
                      <span>$ {info?.amount / 100}</span>
                      <span>{info?.created_at}</span>
                      <span>
                        {info.payment_details && (
                          <button className="show__info__button" onClick={() => modData(index)}>
                            Detail Info
                          </button>
                        )}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}
      </div>
      <PaginationPanelComponent
        pagintationPage={pagintationPage}
        setPaginationPage={setPagintationPage}
        totalCount={pages * ITEMS_PER_PAGE}
        itemsPerPage={ITEMS_PER_PAGE}
      />
      <PaymentFullInfo visibleModal={showModal} closeModal={() => setShowModal(false)} id={paymentIdx} />
    </Spin>
  );
};

export default PaymentStatistic;
