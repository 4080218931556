import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import UserNameIcon from '../../../../assets/auth/userName.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';
import { createWebHook, deleteWebHook, editWebHook, postTestWebHook } from '../../../../store/actions/webHookActions';
import { showNotification } from '../../../../App';

const message = {
  date_and_time: '01/20/2023 00:06:00',
  activity_description: 'User Logged In',
  user_id: '1-1',
  user: 'Test name Test lastname',
  user_email: 'testemai@mail.com',
  phone_number: '18127372187',
  user_came_from: 'Google',
  user_type: 'skipcloud.io',
  white_label_company_name: 'skipcloud',
  white_label_parent_id: 1,
  text_short_activity_description: 'User logged in.',
  ip_address: '192.168.1.1',
};

const WebHookInput = ({ id, defaultValue, setState, defaultId, loadingAction }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState('');

  const editHandler = () => {
    setEdit((prev) => !prev);
  };

  console.log(defaultValue);

  const cancelHandler = () => {
    setEdit((prev) => !prev);

    if (id) setValue(defaultValue);
    else setValue('');
  };

  const deleteHandler = async () => {
    if (id) {
      await dispatch(deleteWebHook(id));
      setState((prev) => prev.filter((item) => item.id !== id));
    } else setState((prev) => prev.filter((item) => item.defaultId !== defaultId));
  };

  const testDataHandler = () => {
    if (value.trim()) dispatch(postTestWebHook(value, message));
    else showNotification('error', 'Send link missing', 'Error');
  };

  const saveHandler = async () => {
    if (id) {
      const response = await dispatch(editWebHook(id, { url: value }));
      if (response) setEdit(false);
    } else {
      const response = await dispatch(createWebHook({ url: value }));
      if (response) setEdit(false);
    }
  };

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="web-hook__field">
      <div className="label">
        <LazyLoadImage className={`field__icon`} src={UserNameIcon} alt="icon" />
        <input style={{ width: '100%' }} className="field" type="text" value={value} onChange={(e) => setValue(e.target.value)} disabled={!edit} />
      </div>
      <div className="web-hook__field-action">
        {edit ? (
          <>
            <Button disabled={loadingAction} onClick={cancelHandler}>
              Cancel
            </Button>
            <Button disabled={loadingAction} onClick={saveHandler}>
              Save
            </Button>
          </>
        ) : (
          <Button disabled={loadingAction} onClick={editHandler}>
            Edit
          </Button>
        )}
        {!edit ? (
          <>
            <Button disabled={loadingAction} onClick={deleteHandler}>
              Delete
            </Button>
            <Button disabled={loadingAction} onClick={testDataHandler}>
              Test Data
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default WebHookInput;
