import React from 'react';

const SidebarFooter = ({ children }) => {
  return (
    <div className="sidebar__footer">
      <svg className="svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 610 230">
        <defs>
          <linearGradient id="SKIPCLOUD__FOOTER" x1="81.34" y1="158.26" x2="189.69" y2="158.26" gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{ stopColor: 'var(--gradient-1)' }} stopColor="var(--gradient-1)" />
            <stop offset="0.7" style={{ stopColor: 'var(--gradient-2)' }} stopColor="var(--gradient-2)" />
            <stop offset="1" style={{ stopColor: 'var(--gradient-2)' }} stopColor="var(--gradient-2)" />
          </linearGradient>
          <filter id="filter" x="0" y="0" width="690" height="317" filterUnits="userSpaceOnUse">
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="27" result="blur" />
            <feFlood floodColor="#0070f1" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g id="Слой_2" data-name="Слой 2">
          <g id="Layer_1" data-name="Layer 1">
            <path fill="url(#SKIPCLOUD__FOOTER)" className="cls-1" d="M606.06,235.47H81.34V81.05l401,39c76.2,6.35,117,44.85,126.47,115.43Z" />
          </g>
        </g>
      </svg>

      {children}
    </div>
  );
};

export default SidebarFooter;
