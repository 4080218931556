import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Table } from 'antd';
import DatePickers from '../Statistics/DatePickers/DatePickers';
import { AppContext } from '../../../appContext';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { PaginationPanelComponent } from '../../common/PaginationPanel/paginationPanel';
import { ITEMS_PER_PAGE } from '../../../utils/constants';
import _ from 'lodash';
import NoResult from '../../common/NoResult/NoResult';
import classnames from 'classnames';
import useResizeHeight from '../../../utils/hooks/useResizeHeight';
import Description from '../Statistics/UserActivity/Description';
import { getUserActivitySuperAdmin } from '../../../store/actions/superAdminReportsActions';

const UserActivitySuperAdmin = () => {
  const id = 'all';
  const dispatch = useDispatch();
  const activityHistory = useSelector((state) => state.reportsSuperAdmin.userActivity);
  const pages = useSelector((state) => state.reportsSuperAdmin.userActivity_pages);
  const loading = useSelector((state) => state.reportsSuperAdmin.loading);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tmpStart, setTmpStart] = useState('');
  const [tmpEnd, setTmpEnd] = useState('');
  const [page, setPage] = useState(1);
  const [type, setType] = useState('');
  const [user, setUser] = useState('');
  const [typeHandler, setTypeHandler] = useState('');
  const { collapsed } = useContext(AppContext);
  const width = useResizeWindows();
  const height = useResizeHeight();

  const searchById = (id) => {
    setUser(id);
    setPage(1);
  };

  const columns = [
    {
      title: 'Date and Time',
      width: 200,
      dataIndex: 'date_and_time',
      key: 'date_and_time',
      fixed: 'left',
    },
    {
      title: 'Activity Description',
      width: 250,
      dataIndex: 'activity_description',
      key: 'activity_description',
      fixed: 'left',
    },
    {
      title: 'User ID',
      width: 70,
      dataIndex: 'user_id',
      key: 'user_id',
      fixed: 'left',
      render: (_, record) => (
        <div className="history-table-btn" onClick={() => searchById(record.user_id)}>
          {record.user_id}
        </div>
      ),
    },
    {
      title: 'User',
      width: 200,
      dataIndex: 'user_name',
      key: 'user_name',
      render: (_, record) => (
        <div className="history-table-btn" onClick={() => searchById(record.user_id)}>
          {record?.user_name} {record?.user_last_name}
        </div>
      ),
    },
    {
      title: 'User Email',
      width: 220,
      dataIndex: 'user_email',
      key: 'user_email',
      render: (_, record) => (
        <div className="history-table-btn" onClick={() => searchById(record.user_id)}>
          {record.user_email}
        </div>
      ),
    },
    {
      title: 'Phone Number',
      width: 200,
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (_, record) => <div className="history-table-btn">{record.phone_number ? record.phone_number : 'not specified'}</div>,
    },
    {
      title: 'How did you hear about us?',
      width: 200,
      dataIndex: 'user_came_from',
      key: 'user_came_from',
      render: (_, record) => <div className="history-table-btn">{record.user_came_from === 'empty' ? 'not specified' : record.user_came_from}</div>,
    },
    {
      title: 'User Type',
      width: 200,
      dataIndex: 'user_type',
      key: 'user_type',
    },

    {
      title: 'White Label Company Name',
      width: 200,
      dataIndex: 'white_label_company_name',
      key: 'white_label_company_name',
    },

    {
      title: 'White Label Parent ID',
      width: 120,
      dataIndex: 'white_label_parent_id',
      key: 'white_label_parent_id',
    },
    {
      title: 'Short Description',
      width: 300,
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <Description record={record} />,
    },

    {
      title: 'User IP Address during Activity Logged',
      width: 200,
      dataIndex: 'ip_address',
      key: 'ip_address',
    },
  ];

  const startDateHandler = (date, dateString) => {
    setTmpStart(dateString);
  };

  const endDateHandler = (date, dateString) => {
    setTmpEnd(dateString);
  };

  const submitDateHandler = () => {
    setStartDate(tmpStart);
    setEndDate(tmpEnd);
    setType(typeHandler);
    setPage(1);
  };

  useEffect(() => {
    if (id) dispatch(getUserActivitySuperAdmin(id, startDate, endDate, false, page, type, user));
  }, [dispatch, startDate, endDate, id, page, type, user]);

  const tableWidth = useMemo(() => {
    return width <= 1279 ? '1200px' : collapsed ? `${width - 250}px` : `${width - 370}px`;
  }, [width, collapsed]);

  const tableHeight = useMemo(() => {
    return width < 1400 && height < 750 ? `${pages > 1 ? '230px' : '275px'}` : width < 1450 ? '255px' : '375px';
  }, [width, height, pages]);

  const clearHandler = () => {
    setType('');
    setUser('');
    setPage(1);
    setStartDate('');
    setEndDate('');
    setTmpStart('');
    setTmpEnd('');
    setTypeHandler('');
  };

  return (
    <Spin spinning={loading}>
      <div className={classnames('white-label-table  ', { 'with-pagination-reports': pages > 1 })}>
        <DatePickers
          user={user}
          setUser={setUser}
          activityHistory
          startDateHandler={startDateHandler}
          endDateHandler={endDateHandler}
          request={getUserActivitySuperAdmin}
          id={id}
          startDate={startDate}
          endDate={endDate}
          dispatch={dispatch}
          submitDate={submitDateHandler}
          setType={setTypeHandler}
          clearHandler={clearHandler}
        />
        {!loading && (
          <>
            {_.isEmpty(activityHistory) && !loading ? (
              <NoResult text="User activity history is empty" />
            ) : (
              <>
                <Table
                  loading={loading}
                  pagination={false}
                  columns={columns}
                  dataSource={activityHistory}
                  style={{
                    width: tableWidth,
                    margin: '0 auto',
                    height: '400px',
                    borderTop: '1px solid var(--main-color)',
                  }}
                  scroll={{
                    x: '1000px',
                    y: tableHeight,
                  }}
                  tableLayout="fixed"
                  rowKey="index"
                />
              </>
            )}
          </>
        )}

        <div className="m-t-auto m-l-auto m-r-10">
          {!loading && (
            <PaginationPanelComponent pagintationPage={page} setPaginationPage={setPage} totalCount={pages * ITEMS_PER_PAGE} itemsPerPage={ITEMS_PER_PAGE} />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default UserActivitySuperAdmin;
