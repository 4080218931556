import React, { useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import classnames from 'classnames';
import './styles.scss';

const SelectField = ({ options, onSelect, isError }) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleOptions = () => setIsOpen(!isOpen);
  useOnClickOutside(ref, () => setIsOpen(false));
  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    toggleOptions();
  };

  return (
    <div className={classnames('custom-select', { 'custom-select--error': isError })} onClick={toggleOptions} ref={ref}>
      <div className="selected-option" onClick={toggleOptions}>
        {selectedOption && <LazyLoadImage src={selectedOption?.icon} className="displayed__icons options__icon" />}
        {selectedOption ? <p> {selectedOption.label}</p> : <p className="m-l-20"> How did you hear about us?</p>}
      </div>
      {isOpen && (
        <ul className="options">
          {options.map((option) => (
            <li key={option.value} onClick={() => handleSelect(option)}>
              <LazyLoadImage src={option.icon} className="options__icon" />
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectField;
