import axiosApi from '../../utils/axios';
import { showNotification } from '../../App';
import {
  DEFAULT_LOADING,
  GET_CONFIG_FAILURE,
  GET_CONFIG_REQUEST,
  GET_CONFIG_SUCCESS,
  GET_STATE_FAILURE,
  GET_STATE_REQUEST,
  GET_STATE_SUCCESS,
} from '../actionsTypes';
import { apiURL } from '../../utils/config';

const getConfigRequest = () => ({ type: GET_CONFIG_REQUEST });
const getConfigSuccess = (config) => ({ type: GET_CONFIG_SUCCESS, config });
export const getConfigFailure = (error) => ({ type: GET_CONFIG_FAILURE, error });

const getStatesRequest = () => ({ type: GET_STATE_REQUEST });
const getStatesSuccess = (states) => ({ type: GET_STATE_SUCCESS, states });
const getStatesFailure = (error) => ({ type: GET_STATE_FAILURE, error });

export const getConfig = () => {
  return async (dispatch) => {
    dispatch({ type: DEFAULT_LOADING });
    try {
      dispatch(getConfigRequest());
      const response = await axiosApi.get('/config/');
      if (response?.data?.data?.name) document.title = response?.data?.data?.name;
      else document.title = 'Loading...';
      const favicon = document.getElementById('favicon');
      const faviconApple = document.getElementById('favicon-apple');
      if (response?.data?.data?.images?.domain_logo) {
        favicon.setAttribute('href', apiURL + response?.data.data.images?.domain_logo);
        favicon.setAttribute('type', 'image/png');
        faviconApple.setAttribute('href', apiURL + response?.data.data.images?.domain_logo);
        faviconApple.setAttribute('type', 'image/png');
      }
      dispatch(getConfigSuccess(response?.data?.data));
      const colors = response?.data?.data?.colors || false;
      if (colors) {
        for (const key in colors) {
          const color = colors[key] || '#000';
          document.documentElement.style.setProperty(key, color);
        }
      } else document.documentElement.removeAttribute('style');

      if (response?.data?.data?.jivochat_url) {
        const head = document.getElementById('head');
        const script = document.createElement('script');
        script.src = '//code.jivosite.com/widget/' + response.data.data?.jivochat_url;
        script.async = true;
        head.appendChild(script);
      }

      if (response?.data?.data?.white_label_id === 1) {
        window._mfq = window._mfq || [];
        (function () {
          var mf = document.createElement('script');
          mf.type = 'text/javascript';
          mf.defer = true;
          mf.src = '//cdn.mouseflow.com/projects/0ac59da5-e38a-49eb-8de9-de7191e0d4e5.js';
          document.getElementsByTagName('head')[0].appendChild(mf);
        })();
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || 'The server is temporarily unavailable', 'Error');
      dispatch(getConfigFailure(!error?.response?.data?.errors?.message || 'The server is temporarily unavailable'));
    }
  };
};

export const getStateList = () => {
  return async (dispatch) => {
    try {
      dispatch(getStatesRequest());
      const response = await axiosApi.get('/profile/states-list');
      dispatch(getStatesSuccess(response.data.states));
    } catch (error) {
      dispatch(getStatesFailure(error?.response?.data?.errors?.message || true));
    }
  };
};
