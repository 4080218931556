import {
  CREATE_AVATAR_FAILURE,
  CREATE_AVATAR_REQUEST,
  CREATE_AVATAR_SUCCESS,
  DEFAULT_LOADING,
  EDIT_EMAIL_FAILURE,
  EDIT_EMAIL_REQUEST,
  EDIT_EMAIL_SUCCESS,
  EDIT_PASSWORD_FAILURE,
  EDIT_PASSWORD_REQUEST,
  EDIT_PASSWORD_SUCCESS,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  FORGOT_USER_FAILURE,
  FORGOT_USER_REQUEST,
  FORGOT_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  PAYMENT_STRIPE_FAILURE,
  PAYMENT_STRIPE_REQUEST,
  PAYMENT_STRIPE_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_HIGHLEVEL_REQUEST,
  REGISTER_USER_HIGHLEVEL_SUCCESS,
  REGISTER_USER_HIGHLEVEL_FAILURE,
  RESET_USER_FAILURE,
  RESET_USER_REQUEST,
  RESET_USER_SUCCESS,
  USER_PROFILE_FAILURE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  UPDATE_RECEIVE_NOTIFICATIONS_SUCCESS,
  UPDATE_RECEIVE_NOTIFICATIONS_REQUEST,
  UPDATE_RECEIVE_NOTIFICATIONS_FAILURE,
} from '../actionsTypes';

export const initialState = {
  user: null,
  profile: null,
  loading: false,
  error: null,
  notificationLoading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.user, error: null };
    case LOGIN_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case REGISTER_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.user ? action.user : null, error: null };
    case REGISTER_USER_FAILURE:
      return { ...state, loading: false, error: action.error };

    case REGISTER_USER_HIGHLEVEL_REQUEST:
      return { ...state, loading: true, error: null };
    case REGISTER_USER_HIGHLEVEL_SUCCESS:
      return { ...state, loading: false, user: action.user ? action.user : null, error: null };
    case REGISTER_USER_HIGHLEVEL_FAILURE:
      return { ...state, loading: false, error: action.error };

    case FORGOT_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case FORGOT_USER_SUCCESS:
      return { ...state, loading: false, error: null };
    case FORGOT_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case RESET_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case RESET_USER_SUCCESS:
      return { ...state, loading: false, error: null };
    case RESET_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case USER_PROFILE_REQUEST:
      return { ...state, loading: true, error: null };
    case USER_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: action.profile, error: null };
    case USER_PROFILE_FAILURE:
      return { ...state, loading: false, profile: {}, error: action.error };
    case EDIT_PROFILE_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: { ...state.profile, ...action.profile }, error: null };
    case EDIT_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.error };
    case EDIT_EMAIL_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_EMAIL_SUCCESS:
      return { ...state, loading: false, error: null };
    case EDIT_EMAIL_FAILURE:
      return { ...state, loading: false, error: action.error };
    case CREATE_AVATAR_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_AVATAR_SUCCESS:
      return { ...state, loading: false, error: null };
    case CREATE_AVATAR_FAILURE:
      return { ...state, loading: false, error: action.error };
    case PAYMENT_STRIPE_REQUEST:
      return { ...state, loading: true, error: null };
    case PAYMENT_STRIPE_SUCCESS:
      return { ...state, loading: false, profile: action.profile, error: null };
    case PAYMENT_STRIPE_FAILURE:
      return { ...state, loading: false, error: action.error };
    case EDIT_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null };
    case EDIT_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.error };
    case DEFAULT_LOADING:
      return { ...state, loading: false, error: null };
    case LOGOUT_USER:
      return initialState;

    case UPDATE_RECEIVE_NOTIFICATIONS_REQUEST:
      return { ...state, notificationLoading: true };
    case UPDATE_RECEIVE_NOTIFICATIONS_SUCCESS:
      return { ...state, notificationLoading: false };
    case UPDATE_RECEIVE_NOTIFICATIONS_FAILURE:
      return { ...state, notificationLoading: false };
    default:
      return { ...state };
  }
};

export default userReducer;
