import {
  CREATE_WEBHOOK_FAILURE,
  CREATE_WEBHOOK_REQUEST,
  CREATE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_FAILURE,
  DELETE_WEBHOOK_REQUEST,
  DELETE_WEBHOOK_SUCCESS,
  EDIT_WEBHOOK_FAILURE,
  EDIT_WEBHOOK_REQUEST,
  EDIT_WEBHOOK_SUCCESS,
  GET_WEBHOOK_FAILURE,
  GET_WEBHOOK_REQUEST,
  GET_WEBHOOK_SUCCESS,
  TEST_WEBHOOK_FAILURE,
  TEST_WEBHOOK_REQUEST,
  TEST_WEBHOOK_SUCCESS,
} from '../actionsTypes';

export const initialState = {
  webhooks: [],
  loading: false,
  error: null,
  loadingAction: false,
};

const webHookReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WEBHOOK_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_WEBHOOK_SUCCESS:
      return { ...state, loading: false, error: null, webhooks: action.webhooks };
    case GET_WEBHOOK_FAILURE:
      return { ...state, loading: false, error: action.error };
    case EDIT_WEBHOOK_REQUEST:
      return { ...state, loadingAction: true, error: null };
    case EDIT_WEBHOOK_SUCCESS:
      return {
        ...state,
        loadingAction: false,
        error: null,
        webhooks: state.webhooks.map((item) => {
          if (item.id === action.webhook.id) {
            item.url = action.webhook.url;
          }
          return item;
        }),
      };
    case EDIT_WEBHOOK_FAILURE:
      return { ...state, loadingAction: false, error: action.error };

    case CREATE_WEBHOOK_REQUEST:
      return { ...state, loadingAction: true, error: null };
    case CREATE_WEBHOOK_SUCCESS:
      return {
        ...state,
        loadingAction: false,
        error: null,
        webhooks: [...state.webhooks, action.webhook],
      };
    case CREATE_WEBHOOK_FAILURE:
      return { ...state, loadingAction: false, error: action.error };

    case DELETE_WEBHOOK_REQUEST:
      return { ...state, loadingAction: true, error: null };
    case DELETE_WEBHOOK_SUCCESS:
      return { ...state, loadingAction: false, error: null, webhooks: state.webhooks.filter((item) => item.id !== action.id) };
    case DELETE_WEBHOOK_FAILURE:
      return { ...state, loadingAction: false, error: action.error };
    case TEST_WEBHOOK_REQUEST:
      return { ...state, loading: true, error: null };
    case TEST_WEBHOOK_SUCCESS:
      return { ...state, loading: false, error: null };
    case TEST_WEBHOOK_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
};
export default webHookReducer;
