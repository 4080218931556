import React, { useEffect, useState } from 'react';
import AccountManagmentField from '../../Components/Field/AccountManagmentField';
import arrowLeft from '../../../../assets/account/arrow-button.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Spin } from 'antd';
import history from '../../../../utils/history';
import { getBillingAddressCard, updateBillingAddressCard } from '../../../../store/actions/paymentActions';
import { getStateList } from '../../../../store/actions/configActions';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './styles.scss';
import ReactGa from 'react-ga4';
import { useHistory } from 'react-router-dom';

const EditBillingDetails = (props) => {
  const dispatch = useDispatch();
  const pathname = useHistory().location.pathname;
  const states = useSelector((state) => state.config.states);
  const loading = useSelector((state) => state.user.loading);
  const loadingPayment = useSelector((state) => state.payment.loading);
  const billing = useSelector((state) => state?.payment?.billing);
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('Select State');
  const [zipCode, setZipCode] = useState('');

  useEffect(() => {
    dispatch(getStateList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBillingAddressCard(props.match.params.id));
  }, [dispatch, props.match.params.id]);

  useEffect(() => {
    setAddressLine1(_.isEmpty(billing) ? '' : billing.address_line_1);
    setAddressLine2(_.isEmpty(billing) ? '' : billing.address_line_2);
    setCity(_.isEmpty(billing) ? '' : billing.city);
    setState(_.isEmpty(billing) ? '' : billing.state);
    setZipCode(_.isEmpty(billing) ? '' : billing.zip_code);
  }, [billing]);

  useEffect(() => {
    ReactGa.send({ hitType: "pageview", page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createUpdateBillingDetails = async () => {
    const billingData = {
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
    };
    await dispatch(updateBillingAddressCard(billingData, props.match.params.id));
    history.push('/profile/billing');
  };

  return (
    <div className="edit-billing">
      <div className="edit-header">
        <div className="edit-header-nav">
          <p>Payment Details</p>
          <button>Billing Address</button>
        </div>
      </div>
      <div className="edit-billing-body">
        <Spin spinning={loading || loadingPayment} size="large">
          <AccountManagmentField width="100%" placeholder="Address Line 1" value={addressLine1} setValue={setAddressLine1} />
          <AccountManagmentField width="100%" placeholder="Address Line 2 (Unit, Suite, Apt, Etc...)" value={addressLine2} setValue={setAddressLine2} />
          <div className="edit-billing-body-item">
            <AccountManagmentField width="35%" placeholder="City" value={city} setValue={setCity} />
            <Select defaultValue={billing && billing.state} onChange={(value) => setState(value)} style={{ width: '28%' }}>
              {states.map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
            <AccountManagmentField width="35%" placeholder="Zip Code" value={zipCode} setValue={setZipCode} />
          </div>
        </Spin>
      </div>
      <div className="border" />
      <div className="edit-billing-footer">
        <button onClick={createUpdateBillingDetails} className="m-l-auto">
          Finish <LazyLoadImage src={arrowLeft} alt="" />
        </button>
      </div>
    </div>
  );
};

export default EditBillingDetails;
