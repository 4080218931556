import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadFromLocalStorage, saveToLocalStorage } from '../utils/localStorage';
import userReducer from './reducers/userReducer';
import axiosApi from '../utils/axios';
import fileReducer from './reducers/fileReducer';
import processReducer from './reducers/processReducer';
import paymentReducer from './reducers/paymentReducer';
import configReducer from './reducers/configReducer';
import adminReducer from './reducers/adminReducer';
import landingReducer from './reducers/landingReducer';
import whiteLabelReducer from './reducers/whiteLabelReducer';
import invitesReducer from './reducers/invitesReducer';
import callendlyReducer from './reducers/callednlyReducer';
import { LOGOUT_USER } from './actionsTypes';
import history from '../utils/history';
import { getConfigFailure } from './actions/configActions';
import sendGridReducer from './reducers/sendGridReducer';
import superAdminReportsReducer from './reducers/superAdminReportsReducer';
import webHookReducer from './reducers/webHookReducer';
import searchSettingsReducer from './reducers/searchSettingsReducer';

const rootReducer = combineReducers({
  user: userReducer,
  files: fileReducer,
  process: processReducer,
  payment: paymentReducer,
  config: configReducer,
  admin: adminReducer,
  landing: landingReducer,
  whiteLabel: whiteLabelReducer,
  invites: invitesReducer,
  callendly: callendlyReducer,
  sendGrid: sendGridReducer,
  reportsSuperAdmin: superAdminReportsReducer,
  webHooks: webHookReducer,
  searchSettings:searchSettingsReducer
});

const preloadedState = loadFromLocalStorage('users') || {};
const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(() => {
  saveToLocalStorage('users', {
    user: store.getState()?.user,
  });
});

axiosApi.interceptors.request.use((config) => {
  if (store.getState()?.user.user) {
    config.headers['Authorization'] = `Bearer ${store.getState()?.user.user.token}`;
  }

  return config;
});

axiosApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(getConfigFailure(null));
      store.dispatch({ type: LOGOUT_USER });
      saveToLocalStorage('users', {});
      history.push('/');
      window.location.reload();
    }
    throw error;
  },
);

export default store;
