import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../appContext';

const RoleNavigation = ({ whiteLabelName }) => {
  const pathname = useHistory().location.pathname;
  const { isUserMainAdmin, isUserAdmin, isUserBasic, isUserSuperAdmin } = useContext(AppContext);

  return (
    <nav className='admin-panel__role'>
      {(isUserAdmin || isUserMainAdmin) && (
        <Link
          className={`admin-panel__role__item ${!pathname.includes('super') && !pathname.includes('white-label') ? 'admin-panel__role__item--active' : ''}`}
          to='/admin'
        >
          Management
        </Link>
      )}
      {(isUserSuperAdmin || isUserMainAdmin) && (
        <>
          <Link
            draggable={false}
            className={`admin-panel__role__item ${
              !pathname.includes('web-hook') && (pathname.includes('super-admin') || pathname.includes('white-label')) ? 'admin-panel__role__item--active' : ''
            }`}
            to='/super-admin'
          >
            White Label
          </Link>
          <h3 className='white-label-title'>{whiteLabelName}</h3>
        </>
      )}

      {(isUserSuperAdmin) && (
        <>
          <Link
            draggable={false}
            className={`admin-panel__role__item ${
              pathname.includes('web-hook') || pathname.includes('web-hook') ? 'admin-panel__role__item--active' : ''
            }`}
            to='/super-admin/web-hook'
          >
            Web Hook
          </Link>
        </>
      )}

      {!isUserAdmin && !isUserMainAdmin && isUserBasic && (
        <>
          <Link
            draggable={false}
            className={`admin-panel__role__item ${
              pathname.includes('admin') && !pathname.includes('super-admin') && !pathname.includes('/admin/users') ? 'admin-panel__role__item--active' : ''
            }`}
            to='/admin'
          >
            Reports
          </Link>

          <Link
            className={`admin-panel__role__item ${pathname.includes('/admin/users') ? 'admin-panel__role__item--active' : ''}`}
            to='/admin/users'>
            User List
          </Link>
        </>
      )}
    </nav>
  );
};

export default RoleNavigation;
