import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createWLSendGrid, getSoloSettingsWl } from '../../../store/actions/whiteLabelActions';
import { sendGridTextBilling, sendGridTextInfo, sendGridTextResults, sendGridTextSupport } from '../../../utils/white-label-annotations';
import WLField from '../FieldWL/WLField';
import SendGridTemplate from '../SendGridTemlate/SendGridTemlate';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../appContext';

const SendgridSettings = ({ paramsID, setSettingContent }) => {
  const { isUserSuperAdmin } = useContext(AppContext);
  const pathname = useHistory().location.pathname;
  const dispatch = useDispatch();
  const whiteLabel = useSelector((state) => state.whiteLabel.whiteLabel);
  const createdWLId = useSelector((state) => state.whiteLabel?.createdWLId);
  const id = pathname.includes('edit') || pathname.includes('settings') || !isUserSuperAdmin ? paramsID : createdWLId;

  const [sendGridInfo, setSendGridInfo] = useState('');
  const [sendGridSupport, setSendGridSupport] = useState('');
  const [sendGridResults, setSendGridResults] = useState('');
  const [sendGridBilling, setSendGridBilling] = useState('');

  useEffect(() => {
    if (id) dispatch(getSoloSettingsWl(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (id && whiteLabel) {
      setSendGridInfo(whiteLabel?.info_email || '');
      setSendGridSupport(whiteLabel?.support_email || '');
      setSendGridResults(whiteLabel?.results_email || '');
      setSendGridBilling(whiteLabel?.billing_email || '');
    }
  }, [id, whiteLabel]);

  const submitHandler = async () => {
    const response = await dispatch(
      createWLSendGrid(id, {
        info_email: sendGridInfo,
        results_email: sendGridResults,
        billing_email: sendGridBilling,
        support_email: sendGridSupport,
      })
    );

    if (response) setSettingContent('certificates');
  };

  return (
    <div className="white-label__fields">
      <WLField text={sendGridTextInfo} placeholder="SendGrid Info Email" value={sendGridInfo} onChange={(e) => setSendGridInfo(e.target.value)} />
      <WLField text={sendGridTextSupport} placeholder="SendGrid Support Email" value={sendGridSupport} onChange={(e) => setSendGridSupport(e.target.value)} />
      <WLField text={sendGridTextResults} placeholder="SendGrid Results Email" value={sendGridResults} onChange={(e) => setSendGridResults(e.target.value)} />
      <WLField text={sendGridTextBilling} placeholder="SendGrid Billing Email" value={sendGridBilling} onChange={(e) => setSendGridBilling(e.target.value)} />
      <SendGridTemplate id={id} />
      <button className="create-white-label-btn" onClick={submitHandler} disabled={!id}>
        Save Changes
      </button>
    </div>
  );
};

export default SendgridSettings;
