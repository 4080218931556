import {
  GET_ALL_FAQS_FAILURE,
  GET_ALL_FAQS_REQUEST,
  GET_ALL_FAQS_SUCCESS,
  POST_CONTACT_US_FAILURE,
  POST_CONTACT_US_REQUEST,
  POST_CONTACT_US_SUCCESS,
  GET_LANDING_PRICES_REQUEST,
  GET_LANDING_PRICES_SUCCESS,
  GET_LANDING_PRICES_FAILURE,
} from '../actionsTypes';
import axiosApi from '../../utils/axios';
import { showNotification } from '../../App';

const getAllFaqsRequest = () => ({ type: GET_ALL_FAQS_REQUEST });
const getAllFaqsSuccess = (faqs) => ({ type: GET_ALL_FAQS_SUCCESS, faqs });
const getAllFaqsFailure = (error) => ({ type: GET_ALL_FAQS_FAILURE, error });

const postContactRequest = () => ({ type: POST_CONTACT_US_REQUEST });
const postContactSuccess = () => ({ type: POST_CONTACT_US_SUCCESS });
const postContactFailure = (error) => ({ type: POST_CONTACT_US_FAILURE, error });

const getLandingPricesRequest = () => ({ type: GET_LANDING_PRICES_REQUEST });
const getLandingPricesSuccess = (landingPrices) => ({ type: GET_LANDING_PRICES_SUCCESS, landingPrices });
const getLandingPricesFailure = (error) => ({ type: GET_LANDING_PRICES_FAILURE, error });

export const getAllFaqs = () => {
  return async (dispatch) => {
    try {
      dispatch(getAllFaqsRequest());
      const response = await axiosApi.get('/faq/?limit=50');
      dispatch(getAllFaqsSuccess(response.data.data));
    } catch (error) {
      dispatch(getAllFaqsFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || " ", 'Error');
    }
  };
};

export const postContactUs = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(postContactRequest());
      const response = await axiosApi.post('/contact', userData);
      dispatch(postContactSuccess());
      showNotification('success', response.data.message, 'Message');
    } catch (error) {
      dispatch(postContactFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || " ", 'Error');
    }
  };
};

export const getLandingPrices = () => {
  return async (dispatch) => {
    try {
      dispatch(getLandingPricesRequest());
      const response = await axiosApi.get('/config/get-prices');
      dispatch(getLandingPricesSuccess(response.data.data));
    } catch (error) {
      dispatch(getLandingPricesFailure(error));
    }
  };
};
