import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MainSettings from '../WLSettings/MainSettings';
import ImageColorSettings from '../WLSettings/ImageColorSettings';
import SendgridSettings from '../WLSettings/SendgridSettings';
import CertificateSettings from '../WLSettings/CertificateSettings';
import { Spin } from 'antd';
import classNames from 'classnames';
import './styles.scss';

const CreateWl = () => {
  const loading = useSelector((state) => state.whiteLabel.loading);
  const [settingContent, setSettingContent] = useState('main');

  const renderWLSettingsContent = (step) => {
    switch (step) {
      case 'main':
        return <MainSettings setSettingContent={setSettingContent} />;
      case 'images':
        return <ImageColorSettings setSettingContent={setSettingContent} />;
      case 'send-grid':
        return <SendgridSettings setSettingContent={setSettingContent} />;
      case 'certificates':
        return <CertificateSettings />;
      default:
        return null;
    }
  };

  return (
    <div className="create-white-label">
      <Spin spinning={loading} size="large">
        <div className="white-label__fields m-b-40">
          <button className={classNames('change-setting_button', { 'active-setting': settingContent === 'main' })} onClick={() => setSettingContent('main')}>
            main settings
          </button>

          <button
            className={classNames('change-setting_button', { 'active-setting': settingContent === 'images' })}
            onClick={() => setSettingContent('images')}
          >
            images settings
          </button>
          <button
            className={classNames('change-setting_button', { 'active-setting': settingContent === 'send-grid' })}
            onClick={() => setSettingContent('send-grid')}
          >
            send-grid settings
          </button>
          <button
            className={classNames('change-setting_button', { 'active-setting': settingContent === 'certificates' })}
            onClick={() => setSettingContent('certificates')}
          >
            certificates settings
          </button>
        </div>
        {renderWLSettingsContent(settingContent)}
      </Spin>
    </div>
  );
};

export default CreateWl;
