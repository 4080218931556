import React, { useContext, useEffect, useMemo, useState } from 'react';
import Row from './components/Row';
import CheckBox from '../../Components/common/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { downloadAllBusinessFiles, downloadAllFiles } from '../../store/actions/fileActions';
import { Spin } from 'antd';
import ArrowBottom from '../../assets/UI/arrow_bottom.svg';
import { getSkeletonRow, toCapitalize } from '../../utils/functions';
import { getSoloBusinessProcess, getSoloProcess } from '../../store/actions/processActions';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { AppContext } from '../../appContext';
import ReactGa from 'react-ga4';

const MyResults = (props) => {
  const pathname = useHistory().location.pathname;
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const process = useSelector((state) => state?.process.process);
  const loading = useSelector((state) => state?.process.loading);
  const [allFilesId, setAllFilesId] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const key = pathname.includes('business') ? 'business_files' : 'person_files';
  const { brand } = useContext(AppContext);

  useEffect(() => {
    if (pathname.includes('business')) dispatch(getSoloBusinessProcess(id));
    else dispatch(getSoloProcess(id));
  }, [dispatch, id, pathname]);

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadAllResultsHandler = async () => {
    setLoadingDownload(true);
    if (pathname.includes('business')) await dispatch(downloadAllBusinessFiles(allFilesId, process, false));
    else await dispatch(downloadAllFiles(allFilesId, process, true));
    setLoadingDownload(false);
  };

  const rows = useMemo(() => {
    return !_.isEmpty(process) ? (process[key]?.length < 6 ? [...process[key], ...getSkeletonRow(process[key].length)] : process[key]) : getSkeletonRow(0);
  }, [process, key]);

  return (
    <div className="results">
      <div className="results__header">
        <h1>
          View & Download <span>Your Results.</span>
        </h1>
        <b>Congratulations! Your {toCapitalize(brand)} search has been completed</b>
        <p>You can review your results summary and download your results or you can view and download your results individually.</p>
      </div>
      <div className="results__body">
        <div className="results__body-item">
          <div className="m-l-40 m-b-15">
            <h3>Results Summary</h3>
            <div className="results-border" style={{ borderBottom: 'none' }}>
              <p>Total Records Submitted</p>
              <p className="total">{process.total_records}</p>
            </div>
            <div className="flex flex-align-items-center  duplicates-item">
              <p>Duplicates found</p>
              <p className="total">{process.total_duplicates}</p>
            </div>
            <div className="results-border-double m-t-10">
              <div className="flex flex-align-items-center">
                <p>Total Hits</p>
                <p className="total"> {process.total_hits}</p>
              </div>
              <div className="flex flex-align-items-center m-b-10">
                <p>Skips Charged</p>
                <p className="total"> {process.skips_charged}</p>
              </div>
              <div className="flex flex-align-items-center">
                <p>Initial Skips</p>
                <p className="total">{process.initial_skips || 0}</p>
              </div>
            </div>
            <div className="results-border">
              <p>Skips Remaining</p>
              <p className="total"> {process.skips_remaining || 0}</p>
            </div>
          </div>
          <div className="m-l-20">
            {(process.skips_saved > 0 || process.skips_saved !== undefined) && (
              <p className="savedSkips">Auto Duplicate detection saved you {process.skips_saved || 0} skips!!!</p>
            )}
          </div>

          <div className="results__body-item-footer">
            <h3>{process.results}</h3>
            <p>Total Results</p>
          </div>
        </div>
        <div className="results__body-item">
          <h3>Your Data Files</h3>
          <Spin spinning={loading || loadingDownload} size="large">
            <div className="table">
              <div className="thead">
                <p className="th" />
                <p className="th">Total Hits</p>
                <p className="th">Results Breakdown</p>
                <p className="th">Download</p>
              </div>
              <div className="tbody" id={'tbody'}>
                {rows &&
                  rows.length &&
                  rows.map((item, index) => {
                    return (
                      <Row
                        type={pathname.includes('business')}
                        allChecked={allChecked}
                        id={item.id}
                        index={index}
                        key={index}
                        skeleton={item.skeleton}
                        name={item.original_name}
                        results={item.results || 0}
                        link={item.link}
                        totalHits={item.total_hits || '0 (0%)'}
                        totalRecord={item.total_records || '0 (0%)'}
                        setAllFilesId={setAllFilesId}
                        status={item.status}
                        totalDuplicates={item.total_duplicates || '0'}
                      />
                    );
                  })}
              </div>
              <div className="tfoot">
                <CheckBox checked={allChecked} setChecked={setAllChecked} text="Select all Files" className="download-checkbox m-r-20" />
                <Spin spinning={loadingDownload}>
                  <button onClick={downloadAllResultsHandler}>
                    <p>Download Results</p> <LazyLoadImage src={ArrowBottom} alt="arrow" />
                  </button>
                </Spin>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default MyResults;
