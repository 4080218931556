import React, { useState } from 'react';
import './style.scss';
import CheckBox from '../../../../Components/common/Checkbox/Checkbox';
import { updateReceiveNotifications } from '../../../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

const NotificationSettings = () => {
  const dispatch = useDispatch();
  const receiveNotifications = useSelector((state) => state.user.profile.receive_notifications);
  const loading = useSelector((state) => state.user.notificationLoading);
  const [sendNotification, setSendNotification] = useState(receiveNotifications);

  const updateReceiveNotificationsHandler = () => {
    dispatch(updateReceiveNotifications(sendNotification));
  };

  return (
    <div className="notification-settings">
      <>
        <h1 className="notification-settings__title">Notification Settings</h1>
        <div className="toggle-send__notification">
          <CheckBox checked={sendNotification} setChecked={setSendNotification} />
          <span>Receive Text Messages From SkipCloud</span>
        </div>
        <h2 className="marketing-text">
          I agree to receive marketing text messages which may be generated by an automated dialer to the phone number provided above. Consent not required to
          make a purchase. Message frequency for alerts varies. Msg & Data Rates May Apply. Unsubscribe at any time by replying STOP or click the unsubscribe
          link (where available). You may also change your selection after making your account by going to Account Management, and then Notification Settings.
        </h2>
        <button onClick={updateReceiveNotificationsHandler} disabled={loading}>
          Save Changes
        </button>
      </>
      <Spin size="large" className="notifications-spinner" spinning={loading} />
    </div>
  );
};

export default NotificationSettings;
