import { GET_USER_CALLENDLY_FAILURE, GET_USER_CALLENDLY_REQUEST, GET_USER_CALLENDLY_SUCCESS } from '../actionsTypes';
import { showNotification } from '../../App';
import axiosApi from '../../utils/axios';

export const getUserCallendlyRequest = () => ({ type: GET_USER_CALLENDLY_REQUEST });
export const getUserCallendlySuccess = (callendly) => ({ type: GET_USER_CALLENDLY_SUCCESS, callendly });
export const getUserCallendlyFailure = (error) => ({ type: GET_USER_CALLENDLY_FAILURE, error });

export const getUserCallendly = () => {
  return async (dispatch) => {
    try {
      dispatch(getUserCallendlyRequest());
      const response = await axiosApi.get('/calendly')
      dispatch(getUserCallendlySuccess(response.data.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message, 'Error');
      dispatch(getUserCallendlyFailure(!error?.response?.data?.errors?.message));
    }
  };
};
