import {
  BAN_CURRENT_USER_FAILURE,
  BAN_CURRENT_USER_REQUEST,
  BAN_CURRENT_USER_SUCCESS,
  CREATE_FAQS_FAILURE,
  CREATE_FAQS_REQUEST,
  CREATE_FAQS_SUCCESS,
  DELETE_FAQS_FAILURE,
  DELETE_FAQS_REQUEST,
  DELETE_FAQS_SUCCESS,
  EDIT_FAQS_FAILURE,
  EDIT_FAQS_REQUEST,
  EDIT_FAQS_SUCCESS,
  GET_ADMIN_ALL_FAQS_FAILURE,
  GET_ADMIN_ALL_FAQS_REQUEST,
  GET_ADMIN_ALL_FAQS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UNBAN_CURRENT_USER_FAILURE,
  UNBAN_CURRENT_USER_REQUEST,
  UNBAN_CURRENT_USER_SUCCESS,
  EDIT_PRICE_CARD_REQUEST,
  EDIT_PRICE_CARD_SUCCESS,
  EDIT_PRICE_CARD_FAILURE,
  GET_ACTIVE_DISCOUNTS_REQUEST,
  GET_ACTIVE_DISCOUNTS_SUCCESS,
  GET_ACTIVE_DISCOUNTS_FAILURE,
  GET_INACTIVE_DISCOUNTS_REQUEST,
  GET_INACTIVE_DISCOUNTS_SUCCESS,
  GET_INACTIVE_DISCOUNTS_FAILURE,
  GET_USERS_DISCOUNT_REQUEST,
  GET_USERS_DISCOUNT_SUCCESS,
  GET_USERS_DISCOUNT_FAILURE,
  GET_SINGLE_DISCOUNTS_REQUEST,
  GET_SINGLE_DISCOUNTS_SUCCESS,
  GET_SINGLE_DISCOUNTS_FAILURE,
  GET_WL_PRICES_REQUEST,
  GET_WL_PRICES_SUCCESS,
  GET_WL_PRICES_FAILURE,
  GET_BUSINESS_FILES_REQUEST,
  GET_BUSINESS_FILES_SUCCESS,
  GET_BUSINESS_FILES_FAILURE,
  GET_PERSON_FILES_REQUEST,
  GET_PERSON_FILES_SUCCESS,
  GET_PERSON_FILES_FAILURE,
} from '../actionsTypes';

const initialState = {
  loading: false,
  users: [],
  pages: 1,
  error: null,
  user: [],
  userLoading: false,
  userError: null,
  faqs: [],
  faqsPages: 1,
  priceCardsLoading: false,
  discountUsers: [],
  discountsError: null,
  activeDiscountsLoading: false,
  inactiveDiscountsLoading: false,
  activeDiscounts: [],
  activeDiscountsPages: 1,
  singleDiscount: [],
  inactiveDiscounts: [],
  inactiveDiscountsPages: 1,
  singleDiscountLoading: false,
  wlPrices: [],
  wlPricesLoading: false,
  wlPricesError: null,
  businessFiles: [],
  personFiles: [],
  businessFilesPages: 1,
  personFilesPages: 1,
  personFilesLoading: false,
  businessFilesLoading: false,
  filesError: null,
};

const adminReducer = (state = initialState, action) => {
  const newState = state.users.map((user) => {
    if (user?.id === action?.user?.id) {
      user = action.user;
    }

    return user;
  });

  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return { ...state, loading: true, users: [] };
    case GET_ALL_USERS_SUCCESS:
      return { ...state, loading: false, users: action.users.usersList, pages: action.users.pages, error: null };
    case GET_ALL_USERS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case BAN_CURRENT_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case BAN_CURRENT_USER_SUCCESS:
      return { ...state, loading: false, users: newState, error: null };
    case BAN_CURRENT_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case UNBAN_CURRENT_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case UNBAN_CURRENT_USER_SUCCESS:
      return { ...state, loading: false, users: newState, error: null };
    case UNBAN_CURRENT_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case RESET_USER_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case RESET_USER_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null };
    case RESET_USER_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_USER_REQUEST:
      return { ...state, userLoading: true, user: [] };
    case GET_USER_SUCCESS:
      return { ...state, userLoading: false, user: action.user };
    case GET_USER_FAILURE:
      return { ...state, userLoading: false, userError: action.error };
    case GET_ADMIN_ALL_FAQS_REQUEST:
      return { ...state, loading: true, error: null, faqs: [] };
    case GET_ADMIN_ALL_FAQS_SUCCESS:
      return { ...state, loading: false, error: null, faqs: action.faqs, faqsPages: action.pages };
    case GET_ADMIN_ALL_FAQS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case EDIT_FAQS_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_FAQS_SUCCESS:
      return { ...state, loading: false, error: null };
    case EDIT_FAQS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case DELETE_FAQS_REQUEST:
      return { ...state, loading: true, error: null };
    case DELETE_FAQS_SUCCESS:
      return { ...state, loading: false, error: null };
    case DELETE_FAQS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case CREATE_FAQS_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_FAQS_SUCCESS:
      return { ...state, loading: false, error: null };
    case CREATE_FAQS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case EDIT_PRICE_CARD_REQUEST:
      return { ...state, priceCardsLoading: true };
    case EDIT_PRICE_CARD_SUCCESS:
      return { ...state, priceCardsLoading: false };
    case EDIT_PRICE_CARD_FAILURE:
      return { ...state, priceCardsLoading: false };
    case GET_ACTIVE_DISCOUNTS_REQUEST:
      return { ...state, activeDiscountsLoading: true };
    case GET_ACTIVE_DISCOUNTS_SUCCESS:
      return { ...state, activeDiscountsLoading: false, activeDiscounts: action.activeDiscounts, activeDiscountsPages: action.activeDiscountsPages };
    case GET_ACTIVE_DISCOUNTS_FAILURE:
      return { ...state, activeDiscountsLoading: false, discountsError: action.error };
    case GET_INACTIVE_DISCOUNTS_REQUEST:
      return { ...state, inactiveDiscountsLoading: true };
    case GET_INACTIVE_DISCOUNTS_SUCCESS:
      return { ...state, inactiveDiscountsLoading: false, inactiveDiscounts: action.inactiveDiscounts, inactiveDiscountsPages: action.inactiveDiscountsPages };
    case GET_INACTIVE_DISCOUNTS_FAILURE:
      return { ...state, inactiveDiscountsLoading: false, discountsError: action.error };
    case GET_USERS_DISCOUNT_REQUEST:
      return { ...state, loading: true };
    case GET_USERS_DISCOUNT_SUCCESS:
      return { ...state, loading: false, discountUsers: action.discountUsers };
    case GET_USERS_DISCOUNT_FAILURE:
      return { ...state, loading: false };
    case GET_SINGLE_DISCOUNTS_REQUEST:
      return { ...state, singleDiscountLoading: true, singleDiscount: [] };
    case GET_SINGLE_DISCOUNTS_SUCCESS:
      return { ...state, singleDiscountLoading: false, singleDiscount: action.singleDiscount };
    case GET_SINGLE_DISCOUNTS_FAILURE:
      return { ...state, singleDiscountLoading: false };
    case GET_WL_PRICES_REQUEST:
      return { ...state, wlPricesLoading: true, wlPrices: [] };
    case GET_WL_PRICES_SUCCESS:
      return { ...state, wlPricesLoading: false, wlPrices: action.wlPrices };
    case GET_WL_PRICES_FAILURE:
      return { ...state, wlPricesLoading: false, wlPricesError: action.error };
    case GET_BUSINESS_FILES_REQUEST:
      return { ...state, businessFilesLoading: true, businessFiles: [] };
    case GET_BUSINESS_FILES_SUCCESS:
      return { ...state, businessFilesLoading: false, businessFiles: action.businessFiles, businessFilesPages: action.businessFilesPages };
    case GET_BUSINESS_FILES_FAILURE:
      return { ...state, businessFilesLoading: false, error: action.error };
    case GET_PERSON_FILES_REQUEST:
      return { ...state, personFilesLoading: true, personFiles: [] };
    case GET_PERSON_FILES_SUCCESS:
      return { ...state, personFilesLoading: false, personFiles: action.personFiles, personFilesPages: action.personFilesPages };
    case GET_PERSON_FILES_FAILURE:
      return { ...state, personFilesLoading: false, error: action.error };
    default:
      return { ...state };
  }
};

export default adminReducer;
