import React, { useContext, useMemo } from 'react';
import AdminNavigation from '../../Components/admin/Navigation/AdminNavigation';
import { AppContext } from '../../appContext';
import BasicNavigation from '../../Components/admin/Navigation/BasicNavigation';
import { useHistory } from 'react-router-dom';
import RoleNavigation from '../../Components/admin/Navigation/RoleNavigation';
import './styles.scss';
import classNames from 'classnames';

const AdminMainComponent = () => {
  const { isUserAdmin, isUserBasic, isUserMainAdmin } = useContext(AppContext);
  const pathname = useHistory().location.pathname;
  const adminPanelPadding = useMemo(() => {
    return (
      pathname.includes('/employees') ||
      pathname.includes('/reports') ||
      pathname.includes('/skiptrace') ||
      pathname.includes('/summary') ||
      pathname.includes('/user-activity')
    );
  }, [pathname]);

  return (
    <section className={classNames('admin-panel', { 'admin-padding': adminPanelPadding })}>
      <RoleNavigation />
      <div className="admin-panel__main-container">
        {(isUserAdmin || isUserMainAdmin) && !pathname.includes('super-admin') && <AdminNavigation />}
        {!isUserAdmin && !isUserMainAdmin && isUserBasic && <BasicNavigation />}
      </div>
    </section>
  );
};

export default AdminMainComponent;
