import React, { useEffect,} from 'react';
import AuthenticationLayout from "../../Components/UI/AuthenticationLayout/AuthenticationLayout";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {apiURL} from "../../utils/config";
import {Spin} from "antd";
import { useSelector} from "react-redux";
import useResizeWindows from "../../utils/hooks/useResizeWindows";
import './HighLevelForm.scss';

const HighLevelForm = () => {
    const width = useResizeWindows();
    const loading = useSelector((state) => state?.user.loading);

    // whitelabel images from config
    const subLogo = useSelector((state) => state.config.config?.images?.logo_2);
    const whiteLabelName = useSelector((state) => state.config?.config?.name);
    const authBackground = useSelector((state) => state.config.config?.images?.auth_background);

    useEffect(() => {
        const messageHandler = async (event) => {
            console.log(event);
        };

        window.addEventListener('message', messageHandler, false);

        return () => {
            window.removeEventListener('message', messageHandler, false);
        };
    }, []);


    const registerStyles = {
        margin: '0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: `url(${apiURL + authBackground}) 0 -2px no-repeat`,
        // backgroundSize: isSkipCloud ? 'contain' : 'cover',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        minHeight: '100vh',
    };

    return (
        <div className="register" style={registerStyles}>
            <div className="register__body">
                {width >= 1200 && <AuthenticationLayout extendedClassName={true} />}
                <div className="register__body-form  m-t-auto m-b-auto">
                    <LazyLoadImage className="login__logo" draggable={false} src={`${apiURL + subLogo}`} alt="logo" />
                    <h3 className="auth__title">
                        Sign up to <span>{whiteLabelName}</span>
                    </h3>
                    <div className="login__border" />
                    <Spin spinning={loading} size={'large'}>
                        <div className="high-level__form">
                            <iframe
                                src="https://link.ai-reply.io/widget/form/Q5HSp1AeNxXjmZD4tcyN"
                                style={{width: '100%', height: '100%', border: 'none', borderRadius: '4px'}}
                                id="inline-Q5HSp1AeNxXjmZD4tcyN"
                                data-layout="{'id':'INLINE'}"
                                data-trigger-type="alwaysShow"
                                data-trigger-value=""
                                data-activation-type="alwaysActivated"
                                data-activation-value=""
                                data-deactivation-type="neverDeactivate"
                                data-deactivation-value=""
                                data-form-name="Form 0"
                                data-height="732"
                                data-layout-iframe-id="inline-Q5HSp1AeNxXjmZD4tcyN"
                                data-form-id="Q5HSp1AeNxXjmZD4tcyN"
                                title="Sign Up"
                            >
                            </iframe>
                            <script src="https://link.ai-reply.io/js/form_embed.js"></script>
                        </div>
                    </Spin>
                </div>
            </div>

        </div>

    );
};

export default HighLevelForm;

