import { GET_USER_CALLENDLY_FAILURE, GET_USER_CALLENDLY_REQUEST, GET_USER_CALLENDLY_SUCCESS, LOGOUT_USER } from '../actionsTypes';

const initialState = {
  loading: false,
  error: null,
  callendly: [],
};

const callendlyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_CALLENDLY_REQUEST:
      return { ...state, loading: true };
    case GET_USER_CALLENDLY_SUCCESS:
      return { ...state, loading: false, callendly: action.callendly };
    case GET_USER_CALLENDLY_FAILURE:
      return { ...state, loading: false, error: action.error };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default callendlyReducer;
