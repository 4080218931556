import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CheckBox from '../../Components/common/Checkbox/Checkbox';
import ArrowLeft from '../../assets/UI/arrow__left.svg';
import SortArrow from '../../assets/UI/sort-arrow.svg';
import { Select, Spin } from 'antd';
import { months } from '../../utils/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from './component/Row';
import { useDispatch, useSelector } from 'react-redux';
import { downloadAllBusinessFiles, downloadAllFiles } from '../../store/actions/fileActions';
import { getSkeletonRow } from '../../utils/functions';
import { removeLibrary, searchBusinessFile, searchFile } from '../../store/actions/processActions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SearchIcon from '../../Components/SVG-Icon/SearchIcon';
import _ from 'lodash';
import NoResult from '../../Components/common/NoResult/NoResult';
import './styles.scss';
import ReactGa from 'react-ga4';

const ResultsLibrary = () => {
  const dispatch = useDispatch();
  const process = useSelector((state) => state?.process.processes);
  const pages = useSelector((state) => state?.process.pages);
  const loading = useSelector((state) => state?.process.loading);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [allFilesId, setAllFilesId] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState(3);
  const [searchAccess, setSearchAccess] = useState(false);
  const [sort, setSort] = useState(false);
  const [order, setOrder] = useState('name');
  const [state, setState] = useState(true);
  const ref = useRef();
  const pathname = useHistory().location.pathname;

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchAccess) {
      if (state) dispatch(searchFile(search, date, page, order, sort));
      else dispatch(searchBusinessFile(search, date, page, order, sort));
      setSearchAccess(false);
    }
  }, [dispatch, page, date, search, searchAccess, order, sort, state]);

  useEffect(() => {
    if (!search) {
      if (state) dispatch(searchFile());
      else dispatch(searchBusinessFile());
      setAllFilesId([]);
      setAllChecked(false);
    }
  }, [dispatch, search, state]);

  const downloadAllResultsHandler = async () => {
    setLoadingDownload(true);
    if (state) await dispatch(downloadAllFiles(allFilesId, process, true));
    else await dispatch(downloadAllBusinessFiles(allFilesId, process, false));
    setLoadingDownload(false);
  };
  const scrollToTop = () => {
    ref.current?.scrollIntoView({ block: 'start' });
  };

  const rows = useMemo(() => {
    return process.length < 6 ? [...process, ...getSkeletonRow(process.length)] : process;
  }, [process]);

  const allCheckedHandler = (checked) => {
    setAllChecked(checked);
    if (checked) {
      const array = [];
      rows.map(({ person_files, business_files, skeleton }) =>
        !skeleton && business_files
          ? business_files.forEach((item) => array.push(item.id))
          : !skeleton && person_files
          ? person_files.forEach((item) => array.push(item.id))
          : {}
      );
      setAllFilesId(array);
    } else setAllFilesId([]);
  };

  const selectHandler = (value) => {
    setAllChecked(false);
    setAllFilesId([]);
    setDate(value);
    setPage(1);
    setSearchAccess(true);
    scrollToTop();
  };

  const enterHandler = (e) => {
    if (e.keyCode === 13) {
      setSearchAccess(true);
      scrollToTop();
    }
  };

  const sortHandler = (value) => {
    setPage(1);
    setSearchAccess(true);
    setSort(!sort);
    setOrder(value);
    scrollToTop();
  };

  const navigationHandler = (state) => {
    dispatch(removeLibrary());
    setState(state);
    setPage(1);
  };

  return (
    <div className="results-library">
      <div className="results-library__header">
        <h1>
          RESULTS <span>LIBRARY</span>
        </h1>
        <p>You can review your results summary and download your results or you can view and download your results individually.</p>
      </div>

      <div className="results-library__navigation">
        <button
          onClick={() => navigationHandler(true)}
          className={`results-library__navigation-button ${state ? 'results-library__navigation-button-active' : ''}`}
          disabled={loading}
        >
          People
        </button>
        <button
          onClick={() => navigationHandler(false)}
          className={`results-library__navigation-button ${!state ? 'results-library__navigation-button-active' : ''}`}
          disabled={loading}
        >
          Business
        </button>
      </div>

      <div className="results-library__body">
        <div className="results-library__body-header">
          <div className="results-library__body-header-inner">
            <Select className="month" defaultValue={months[0]} onChange={selectHandler}>
              {months.map((month) => (
                <Select.Option key={month.id} value={month.value}>
                  {month.name}
                </Select.Option>
              ))}
            </Select>
            <div className="search">
              <input
                className="search__field"
                type="text"
                placeholder="Search file name"
                value={search}
                onKeyDown={enterHandler}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(1);
                }}
              />
              <SearchIcon
                className="search__icon"
                onClick={() => {
                  setSearchAccess(true);
                  scrollToTop();
                }}
              />
            </div>
          </div>
        </div>
        <Spin spinning={loading} size="large" style={{ minHeight: '400px' }}>
          <div className="table">
            {_.isEmpty(process) ? (
              <NoResult text="No Files Found" style={{ marginTop: '120px' }} />
            ) : (
              <>
                <div className="thead">
                  <p className="th sort" onClick={() => sortHandler('name')}>
                    File Name <LazyLoadImage className={`sort-icon ${!sort && order === 'name' ? 'sort-icon-top' : ''}`} src={SortArrow} alt="arrow" />
                  </p>
                  <p className="th sort" onClick={() => sortHandler('created_at')}>
                    Date <LazyLoadImage className={`sort-icon ${!sort && order === 'created_at' ? 'sort-icon-top' : ''}`} src={SortArrow} alt="arrow" />
                  </p>
                  <p className="th">Expiration Date</p>
                  <p className="th">Total Hits</p>
                  <p className="th">Results Breakdown</p>
                  <p className="th">Download</p>
                </div>
                <div className="tbody" id={'tbody'}>
                  <div ref={ref} />
                  <InfiniteScroll
                    dataLength={process.length - 2}
                    next={() => {
                      setPage((prev) => (prev + 1 <= pages ? prev + 1 : prev));
                      setSearchAccess(true);
                      setAllChecked(false);
                      setAllFilesId([]);
                    }}
                    hasMore={true}
                    scrollThreshold={'10px'}
                    pullDownToRefreshThreshold={50}
                    scrollableTarget="tbody"
                    loader={null}
                  >
                    {rows.length &&
                      rows.map((item, index) => (
                        <Row
                          process={item}
                          type={item.business_files}
                          files={item.business_files ? item.business_files : item.person_files}
                          allChecked={allChecked}
                          id={item.id}
                          index={index}
                          key={index}
                          skeleton={item.skeleton}
                          name={item.name}
                          results={item.results}
                          link={item.link}
                          totalHits={item.total_hits}
                          totalRecord={item.total_records}
                          setAllFilesId={setAllFilesId}
                          date={item.created_at}
                          search={search}
                          multiply={item.multiply_files}
                          setAllChecked={setAllChecked}
                          allFilesId={allFilesId}
                          totalDuplicate={item.total_duplicates || 0}
                        />
                      ))}
                  </InfiniteScroll>
                </div>
              </>
            )}
          </div>
        </Spin>
        <div className="results-library__footer">
          <Link to="/dashboard">
            <LazyLoadImage src={ArrowLeft} alt="arrow" className="arrow" /> Go Back To Dashboard
          </Link>
          <div className="results-library__footer-buttons">
            <div className="selected">
              <b>{allFilesId.length} FILES</b>
              <p>SELECTED</p>
            </div>
            <div className="button-block">
              <CheckBox text="Select all files" checked={allChecked} setChecked={allCheckedHandler} />
              <Spin spinning={loadingDownload}>
                <button onClick={downloadAllResultsHandler}>
                  <p>DOWNLOAD SELECTED</p>
                </button>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsLibrary;
