import {
  ADD_IMAGE_WL_FAILURE,
  ADD_IMAGE_WL_REQUEST,
  ADD_IMAGE_WL_SUCCESS,
  CREATE_CALLENDLY_FAILURE,
  CREATE_CALLENDLY_REQUEST,
  CREATE_CALLENDLY_SUCCESS,
  CREATE_WL_FAILURE,
  CREATE_WL_REQUEST,
  CREATE_WL_SUCCESS,
  DELETE_CALLENDLY_FAILURE,
  DELETE_CALLENDLY_REQUEST,
  DELETE_CALLENDLY_SUCCESS,
  EDIT_CALLENDLY_FAILURE,
  EDIT_CALLENDLY_REQUEST,
  EDIT_CALLENDLY_SUCCESS,
  EDIT_WL_FAILURE,
  EDIT_WL_REQUEST,
  EDIT_WL_SUCCESS,
  GET_ACTIVITY_STATISTICS_FAILURE,
  GET_ACTIVITY_STATISTICS_REQUEST,
  GET_ACTIVITY_STATISTICS_SUCCESS,
  GET_CALLENDLY_FAILURE,
  GET_CALLENDLY_REQUEST,
  GET_CALLENDLY_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_PAYMENT_STATISTICS_FAILURE,
  GET_PAYMENT_STATISTICS_REQUEST,
  GET_PAYMENT_STATISTICS_SUCCESS,
  GET_SKIPTRACE_STATISTICS_FAILURE,
  GET_SKIPTRACE_STATISTICS_REQUEST,
  GET_SKIPTRACE_STATISTICS_SUCCESS,
  GET_SOLO_WL_COLORS_SUCCESS,
  GET_SOLO_WL_FAILURE,
  GET_SOLO_WL_IMAGES_SUCCESS,
  GET_SOLO_WL_REQUEST,
  GET_SOLO_WL_SUCCESS,
  GET_SUMMARY_STATISTICS_FAILURE,
  GET_SUMMARY_STATISTICS_REQUEST,
  GET_SUMMARY_STATISTICS_SUCCESS,
  GET_WL_FAILURE,
  GET_WL_REQUEST,
  GET_WL_SUCCESS,
} from '../actionsTypes';
import axiosApi from '../../utils/axios';
import history from '../../utils/history';
import { showNotification } from '../../App';
import { getConfig } from './configActions';
import fileDownload from 'js-file-download';

const getWLRequest = () => ({ type: GET_WL_REQUEST });
const getWLSuccess = (whiteLabels) => ({ type: GET_WL_SUCCESS, whiteLabels });
const getWLFailure = (error) => ({ type: GET_WL_FAILURE, error });

const getPaymentStatisticsRequest = () => ({ type: GET_PAYMENT_STATISTICS_REQUEST });
const getPaymentStatisticsSuccess = (payments) => ({ type: GET_PAYMENT_STATISTICS_SUCCESS, payments });
const getPaymentStatisticsFailure = (error) => ({ type: GET_PAYMENT_STATISTICS_FAILURE, error });

const getSummaryStatisticsRequest = () => ({ type: GET_SUMMARY_STATISTICS_REQUEST });
const getSummaryStatisticsSuccess = (summary) => ({ type: GET_SUMMARY_STATISTICS_SUCCESS, summary });
const getSummaryStatisticsFailure = (error) => ({ type: GET_SUMMARY_STATISTICS_FAILURE, error });

const getActivityStatisticsRequest = () => ({ type: GET_ACTIVITY_STATISTICS_REQUEST });
const getActivityStatisticsSuccess = (history) => ({ type: GET_ACTIVITY_STATISTICS_SUCCESS, history });
const getActivityStatisticsFailure = (error) => ({ type: GET_ACTIVITY_STATISTICS_FAILURE, error });

const getSkiptraceStatisticsRequest = () => ({ type: GET_SKIPTRACE_STATISTICS_REQUEST });
const getSkiptraceStatisticsSuccess = (skiptrace) => ({ type: GET_SKIPTRACE_STATISTICS_SUCCESS, skiptrace });
const getSkiptraceStatisticsFailure = (error) => ({ type: GET_SKIPTRACE_STATISTICS_FAILURE, error });

const getSoloWLRequest = () => ({ type: GET_SOLO_WL_REQUEST });
const getSoloWLSuccess = (whiteLabel) => ({ type: GET_SOLO_WL_SUCCESS, whiteLabel });
const getSoloWLFailure = (error) => ({ type: GET_SOLO_WL_FAILURE, error });

const getSoloWLSettingsSuccess = (whiteLabel) => ({ type: GET_SOLO_WL_SUCCESS, whiteLabel });
const getSoloWLColorsSuccess = (colors) => ({ type: GET_SOLO_WL_COLORS_SUCCESS, colors });
const getSoloWLImagesSuccess = (images) => ({ type: GET_SOLO_WL_IMAGES_SUCCESS, images });

const createWLRequest = () => ({ type: CREATE_WL_REQUEST });
const createWLSuccess = (id) => ({ type: CREATE_WL_SUCCESS, id });
const createWLFailure = (error) => ({ type: CREATE_WL_FAILURE, error });

const editWLRequest = () => ({ type: EDIT_WL_REQUEST });
export const editWLSuccess = () => ({ type: EDIT_WL_SUCCESS });
const editWLFailure = (error) => ({ type: EDIT_WL_FAILURE, error });

const addImageRequest = () => ({ type: ADD_IMAGE_WL_REQUEST });
const addImageSuccess = () => ({ type: ADD_IMAGE_WL_SUCCESS });
const addImageFailure = (error) => ({ type: ADD_IMAGE_WL_FAILURE, error });

const getEmployeesRequest = () => ({ type: GET_EMPLOYEES_REQUEST });
const getEmployeesSuccess = (employees) => ({ type: GET_EMPLOYEES_SUCCESS, employees });
const getEmployeesFailure = (error) => ({ type: GET_EMPLOYEES_FAILURE, error });

const createCallendlyRequest = () => ({ type: CREATE_CALLENDLY_REQUEST });
const createCallendlySuccess = () => ({ type: CREATE_CALLENDLY_SUCCESS });
const createCallendlyFailure = (error) => ({ type: CREATE_CALLENDLY_FAILURE, error });

const getCallendlyRequest = () => ({ type: GET_CALLENDLY_REQUEST });
const getCallendlySuccess = (callendly) => ({ type: GET_CALLENDLY_SUCCESS, callendly });
const getCallendlyFailure = (error) => ({ type: GET_CALLENDLY_FAILURE, error });

const editCallendlyRequest = () => ({ type: EDIT_CALLENDLY_REQUEST });
const editCallendlySuccess = (edit) => ({ type: EDIT_CALLENDLY_SUCCESS, edit });
const editCallendlyFailure = (error) => ({ type: EDIT_CALLENDLY_FAILURE, error });

const deleteCallendlyRequest = () => ({ type: DELETE_CALLENDLY_REQUEST });
const deleteCallendlySuccess = () => ({ type: DELETE_CALLENDLY_SUCCESS });
const deleteCallendlyFailure = (error) => ({ type: DELETE_CALLENDLY_FAILURE, error });

export const getAllWl = () => {
  return async (dispatch) => {
    try {
      dispatch(getWLRequest());
      const response = await axiosApi.get('/admin/white-label');
      dispatch(getWLSuccess(response.data.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getWLFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getWLFailure({ global: 'No internet' }));
      }
    }
  };
};

export const createWL = (wlData) => {
  return async (dispatch) => {
    try {
      dispatch(createWLRequest());
      const response = await axiosApi.post('/admin/white-label/new', wlData);
      dispatch(createWLSuccess(response.data.data.id));
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(createWLFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(createWLFailure({ global: 'No internet' }));
      }

      return false;
    }
  };
};

export const createWLColors = (id, colorsData) => {
  return async () => {
    try {
      await axiosApi.put(`/admin/white-label/${id}/color/edit`, colorsData);
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const createWLSendGrid = (id, sendGridData) => {
  return async () => {
    try {
      await axiosApi.put(`/admin/white-label/${id}/emails/edit`, sendGridData);
      showNotification('success', 'Your SendGrid emails have been successfully saved', 'Success');
      return true;
    } catch (e) {
      showNotification('error', e?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const editWLSettings = (domainData, id) => {
  return async (dispatch) => {
    try {
      dispatch(editWLRequest());
      await axiosApi.put(`/admin/white-label/${id}/edit`, domainData);
      dispatch(editWLSuccess());
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(editWLFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(editWLFailure({ global: 'No internet' }));
      }
      return false;
    }
  };
};

export const editWLColorList = (colorsData, id) => {
  return async (dispatch) => {
    try {
      dispatch(editWLRequest());
      await axiosApi.put(`/admin/white-label/${id}/color/edit`, colorsData);
      dispatch(editWLSuccess());
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(editWLFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(editWLFailure({ global: 'No internet' }));
      }
    }
  };
};

// export const editWLColors = (colorsData, domainData, id) => {
//   return async (dispatch) => {
//     try {
//       dispatch(editWLRequest());
//       await axiosApi.put(`/admin/white-label/${id}/edit`, domainData);
//       await axiosApi.put(`/admin/white-label/${id}/color/edit`, colorsData);
//       dispatch(editWLSuccess());
//       history.push('/super-admin');
//       dispatch(getConfig());
//     } catch (error) {
//       showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
//       if (error?.response?.data?.errors?.message || true) {
//         dispatch(editWLFailure(error?.response?.data?.errors?.message || true));
//       } else {
//         dispatch(editWLFailure({ global: 'No internet' }));
//       }
//     }
//   };
// };

export const getSoloSettingsWl = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSoloWLRequest());
      const response = await axiosApi.get(`/admin/white-label/${id}/show`);
      dispatch(getSoloWLSettingsSuccess(response.data.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getSoloWLFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getSoloWLFailure({ global: 'No internet' }));
      }
    }
  };
};

export const getSoloColorsWl = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSoloWLRequest());
      const colors = await axiosApi.get(`/admin/white-label/${id}/color/show`);
      dispatch(getSoloWLColorsSuccess(colors.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getSoloWLFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getSoloWLFailure({ global: 'No internet' }));
      }
    }
  };
};

export const getSoloImagesWl = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSoloWLRequest());
      const images = await axiosApi.get(`/admin/white-label/${id}/images/index`);
      dispatch(getSoloWLImagesSuccess(images.data.errors ? [] : images.data.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getSoloWLFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getSoloWLFailure({ global: 'No internet' }));
      }
    }
  };
};

export const addImagesWL = (file, id) => {
  const formData = new FormData();
  formData.append('file', file.file);
  formData.append('type', file.type);

  return async (dispatch) => {
    try {
      if (typeof file === 'string') return;
      dispatch(addImageRequest());
      await axiosApi.post(`/admin/white-label/${id}/images/new`, formData);
      dispatch(addImageSuccess());
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(addImageFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(addImageFailure({ global: 'No internet' }));
      }
    }
  };
};

export const getPaymentStatistics = (id, startDate, endDate, created = false, page = 1, userId = '') => {
  return async (dispatch) => {
    try {
      if (created) {
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-transaction-history-report/?start_date=${startDate}&end_date=${endDate}&create_file=${created}`,
          { responseType: 'blob' }
        );
        fileDownload(response.data, 'report.csv');
      } else {
        dispatch(getPaymentStatisticsRequest());
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-transaction-history-report/?start_date=${startDate}&end_date=${endDate}&create_file=${created}&page=${page}&userId=${userId}`
        );
        dispatch(getPaymentStatisticsSuccess(response.data));
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getPaymentStatisticsFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getPaymentStatisticsFailure({ global: 'No internet' }));
      }
    }
  };
};

export const getSummaryStatistics = (id, startDate, endDate, create_file = false) => {
  return async (dispatch) => {
    try {
      if (create_file) {
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-summary-report/?start_date=${startDate}&end_date=${endDate}&create_file=${create_file}`,
          { responseType: 'blob' }
        );
        fileDownload(response.data, 'report-summary.csv');
      } else {
        dispatch(getSummaryStatisticsRequest());
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-summary-report/?start_date=${startDate}&end_date=${endDate}&create_file=${create_file}`
        );
        dispatch(getSummaryStatisticsSuccess(response.data.data));
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getSummaryStatisticsFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getSummaryStatisticsFailure({ global: 'No internet' }));
      }
    }
  };
};

export const getUserActivityReports = (id, startDate, endDate, create_file = false, page, type = '', userId = '') => {
  return async (dispatch) => {
    try {
      if (create_file) {
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-users-activity-history-report?start_date=${startDate}&end_date=${endDate}&create_file=${create_file}&order=id&sort=desc&userId=${userId}&activityType=${type}`,
          { responseType: 'blob' }
        );
        fileDownload(response.data, 'report-user-activity.csv');
      } else {
        dispatch(getActivityStatisticsRequest());
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-users-activity-history-report?start_date=${startDate}&end_date=${endDate}&create_file=${create_file}&page=${page}&order=id&sort=desc&userId=${userId}&activityType=${type}`
        );
        dispatch(getActivityStatisticsSuccess(response.data));
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getActivityStatisticsFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getActivityStatisticsFailure({ global: 'No internet' }));
      }
    }
  };
};

export const getSkiptraceStatistics = (id, startDate, endDate, created = false, page, userId = '') => {
  return async (dispatch) => {
    try {
      if (created) {
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-skiptrace-history-report/?start_date=${startDate}&end_date=${endDate}&create_file=${created}`,
          { responseType: 'blob' }
        );
        fileDownload(response.data, 'report-skiptrace-history.csv');
      } else {
        dispatch(getSkiptraceStatisticsRequest());
        const response = await axiosApi.get(
          `/admin/white-label/${id}/show-skiptrace-history-report/?start_date=${startDate}&end_date=${endDate}&create_file=${created}&page=${page}&userId=${userId}`
        );
        dispatch(getSkiptraceStatisticsSuccess(response.data));
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getSkiptraceStatisticsFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getSkiptraceStatisticsFailure({ global: 'No internet' }));
      }
    }
  };
};

export const getEmployees = (id, disabled = false, page) => {
  return async (dispatch) => {
    dispatch(getEmployeesRequest());
    try {
      const response = await axiosApi.get(`/admin/white-label/${id}/get-employee-list?disabled=${disabled}&page=${page}`);
      dispatch(getEmployeesSuccess(response.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getEmployeesFailure(error));
    }
  };
};

export const changeExpiredDate = (data) => {
  return async () => {
    try {
      const response = await axiosApi.put(`admin/files/update-expiration-date`, data);
      showNotification('success', response.data.message, 'Success');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const banWhiteLabel = (wlID) => {
  return async (dispatch) => {
    try {
      dispatch(getWLRequest());
      await axiosApi.delete(`/admin/white-label/${wlID}/delete`);
      dispatch(getAllWl());
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getWLFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const unBanWhiteLabel = (wlID) => {
  return async (dispatch) => {
    try {
      dispatch(getWLRequest());
      await axiosApi.put(`/admin/white-label/${wlID}/restore`);
      dispatch(getAllWl());
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getWLFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const confirmWhiteLabel = (wlID) => {
  return async (dispatch) => {
    try {
      dispatch(getWLRequest());
      await axiosApi.put(`admin/white-label/${wlID}/confirm`);
      dispatch(getAllWl());
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getWLFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const deleteWhiteLabel = (wlID) => {
  return async (dispatch) => {
    try {
      dispatch(getWLRequest());
      await axiosApi.delete(`/admin/white-label/${wlID}/remove`);
      dispatch(getAllWl());
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getWLFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const addCertificate = (certificate, wlId) => {
  return async (dispatch) => {
    try {
      history.goBack();
      await axiosApi.post(`admin/white-label/${wlId}/certificates/new`, certificate);
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getWLFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const getCallendly = (wlId) => {
  return async (dispatch) => {
    try {
      dispatch(getCallendlyRequest());
      const response = await axiosApi.get(`/admin/white-label/${wlId}/calendly`);
      dispatch(getCallendlySuccess(response.data.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getCallendlyFailure(error?.response?.data?.errors?.message));
    }
  };
};

export const createCallendly = (wlId, callendlyData) => {
  const formData = new FormData();

  Object.keys(callendlyData).forEach((key) => {
    formData.append(key, callendlyData[key]);
  });

  return async (dispatch) => {
    try {
      dispatch(createCallendlyRequest());
      await axiosApi.post(`/admin/white-label/${wlId}/calendly/new`, formData);
      dispatch(createCallendlySuccess());
      dispatch(getCallendly(wlId));

      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(createCallendlyFailure(error?.response?.data?.errors?.message));

      return false;
    }
  };
};

export const deleteCallendly = (wlId, id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteCallendlyRequest());
      await axiosApi.delete(`/admin/white-label/${wlId}/calendly/remove/${id}`);
      dispatch(deleteCallendlySuccess());
      dispatch(getCallendly(wlId));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(deleteCallendlyFailure(error?.response?.data?.errors?.message));
    }
  };
};

export const editCallendly = (wlId, id, callendlyData) => {
  const formData = new FormData();

  Object.keys(callendlyData).forEach((key) => {
    formData.append(key, callendlyData[key]);
  });

  return async (dispatch) => {
    try {
      dispatch(editCallendlyRequest());
      await axiosApi.post(`/admin/white-label/${wlId}/calendly/edit/${id}`, formData);
      dispatch(editCallendlySuccess());
      dispatch(getCallendly(wlId));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(editCallendlyFailure(error?.response?.data?.errors?.message));
    }
  };
};

export const getSoloWLColorImages = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSoloWLRequest());
      const colors = await axiosApi.get(`/admin/white-label/${id}/color/show`);
      const images = await axiosApi.get(`/admin/white-label/${id}/images/index`);
      dispatch(getSoloWLSuccess({ ...colors.data, images: images.data.errors ? [] : images.data.data }));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(getSoloWLFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(getSoloWLFailure({ global: 'No internet' }));
      }
    }
  };
};

export const editWLColors = (colorsData, id) => {
  return async (dispatch) => {
    try {
      dispatch(editWLRequest());
      await axiosApi.put(`/admin/white-label/${id}/color/edit`, colorsData);
      dispatch(editWLSuccess());
      history.push('/super-admin');
      dispatch(getConfig());
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      if (error?.response?.data?.errors?.message || true) {
        dispatch(editWLFailure(error?.response?.data?.errors?.message || true));
      } else {
        dispatch(editWLFailure({ global: 'No internet' }));
      }
    }
  };
};
