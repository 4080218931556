import React, { useEffect, useState } from 'react';
import StarIcon from '../../../assets/pop-up/star.svg';
import DollarIcon from '../../../assets/pop-up/dollar.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './styles.scss';

const Card = ({ skips, price, perHit, type, setTotalOrder, setTotalSkips, setArray, id, cartActive, setDefaultCart, discount_id }) => {
  const [quantity, setQuantity] = useState(1);
  const [active, setActive] = useState(false);

  const addToCart = () => {
    setArray((prev) => [...prev, { skips, id, perHit, quantity, price, discount_id }]);
    setDefaultCart((prev) => [...prev, { skips, id, perHit, quantity, price, discount_id }]);
    setActive(true);
    setTotalOrder((prev) => prev + (quantity * price) / 100);
    setTotalSkips((prev) => prev + skips * quantity);
  };

  const removeToCart = () => {
    setArray((prev) => prev.filter((item) => item.id !== id));
    setDefaultCart((prev) => prev.filter((item) => item.id !== id));
    setActive(false);
    setTotalOrder((prev) => prev - (quantity * price) / 100);
    setTotalSkips((prev) => prev - skips * quantity);
  };

  useEffect(() => {
    if (!cartActive) {
      setActive(false);
      setTotalOrder(0);
      setTotalSkips(0);
    }
  }, [cartActive, setTotalOrder, setTotalSkips]);

  return (
    <div style={{ width: '18.5%' }}>
      <div className="card">
        <div className={`card__header ${type !== 'default' && type !== 'discount' ? 'card__header-profit' : ''}`}>
          <p draggable={false}>
            <span>{new Intl.NumberFormat('en').format(skips)}</span>
            SKIPS
          </p>
        </div>

        <div className={`card__body ${type !== 'default' && type !== 'discount' ? '' : 'card__body-border'}`}>
          <p className={type !== 'default' && type !== 'discount' ? 'profit-price' : type === 'discount' ? 'discount-price' : ''}>
            ${price / 100} <span>{price.toString().slice(-2)}</span>
          </p>
          {type !== 'default' && type !== 'discount' ? <p className="profit__per-hit">${perHit} PER HIT</p> : null}
        </div>

        <div className="card__footer">
          {type === 'popular_option' ? (
            <div className="card__footer-bg">
              <LazyLoadImage src={StarIcon} alt="star" style={{ width: '20px', height: '19px' }} />
              <p>Popular option</p>
            </div>
          ) : type === 'best_deal' ? (
            <div className="card__footer-bg-deal">
              <LazyLoadImage src={DollarIcon} alt="star" style={{ width: '12px', height: '23px' }} />
              <p>Best Deal</p>
            </div>
          ) : (
            <p className="m-t-10">${perHit} PER HIT</p>
          )}
        </div>
      </div>
      <div className="card__count">
        <div className="card__count-inner">
          <button className={`card__count-inner-btn ${active ? 'active' : ''}`} onClick={active ? removeToCart : addToCart}>
            ADD TO CART
          </button>
          <div className="card__count-inner-buttons">
            <button onClick={() => setQuantity((prev) => (prev - 1 <= 0 ? prev : prev - 1))} disabled={active}>
            </button>
            <p>{quantity}</p>
            <button onClick={() => setQuantity((prev) => prev + 1)} disabled={active}>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
